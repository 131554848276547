/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';
import { consumerMembersEntity } from './consumerMember.schema';

export const consumerDivisionsEntity = new schema.Entity(
  'consumerDivisions',
  {
    consumerMembers: [consumerMembersEntity]
  },
  {
    processStrategy: consumerDivision => ({
      ...consumerDivision
    })
  }
);
