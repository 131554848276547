/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';
import { groupsEntity } from './group.schema';
import getDefaultAddress from '../selectors/address/getDefaultAddress';

export const domainsEntity = new schema.Entity(
  'domains',
  {
    groups: [groupsEntity]
  },
  {
    processStrategy: domain => ({
      ...domain,
      defaultAddress: getDefaultAddress(domain.addresses)
    })
  }
);
