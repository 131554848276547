import { isDate, getDates } from 'groundkeeper-component-library';

export default function getDateArray(
  start_date,
  end_date,
  { start_time, end_time },
  repeat_weekday,
  repeat_cycle
) {
  if (!isDate(start_date) || !isDate(end_date)) {
    return [];
  }

  if (repeat_weekday < 0 || repeat_weekday > 6) {
    throw Error(
      `The given weekday needs to be in the range 0...6 for (Sun...Sat). ${repeat_weekday} was passed instead.`
    );
  }

  return getDates(
    new Date(start_date),
    new Date(end_date),
    repeat_weekday ?? new Date(start_date).getDay(),
    repeat_cycle
  ).map(d => {
    const startDate = d;
    const endDate = d;
    startDate.setHours(start_time.hour);
    startDate.setMinutes(start_time.minutes);
    endDate.setHours(end_time.hour);
    endDate.setMinutes(end_time.minutes);

    return {
      start: startDate,
      end: endDate
    };
  });
}
