import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

const useStyles = makeStyles(theme => ({
  dataTileItems: {}
}));

export default function DataTileGroup(props) {
  const classes = useStyles();

  return (
    <Box display="flex" flexWrap="wrap" className={classes.dataTileItems}>
      {props.children}
    </Box>
  );
}

DataTileGroup.propTypes = {
  children: PropTypes.node.isRequired
};

DataTileGroup.defaultProps = {};
