import Router from 'next/router';
import isServer from '../utils/isServer';
import { fetchUser } from '../slices/auth.slice';
import { getClientSessionToken, SESSION_COOKIE } from './session';

import logoutSideEffects, {
  logoutRedirectUrl
} from 'shared/Redux/utils/logout';

export function hasSessionCookie(req: any): boolean {
  if (isServer) {
    return !!req.cookies[SESSION_COOKIE];
  }

  return !!getClientSessionToken();
}

export async function isAuthenticated(context: {
  req: any;
  store: any;
}): Promise<boolean> {
  const { req, store } = context;
  if (hasSessionCookie(req)) {
    // TODO: Introduce dedicated `session_active` endpoint
    const response = await store.dispatch(fetchUser());

    return !response.error;
  }

  return false;
}

// Check if user is allowed to fetch data
// On Server check the request session cookie availability
// On Client check the local session cookie availability
// and store auth user
// Example for NextJS:
//   Members.getInitialProps = wrapper.getInitialPageProps(
//     withAuthentication(async ({ store }) => {
//       await store.dispatch(getUsers());
//       await store.dispatch(getOrganization());
//
//       return {};
//   }));

function withAuthentication(func: any): any {
  return (store: any) => async (context: any) => {
    const { res, asPath } = context;

    const redirectUrl = logoutRedirectUrl(isServer ? res?.req?.url : asPath);
    const valid = await isAuthenticated({ ...context, store });

    if (valid) {
      return func({ ...context, store });
    }

    logoutSideEffects();

    if (isServer) {
      if (redirectUrl) {
        res.writeHead(302, { Location: redirectUrl }).end();
      }

      return { error: 'restricted' };
    }

    if (redirectUrl) {
      Router.push(logoutRedirectUrl(redirectUrl));
    }

    return { error: 'restricted' };
  };
}

export default withAuthentication;
