import React from 'react';

import Box from '@mui/material/Box';

import Spacing from './Spacing';
import Text from './Text';
import Void from './Void';

export default function FormPresenterSingleLine(props) {
  const {
    caption,
    captionLineBreak,
    display = 'inline-flex',
    popoverHover,
    popoverIcon,
    popoverText,
    popoverTitle,
    spacing = 0.75,
    value
  } = props;

  return (
    <>
      <Box
        style={{ display: captionLineBreak ? 'block' : display }}
        whiteSpace="pre-wrap"
        gap={1}
      >
        {caption ? <Text bold>{`${caption}:`}</Text> : <Void />}
        <Text>{value}</Text>
      </Box>
      <Spacing y={spacing} />
    </>
  );
}
