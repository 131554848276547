import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import DataTileItem from './DataTileItem';
import DataTileGroup from './DataTileGroup';

const useStyles = makeStyles(theme => ({
  dataTile: {
    border: `1px solid ${theme.color.border.light}`,
    borderRadius: theme.borderRadius.small
  }
}));

export default function DataTile(props) {
  const classes = useStyles(props);

  return (
    <Box
      justifyContent={props.justifyContent}
      display="flex"
      flexWrap="wrap"
      className={classes.dataTile}
    >
      {props.children}
    </Box>
  );
}

DataTile.propTypes = {
  children: PropTypes.node,
  justifyContent: PropTypes.oneOf([
    'space-around',
    'space-between',
    'space-evenly',
    'start',
    'end'
  ])
};

DataTile.defaultProps = {
  justifyContent: 'left'
};

DataTile.Item = DataTileItem;
DataTile.Group = DataTileGroup;
