/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';
import { consumerMembersEntity } from './consumerMember.schema';
import { notesEntity } from './note.schema';
import getDefaultAddress from '../selectors/address/getDefaultAddress';
import getDefaultContact from '../selectors/contact/getDefaultContact';

export const consumersEntity = new schema.Entity(
  'consumers',
  {
    consumer_members: [consumerMembersEntity],
    notes: [notesEntity]
  },
  {
    processStrategy: consumer => ({
      ...consumer,
      defaultAddress: getDefaultAddress(consumer.addresses),
      defaultContact: getDefaultContact(consumer.contacts)
    })
  }
);
