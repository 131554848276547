import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { useTheme } from '@mui/styles';
export default function Sticky(props) {
  const theme = useTheme();
  const contentContainer = useRef();
  const [contentContainerPaddingTop, setContentContainerPaddingTop] =
    useState(0);

  useEffect(() => {
    if (theme.contentId) {
      contentContainer.current = document?.getElementById(theme.contentId);
      setContentContainerPaddingTop(
        parseInt(
          window
            .getComputedStyle(contentContainer.current, null)
            .getPropertyValue('padding-top'),
          10
        )
      );
    }
  }, []);

  return (
    <div
      style={{
        position: 'sticky',
        top: theme.contentOffset
          ? `calc(${theme.contentOffset} + ${contentContainerPaddingTop}px)`
          : 0
      }}
    >
      {props.children}
    </div>
  );
}

Sticky.propTypes = {
  children: PropTypes.node.isRequired
};

Sticky.defaultProps = {};
