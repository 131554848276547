import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import Button from '../Button';
import Day from './Day';
import MonthToggle from './MonthToggle';

import getCalendar from '../../utils/calendar/getCalendar';
import getWeekdays from '../../utils/calendar/getWeekdayNames';
import getMonthNames from '../../utils/calendar/getMonthNames';
import getYears from '../../utils/calendar/getYears';

const useStyles = makeStyles(theme => ({
  calendar: {
    width: props => {
      if (props.fullWidth) {
        return '100%';
      }

      switch (props.size) {
        case 'small':
          return '15em';
        default:
        case 'medium':
          return '25em';
        case 'large':
          return '35em';
      }
    }
  },
  weekday: {
    color: theme.color.text.light,
    cursor: 'default',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '0.9em'
  },
  content: {
    position: 'relative',
    overflow: 'auto'
  },
  overlay: {
    position: 'absolute',
    background: theme.color.background.default,
    width: '100%',
    height: '100%',
    top: 0
  }
}));

function Calendar(props) {
  const classes = useStyles(props);
  const yearRef = useRef(null);
  const [pickedDate, setPickedDate] = useState(
    props.picker ? props.value : null
  );
  const [date, setDate] = useState(props.date);
  const [mode, setMode] = useState('day');
  const [bufferedMonths, setBufferedMonths] = useState(getCalendar(props.date));
  const [bufferedYears, setBufferedYears] = useState(
    getYears(date.getFullYear() - 50, date.getFullYear() + 50)
  );

  useEffect(() => {
    setBufferedMonths(getCalendar(props.date));
  }, [props.date]);

  useEffect(() => {
    if (props.value) {
      setPickedDate(props.value);
      updateDate(props.value);
    }
  }, [props.value]);

  function updateDate(date) {
    setDate(date);
    setBufferedMonths(getCalendar(date));
  }

  function updateMonth(monthIndex) {
    updateDate(new Date(date.getFullYear(), monthIndex, date.getDate()));
    setMode('day');
  }

  function updateYear(year) {
    updateDate(new Date(year, date.getMonth(), date.getDate()));
    setMode('day');
  }

  function updateMode(mode) {
    setMode(mode);
    setBufferedYears(
      getYears(date.getFullYear() - 50, date.getFullYear() + 50)
    );

    if (mode === 'year') {
      setTimeout(() => {
        yearRef.current.scrollIntoView({ block: 'center' });
      }, 100);
    }
  }

  function onChange(date) {
    setPickedDate(date);

    if (typeof props.onChange === 'function') {
      props.onChange(date);
    }
  }

  function addPreviousBufferedYears() {
    const firstYear = bufferedYears[0];

    setBufferedYears([
      ...getYears(firstYear - 25, firstYear - 1),
      ...bufferedYears
    ]);
  }

  function addNextBufferedYears() {
    const lastYear = bufferedYears[bufferedYears.length - 1];

    setBufferedYears([
      ...bufferedYears,
      ...getYears(lastYear + 1, lastYear + 25)
    ]);
  }

  return (
    <Box
      display="flex"
      className={classes.calendar}
      flexDirection="column"
      width="100%"
    >
      <MonthToggle
        date={date}
        mode={mode}
        size={props.size}
        setDate={updateDate}
        setMode={updateMode}
        locale={props.locale}
      />
      <Box className={classes.content}>
        <Box display="flex">
          {getWeekdays(props.locale).map(day => (
            <Box
              className={classes.weekday}
              key={day}
              width={1 / 7}
              title={day}
            >
              {day.substr(0, 2)}
            </Box>
          ))}
        </Box>
        {bufferedMonths.current[0].map((week, index) => {
          return (
            <Box
              display="flex"
              key={week[0].getTime()}
              justifyContent={index === 0 ? 'flex-end' : 'flex-start'}
            >
              {week.map(day => {
                return (
                  <Day
                    key={day.getTime()}
                    compareDate={pickedDate}
                    day={day}
                    month={date.getMonth()}
                    maxDate={props.maxDate}
                    minDate={props.minDate}
                    size={props.size}
                    onClick={props.picker ? onChange : undefined}
                  />
                );
              })}
            </Box>
          );
        })}
        {mode === 'month' && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexWrap="wrap"
            className={classes.overlay}
          >
            {getMonthNames(props.locale).map((m, i) => (
              <Button
                size={props.size}
                color={i === date.getMonth() ? 'primary' : 'default'}
                variant={i === date.getMonth() ? 'contained' : 'text'}
                onClick={() => updateMonth(i)}
                key={m}
              >
                {m}
              </Button>
            ))}
          </Box>
        )}
        {mode === 'year' && (
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            className={classes.overlay}
          >
            <Button
              size={props.size}
              variant="text"
              onClick={addPreviousBufferedYears}
            >
              ...
            </Button>
            {bufferedYears.map(y => (
              <Box key={y} ref={y === date.getFullYear() ? yearRef : undefined}>
                <Button
                  size={props.size}
                  color={y === date.getFullYear() ? 'primary' : 'default'}
                  variant={y === date.getFullYear() ? 'contained' : 'text'}
                  onClick={() => updateYear(y)}
                >
                  {y}
                </Button>
              </Box>
            ))}
            <Button
              size={props.size}
              variant="text"
              onClick={addNextBufferedYears}
            >
              ...
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}

Calendar.propTypes = {
  // Value of the picker
  value: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  // Date of the calendar
  date: PropTypes.instanceOf(Date),
  picker: PropTypes.bool,
  locale: PropTypes.string,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onChange: PropTypes.func
};

Calendar.defaultProps = {
  maxDate: undefined,
  date: new Date(),
  minDate: undefined,
  picker: false,
  onChange: undefined,
  locale: 'en-EN',
  size: 'medium',
  fullWidth: false,
  value: undefined
};

export default Calendar;
