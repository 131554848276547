/**
 * et next and previous 50 years
 */
export default function getYears(start, end) {
  const years = [];

  for (let year = start; year <= end; year++) {
    years.push(year);
  }

  return years;
}
