/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';

import { consumersEntity } from './consumer.schema';
import { consumerMembersEntity } from './consumerMember.schema';
import { consumerTeamsEntity } from './consumerTeam.schema';
import { datesEntity } from './date.schema';
import { eventsEntity } from './event.schema';
import { notesEntity } from './note.schema';
import { occasionsEntity } from './occasion.schema';
import { seasonsEntity } from './season.schema';
import { unitsEntity } from './unit.schema';

export const requestEntity = new schema.Entity('requests', {
  applicant_id: consumerMembersEntity,
  events: [eventsEntity],
  notes: [notesEntity],
  consumer_teams: [consumerTeamsEntity],
  occasion: occasionsEntity,
  end_date: datesEntity,
  start_date: datesEntity,
  units: [unitsEntity],
  season: seasonsEntity
});
