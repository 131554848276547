/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';

export const datesEntity = new schema.Entity(
  'dates',
  {},
  {
    processStrategy: date => ({
      ...date,
      start: date.start,
      end: date.end,
      start_local: date.start_local,
      end_local: date.end_local
    })
  }
);
