import Cookies from 'js-cookie';
import { AnyAction } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { SESSION_COOKIE } from '../api/session';
import { client } from '../api/client';

import {
  AuthState,
  LoginForm,
  RegisterForm,
  PasswordResetForm,
  PasswordResetUpdateForm
} from '../interfaces';

import logoutSideEffects from 'shared/Redux/utils/logout';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const fetchUser = createAsyncThunk('auth/user', async () => {
  const response = await client.get('/users');

  return response.data;
});

export const login = createAsyncThunk('auth/login', async (form: LoginForm) => {
  logoutSideEffects();

  const response = await client.post('/sessions', {
    session: { email: form.email, password: form.password }
  });

  // Set session cookie on successful login
  if (response && response.status === 200) {
    Cookies.set(SESSION_COOKIE, response.data.access_token, {
      sameSite: 'lax',
      secure: window.location.protocol === 'https:'
    });
  }

  return response.data;
});

export const register = createAsyncThunk(
  'auth/register',
  async (form: RegisterForm) => {
    const response = await client.post('/users', {
      user: {
        first_name: form.first_name,
        last_name: form.last_name,
        email: form.email,
        password: form.password
      }
    });

    return response.data;
  }
);

export const userConfirm = createAsyncThunk(
  'auth/confirm',
  async (key: string) => {
    const response = await client.get(`/confirms?key=${key}`);

    return response.data;
  }
);

export const userConfirmResend = createAsyncThunk(
  'auth/confirm_resend',
  async (opts: { email: string }) => {
    const response = await client.post('/confirms/resend', {
      email: opts.email
    });

    return response.data;
  }
);

export const userConfirmTokenValid = createAsyncThunk(
  'auth/confirm_token_valid',
  async (key: string) => {
    const response = await client.get(`/confirms/valid/${key}`);
    return response.status;
  }
);

export const userConfirmWithPassword = createAsyncThunk(
  'auth/confirmWithPassword',
  async (payload: any) => {
    const response = await client.get(
      `/confirms?key=${payload.key}&password=${encodeURIComponent(payload.password)}`
    );

    return response.data;
  }
);

export const passwordReset = createAsyncThunk(
  'auth/passwordReset',
  async (form: PasswordResetForm) => {
    const response = await client.post('/password_resets', {
      password_reset: { email: form.email }
    });

    return response.data;
  }
);
export const passwordResetUpdate = createAsyncThunk(
  'auth/passwordResetUpdate',
  async (form: PasswordResetUpdateForm) => {
    const response = await client.put('/password_resets/update', {
      password_reset: { password: form.password, key: form.key }
    });

    return response.data;
  }
);

const initialState: AuthState = {
  user: null,
  member: null,
  isLoading: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state: AuthState) {
      state.user = null;
      state.member = null;
      logoutSideEffects();
    }
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        state.user = payload.auth.user;
        state.member = payload.auth.member;
      });
    }),
      builder.addCase(login.pending, state => {
        state.isLoading = true;
      });
    builder.addCase(login.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(login.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchUser.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      const userCopy = { ...action.payload.data };
      delete userCopy.member;
      state.user = userCopy;
      state.member = action.payload.data.member;
      state.isLoading = false;
    });
    builder.addCase(fetchUser.rejected, state => {
      state.isLoading = false;
    });
  }
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
