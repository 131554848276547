export default function classNames(classes: string[]) {
  const filteredClasses = classes.filter(
    c =>
      c !== undefined &&
      c !== null &&
      (typeof c === 'string' || (c as any) instanceof String)
  );

  return filteredClasses.join(' ');
}
