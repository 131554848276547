import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { renderEditFields, renderFields } from './jsonFieldHelpers';

export default function JsonFields(props) {
  const intl = useIntl();

  return props.editMode
    ? renderEditFields(props.fields, props.categories, props, intl)
    : renderFields(props.fields, props.categories, props);
}

JsonFields.propTypes = {
  fields: PropTypes.object,
  startIndex: PropTypes.number,
  labelWidth: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      categorie_name: PropTypes.string,
      order: PropTypes.number
    })
  ),
  disableCategoryIcons: PropTypes.bool,
  disabled: PropTypes.bool,
  internal: PropTypes.bool,
  onChange: PropTypes.func,
  filter: PropTypes.func
};

JsonFields.defaultProps = {
  fields: {},
  internal: false,
  disabled: false,
  labelWidth: undefined,
  disableCategoryIcons: false,
  onChange: () => null,
  filter: () => true
};
