import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

const useStyles = makeStyles(theme => ({
  dataTileItem: {
    paddingTop: props => (props.noPadding ? 0 : theme.spacing(2)),
    paddingLeft: props => (props.noPadding ? 0 : theme.spacing(2)),
    paddingBottom: props => (props.noPadding ? 0 : theme.spacing(2))
  }
}));

export default function DataTileItem(props) {
  const classes = useStyles(props);

  return (
    <Box
      display="flex"
      flexDirection={props.flexDirection}
      alignItems={props.alignItems}
      justifyContent={props.justifyContent}
      className={classes.dataTileItem}
    >
      {props.children}
    </Box>
  );
}

DataTileItem.propTypes = {
  alignItems: PropTypes.oneOf(['start', 'center', 'end']),
  flexDirection: PropTypes.oneOf(['column', 'row']),
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
  justifyContent: PropTypes.oneOf(['start', 'center', 'end'])
};

DataTileItem.defaultProps = {
  alignItems: 'start',
  flexDirection: 'column',
  noPadding: false,
  justifyContent: 'start'
};
