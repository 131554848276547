import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';

import Heading from './Heading';
import Spacing from './Spacing';
import Sticky from './Sticky';
import Text from './Text';
import SmartDirectory from './SmartDirectory';

const useStyles = makeStyles(theme => ({
  formDirectory: {
    flexShrink: 0,
    flexBasis: '33%',
    boxSizing: 'border-box',
    [theme.mediaQueries.tiny]: {
      flexBasis: '100%'
    }
  },
  directory: {
    width: '100%',
    boxSizing: 'border-box',
    background: theme.color.background.grey
  },
  requiredHint: {
    marginTop: theme.spacing(2)
  }
}));

export default function FormDirectory(props) {
  const classes = useStyles(props);
  const intl = useIntl();

  return (
    <Box className={classes.formDirectory}>
      <Sticky>
        {props.title ? (
          <Heading context type="h1">
            {props.title}
          </Heading>
        ) : null}
        {props.description ? (
          <Text color="light">{props.description}</Text>
        ) : null}
        <Text className={classes.requiredHint} color="light">
          {intl.formatMessage({
            id: 'required_form_inputs_hint',
            defaultMessage: 'The fields marked with * must be specified'
          })}
        </Text>
        {props.title || props.description ? <Spacing y={2} /> : null}
        {props.disableSmartDirectory ? null : (
          <Box className={classes.directory} p={2}>
            <SmartDirectory container={props.formId} />
          </Box>
        )}
      </Sticky>
    </Box>
  );
}

FormDirectory.propTypes = {
  directory: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  formId: PropTypes.string.isRequired,
  disableSmartDirectory: PropTypes.bool,
  'data-testid': PropTypes.string
};

FormDirectory.defaultProps = {
  directory: [],
  title: undefined,
  description: undefined,
  disableSmartDirectory: false,
  'data-testid': undefined
};
