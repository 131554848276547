import isBefore from './isBefore';

/**
 * Check if given day is in the past
 */
export default function isDayInPast(dt) {
  const newDt = new Date(dt);

  newDt.setHours(23);
  newDt.setMinutes(59);
  newDt.setSeconds(59);

  return isBefore(newDt, new Date());
}
