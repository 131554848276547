import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { useStore } from 'react-redux';

function dropEmptyParams(filter) {
  Object.keys(filter).map(k => {
    if (filter[k] === '' || filter[k] === undefined) {
      delete filter[k];
    }
  });

  return filter;
}

const env = getConfig()?.publicRuntimeConfig;

export default function usePagination(storePath, defaultPaginationSize = 25) {
  const router = useRouter();
  const store = useStore()?.getState();
  let pagination = store[storePath]?.pagination;
  const [pageSize, setPageSize] = useState(
    pagination?.page_size || defaultPaginationSize
  );
  const [loading, setLoading] = useState(false);

  if (!pagination) {
    pagination = {
      page: 1
    };

    if (env.isDevelopment) {
      // eslint-disable-next-line no-console
      console.warn(
        `The given storePath '${storePath}' does not exist in store ` +
          `or the pagination is missing.` +
          `Maybe you are disconnected from the backend ` +
          `or your session token is invalid.`
      );
    }
  }

  useEffect(() => {
    function routeChangeComplete() {
      setLoading(false);
    }

    router.events.on('routeChangeComplete', routeChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', routeChangeComplete);
    };
  }, []);

  async function handleChangePage(page) {
    setLoading(true);
    router.push({
      pathname: router.pathname,
      query: dropEmptyParams({ ...router.query, page })
    });
  }

  async function handleChangeRowsPerPage(rows) {
    setLoading(true);
    const newPageSize = Number(rows.key.split('$')[1]);

    const updatedPagination: {
      count?: number;
      page?: number;
      page_size?: number;
      total_pages?: number;
    } = { ...router.query, page_size: newPageSize };

    delete updatedPagination.page;

    router.push({
      pathname: router.pathname,
      query: dropEmptyParams(updatedPagination)
    });

    setPageSize(newPageSize);
  }

  return {
    pagination: { ...pagination, page_size: pageSize },
    handleChangePage,
    handleChangeRowsPerPage,
    loading
  };
}
