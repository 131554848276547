import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';

import Box from '@mui/material/Box';
import classNames from '../utils/react/classNames';

const transition =
  'background-color 100ms linear, color 100ms linear, border 100ms linear';

const useStyles = makeStyles(theme => ({
  barContainer: {
    color: theme.color.text.main,
    boxSizing: 'border-box',
    background: theme.color.background.default,
    borderBottom: `1px solid ${theme.color.border.light}`,
    '& a': {
      textDecoration: 'none'
    },
    '& > button': {
      display: 'flex',
      transition,
      '&:hover': {
        background: theme.color.background.grey,
        borderColor: 'transparent',
        borderRadius: 0
      }
    },
    ...(theme.components?.topbar?.root ? theme.components?.topbar?.root : {})
  }
}));

const Topbar = React.forwardRef(function Topbar(props, ref) {
  const classes = useStyles(props);

  return (
    <Box
      ref={ref}
      className={classNames([classes.barContainer, props.className])}
      width={1}
      px={1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      {props.logo}

      <Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          {React.Children.map(props.children, child => {
            if (!React.isValidElement(child)) {
              return null;
            }

            return React.cloneElement(child, {
              size: props.size
            });
          })}
        </Box>
      </Box>
    </Box>
  );
});

Topbar.propTypes = {
  logo: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

Topbar.defaultProps = {
  size: 'small'
};

export default Topbar;
