import getConfig from 'next/config';

export default () => {
  const isServer = typeof window === 'undefined';
  const publicRuntimeConfig = getConfig()?.publicRuntimeConfig;

  if (isServer) {
    return { ...publicRuntimeConfig, ...getConfig()?.serverRuntimeConfig };
  }

  return publicRuntimeConfig;
};
