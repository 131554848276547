import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import Icon from './Icon';
import Text from './Text';

const useStyles = makeStyles(theme => ({
  visibleContent: {
    color: theme.color.text.main,
    '& > *': {
      cursor: 'pointer',
      display: 'flex'
    }
  },
  hiddenContent: {
    borderTop: `.1em dotted ${theme.color.common.grey.light}`,
    display: ({ props, state }) => (state.visible ? 'block' : 'none')
  }
}));

export default function PartialContent(props) {
  const [visible, setVisible] = useState(false);
  const classes = useStyles({ props, state: { visible } });

  useEffect(() => {
    if (props.visible !== visible) {
      setVisible(props.visible);
    }
  }, [props.visible]);

  return (
    <>
      <Box
        alignItems="center"
        className={classes.visibleContent}
        display="flex"
      >
        <Box onClick={() => setVisible(!visible)}>{props.visibleContent}</Box>
        <Box
          display="flex"
          alignItems="center"
          ml={2}
          onClick={() => setVisible(!visible)}
        >
          <Icon name={visible ? 'arrow-up' : 'arrow-down'} />
        </Box>
        {props.linkComponent && (
          <Box display="flex" alignItems="center" ml={0.5}>
            {props.linkComponent}
          </Box>
        )}
      </Box>
      <Box className={classes.hiddenContent}>{props.hiddenContent}</Box>
    </>
  );
}

PartialContent.propTypes = {
  hiddenContent: PropTypes.node,
  linkComponent: PropTypes.node,
  visible: PropTypes.bool,
  visibleContent: PropTypes.node
};

PartialContent.defaultProps = {
  hiddenContent: null,
  linkComponent: null,
  visible: false,
  visibleContent: null
};
