import { isBefore, addDays, isSameDayDate } from './index';

/**
 * Get dates between two dates
 */
export default function getDatesBetween(start, end) {
  const datesBetween = [];
  let current = new Date(start);

  while (isBefore(current, end) && !isSameDayDate(current, end)) {
    datesBetween.push(current);
    current = addDays(current, 1);
  }

  return datesBetween;
}
