/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';
import getDefaultContact from '../selectors/contact/getDefaultContact';

export const organizationMembersEntity = new schema.Entity(
  'organizationMembers',
  {},
  {
    processStrategy: organizationMember => ({
      ...organizationMember,
      defaultContact: getDefaultContact(organizationMember.contacts)
    })
  }
);
