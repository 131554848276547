import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  formatBoolean: {
    '& svg': {
      marginBottom: `-${theme.spacing(0.25)}`
    }
  }
}));

export default function FormatBoolean(props) {
  const classes = useStyles();
  const isString =
    typeof props.children === 'string' || props.children instanceof String;
  const isTrue =
    props.children === true ||
    (isString && props.children.toLowerCase() === 'true');
  const isFalse =
    props.children === false ||
    props.children === null ||
    (isString && props.children.toLowerCase() === 'false');
  const isBoolean = isTrue || isFalse;

  if (!isBoolean) {
    console.error(
      'Boolean: ',
      `Given props.children '${props.children}' is no boolean.`
    );
  }

  return isTrue ? (
    <Box
      className={classes.formatBoolean}
      display="inline-flex"
      alignItems="baseline"
      gap={0.25}
    >
      <Icon name="check" color="green" />
      {/* TBD: intl */}
      Ja
    </Box>
  ) : (
    <Box
      className={classes.formatBoolean}
      display="inline-flex"
      alignItems="baseline"
      gap={0.25}
    >
      <Icon name="close" color="red" />
      {/* TBD: intl */}
      Nein
    </Box>
  );
}

FormatBoolean.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

FormatBoolean.defaultProps = {
  children: undefined
};
