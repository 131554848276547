import { setTime } from '../date';
import isTime from './isTime';

export default function formatTime(string, baseDate = new Date()) {
  if (isTime(string)) {
    // Is format HH:MM or HHMM
    const isPretty = string.includes(':');

    const hour = string.substr(0, 2);
    const minutes = string.substr(isPretty ? 3 : 2, isPretty ? 5 : 4);

    return {
      hour: Number(hour),
      minutes: Number(minutes),
      prettyString: `${hour}:${minutes}`,
      string: `${hour}${minutes}`,
      number: Number(`${hour}${minutes}`),
      date: setTime(baseDate, hour, minutes)
    };
  }

  return null;
}
