/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';
import { datesEntity } from './date.schema';

export const dateSeriesEntity = new schema.Entity(
  'dateSeries',
  {
    dates: [datesEntity]
  },
  {
    processStrategy: dateSeries => ({
      ...dateSeries,
      repeat_until_date: dateSeries.repeat_until_date
        ? new Date(dateSeries.repeat_until_date)
        : null,
      repeat_from_date: dateSeries.repeat_from_date
        ? new Date(dateSeries.repeat_from_date)
        : null
    })
  }
);
