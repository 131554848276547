import isDate from './isDate';

/**
 * Gets the date of a given query string
 * Example str = 2021-04-26
 */
export default function fromQueryString(str) {
  if (typeof str === 'string' || str instanceof String) {
    const splitted = str?.split('-');

    if (splitted?.length === 3) {
      const year = Number(splitted[0]);
      const month = Number(splitted[1]) - 1;
      const day = Number(splitted[2]);

      const date = new Date(year, month, day);

      if (isDate(date)) {
        return date;
      }
    }
  }

  return null;
}
