import { RootState } from '.';

// Because next-redux-wrapper HYDRATE causes unwanted HYDRATE actions
// on every client transition multiple selectors get triggered often.
// That results in performance issues.
// https://github.com/kirill-konshin/next-redux-wrapper/issues/562
export default function HYDRATE_FIX(payload: RootState, callback: () => void) {
  if (!payload.application.isClientSideInitialized) {
    callback();
  }
}
