import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';

export default function ModalActions({ children }) {
  return (
    <Box width={1} pt={1} flexShrink={0} px={2} pb={2} boxSizing="border-box">
      {!children.length ? (
        children
      ) : (
        <Box display="flex" justifyContent="flex-end">
          {children.map((child, i) => (
            <Box key={i} mr={i < children.length - 1 ? 1 : 0}>
              {child}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

ModalActions.propTypes = {
  children: PropTypes.node.isRequired
};

ModalActions.defaultProps = {};
