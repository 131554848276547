import Cookies from 'js-cookie';
import isServer from '../utils/isServer';

export const SESSION_COOKIE = 'session_token';

export function getServerSessionToken(context: {
  ctx: { req: { cookies: { [x: string]: string } } };
}): string | null {
  if (isServer && context && context.ctx && context.ctx.req) {
    return context.ctx.req.cookies[SESSION_COOKIE];
  }

  return null;
}

export function getClientSessionToken(): string | null {
  return Cookies.get(SESSION_COOKIE);
}

export function getSessionToken(context?: any): string | null {
  return isServer ? getServerSessionToken(context) : getClientSessionToken();
}
