/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';
import getDefaultAddress from '../selectors/address/getDefaultAddress';
import { unitsEntity } from './unit.schema';

export const groupsEntity = new schema.Entity(
  'groups',
  {
    units: [unitsEntity]
  },
  {
    processStrategy: group => {
      const defaultAddress = getDefaultAddress(group.addresses);
      const defaultAddressDomain = getDefaultAddress(group.domain?.addresses);
      const g = defaultAddressDomain
        ? {
            ...group,
            domain: { ...group.domain, defaultAddress: defaultAddressDomain }
          }
        : group;

      return defaultAddress
        ? {
            ...g,
            defaultAddress
          }
        : g;
    }
  }
);
