/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';
import { notesEntity } from './note.schema';
import { contactsEntity } from './contact.schema';
import getDefaultAddress from '../selectors/address/getDefaultAddress';
import getDefaultContact from '../selectors/contact/getDefaultContact';

export const consumerMembersEntity = new schema.Entity(
  'consumerMembers',
  {
    notes: [notesEntity],
    contacts: [contactsEntity]
  },
  {
    processStrategy: consumerMember => ({
      ...consumerMember,
      defaultAddress: getDefaultAddress(consumerMember.addresses),
      defaultContact: getDefaultContact(consumerMember.contacts)
    })
  }
);
