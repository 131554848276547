/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';
import { consumersEntity } from './consumer.schema';
import { consumerDivisionsEntity } from './consumerDivision.schema';
import { consumerMembersEntity } from './consumerMember.schema';
import { occasionsEntity } from './occasion.schema';

export const consumerTeamsEntity = new schema.Entity(
  'consumerTeams',
  {
    occasions: occasionsEntity,
    consumer: consumersEntity,
    trainer: consumerMembersEntity,
    consumerDivision: consumerDivisionsEntity
  },
  {
    processStrategy: consumerTeam => ({
      ...consumerTeam
    })
  }
);
