/* eslint-disable import/prefer-default-export */
import { AnyAction, combineReducers } from 'redux';

import application from './application.slice';
import auth from './auth.slice';
import feature from './feature.slice';
import bookings, { bookingSelectors } from './booking.slice';
import bookingPlans, { bookingPlanSelectors } from './bookingPlan.slice';
import consumers, { consumerSelectors } from './consumer.slice';
import consumerDivisions, {
  consumerDivisionSelectors
} from './consumerDivision.slice';
import consumerMembers, {
  consumerMemberSelectors
} from './consumerMember.slice';
import contacts, { contactSelectors } from './contact.slice';
import consumerTeams, { consumerTeamSelectors } from './consumerTeam.slice';
import holidays, { holidaySelectors } from './holiday.slice';
import providers, { providerSelectors } from './provider.slice';
import providerMembers, {
  providerMemberSelectors
} from './providerMember.slice';
import publicHolidays, { publicHolidaySelectors } from './publicHoliday.slice';
import requests, { requestSelectors } from './request.slice';
import dates, { dateSelectors } from './date.slice';
import dateSeries, { dateSeriesSelectors } from './dateSeries.slice';
import domains, { domainSelectors } from './domain.slice';
import groups, { groupSelectors } from './group.slice';
import events, { eventSelectors } from './event.slice';
import units, { unitSelectors } from './unit.slice';
import unitAttributes, { unitAttributeSelectors } from './unitAttribute.slice';
import unitInventories, { unitInventorySelectors } from './unitInventory.slice';
import notes, { noteSelectors } from './note.slice';
import notifications, { notificationSelectors } from './notification.slice';
import occasions, { occasionSelectors } from './occasion.slice';
import occasionPrimaryCategories, {
  occasionPrimaryCategorySelectors
} from './occasionPrimaryCategory.slice';
import occasionSecondaryCategories, {
  occasionSecondaryCategorySelectors
} from './occasionSecondaryCategory.slice';
import organizations, { organizationSelectors } from './organization.slice';
import organizationMembers, {
  organizationMemberSelectors
} from './organizationMember.slice';
import seasons, { seasonSelectors } from './season.slice';
import statistics, { statisticSelectors } from './statistic.slice';
import { ThunkDispatch } from '@reduxjs/toolkit';

export const reducers = combineReducers({
  application,
  auth,
  bookingPlans,
  bookings,
  consumerDivisions,
  consumerMembers,
  consumers,
  consumerTeams,
  contacts,
  dates,
  dateSeries,
  domains,
  events,
  feature,
  groups,
  holidays,
  notes,
  notifications,
  occasionPrimaryCategories,
  occasions,
  occasionSecondaryCategories,
  organizationMembers,
  organizations,
  providerMembers,
  providers,
  publicHolidays,
  requests,
  seasons,
  statistics,
  unitAttributes,
  unitInventories,
  units
});

export {
  bookingPlanSelectors,
  bookingSelectors,
  consumerDivisionSelectors,
  consumerMemberSelectors,
  consumerSelectors,
  consumerTeamSelectors,
  contactSelectors,
  dateSelectors,
  dateSeriesSelectors,
  domainSelectors,
  eventSelectors,
  groupSelectors,
  holidaySelectors,
  noteSelectors,
  notificationSelectors,
  occasionPrimaryCategorySelectors,
  occasionSecondaryCategorySelectors,
  occasionSelectors,
  organizationMemberSelectors,
  organizationSelectors,
  providerMemberSelectors,
  providerSelectors,
  publicHolidaySelectors,
  requestSelectors,
  seasonSelectors,
  statisticSelectors,
  unitAttributeSelectors,
  unitInventorySelectors,
  unitSelectors
};

export type RootState = ReturnType<typeof reducers>;

export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;
