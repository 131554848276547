import ArrowDownIcon from '../../public/arrow-down.svg';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import React from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { makeStyles } from '@mui/styles';

import Button from './Button';

const FADE_TIMEOUT = 150;

const useStyles = makeStyles(theme => ({
  splitButton: {
    border: 0,
    boxShadow: 'none !important',
    '& > button:first-child': {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '0 !important',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '0 !important'
    }
  },
  toggleButton: {
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '4px',
    borderLeft: 0,
    borderTopLeftRadius: '0',
    borderTopRightRadius: '4px',
    padding: 0
  },
  dropdown: {
    background: '#fff',
    paddingBottom: '6px'
  },
  componentWrapper: {
    margin: '6px 16px'
  }
}));

export default function SplitButton(props) {
  const { options, defaultValue, disabledValues, color, variant } = props;

  const classes = useStyles(props);
  const anchorRef = React.useRef(null);

  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(defaultValue);

  const handleMenuItemClick = index => {
    setSelectedIndex(index);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant={props.variant}
        disabled={props.disabled}
        ref={anchorRef}
        aria-label="split button"
        data-testid={props['data-testid']}
        className={classes.splitButton}
      >
        <Button
          variant={props.variant}
          color={color}
          disabled={props.disabled}
          type={props.type}
          onClick={() => props.onClick(options[selectedIndex].id)}
        >
          {options[selectedIndex].buttonLabel}
        </Button>
        <Button
          variant={props.variant}
          color={color}
          disabled={props.disabled}
          className={classes.toggleButton}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={FADE_TIMEOUT}>
              <Paper className={classes.dropdown}>
                <MenuList id="split-button-menu" className={classes.list}>
                  {options.map((option, index) => (
                    <MenuItem
                      tabIndex={0}
                      key={option.id}
                      className={classes.listItem}
                      disabled={
                        disabledValues?.length > 0 &&
                        disabledValues.includes(index)
                      }
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option.dropdownLabel}
                    </MenuItem>
                  ))}
                </MenuList>
                {options[selectedIndex]?.component && (
                  <>
                    <Divider className={classes.divider} />
                    <div className={classes.componentWrapper}>
                      {options[selectedIndex]?.component}
                    </div>
                  </>
                )}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}

SplitButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      buttonLabel: PropTypes.string.isRequired,
      dropdownLabel: PropTypes.string.isRequired,
      component: PropTypes.node
    })
  ).isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.number,
  disabledValues: PropTypes.array,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf(['contained', 'outlined']),
  color: PropTypes.oneOf([
    'default',
    'light',
    'commonLight',
    'primary',
    'secondary'
  ]),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  'data-testid': PropTypes.string
};

SplitButton.defaultProps = {
  defaultValue: 0,
  disabled: false,
  disabledValue: [],
  variant: 'contained',
  type: 'button',
  color: 'default',
  onClick: () => {},
  onChange: () => {},
  'data-testid': 'split-button'
};
