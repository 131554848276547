import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import Button from '../Button';

import { addMonths, subtractMonths } from '../../utils/date';

const useStyles = makeStyles(theme => ({
  monthToggle: {},
  toggleButtons: {
    '& button': {
      padding: '.5em;'
    }
  }
}));

function MonthToggle(props) {
  const classes = useStyles(props);
  const today = new Date();

  function setMode(mode) {
    // Reset/Toggle mode
    if (mode === props.mode) {
      props.setMode('day');
    } else {
      props.setMode(mode);
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classes.monthToggle}
    >
      <Button
        size="large"
        icon
        onClick={() => props.setDate(subtractMonths(props.date, 1))}
      >
        arrow-left
      </Button>
      <Box display="flex" className={classes.toggleButtons}>
        <Button
          size={props.size}
          color="primary"
          onClick={() => setMode('month')}
        >
          {new Intl.DateTimeFormat(props.locale, { month: 'long' }).format(
            props.date
          )}
        </Button>
        <Button
          size={props.size}
          color="primary"
          onClick={() => setMode('year')}
        >
          {props.date.getFullYear()}
        </Button>
        {(props.date.getMonth() !== today.getMonth() ||
          props.date.getFullYear() !== today.getFullYear()) && (
          <Button onClick={() => props.setDate(today)} size={props.size} icon>
            today
          </Button>
        )}
      </Box>
      <Button
        size="large"
        icon
        onClick={() => props.setDate(addMonths(props.date, 1))}
      >
        arrow-right
      </Button>
    </Box>
  );
}

MonthToggle.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  mode: PropTypes.oneOf(['day', 'month', 'year']).isRequired,
  setDate: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired
};

export default MonthToggle;
