import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { Provider, Pagination, UUID } from '../interfaces';
import { RootState } from '.';
import { client } from '../api/client';
import { fetchOrganizations } from './organization.slice';
import { providersEntity } from '../schemas';
import { safeArray, paginate } from '../utils';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const fetchProvider = createAsyncThunk(
  'providers/byId',
  async (providerId: UUID) => {
    const response = await client.get(`/providers/${providerId}`);
    const normalized = normalize(response.data.data, providersEntity);

    return normalized.entities;
  }
);

export const fetchProviders = createAsyncThunk(
  'providers/all',
  async (filter: Pagination & { portal_booking_requests_enabled: Boolean }) => {
    const response = await client.get('/providers', filter);
    const normalized = normalize(response.data.data, [providersEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const createProvider = createAsyncThunk(
  'providers/create',
  async provider => {
    const response = await client.post('/providers', { provider });
    const normalized = normalize(response.data.data, providersEntity);

    return normalized.entities;
  }
);

export const updateProvider = createAsyncThunk(
  'providers/update',
  async ({ id, provider }: { id: string; provider: Provider }) => {
    const response = await client.put(`/providers/${id}`, {
      provider
    });
    const normalized = normalize(response.data.data, providersEntity);

    return normalized.entities;
  }
);

export const archiveProvider = createAsyncThunk(
  'providers/archive',
  async (id: number) => {
    await client.put(`/archive/providers/${id}`);
    return id;
  }
);

export const unarchiveProvider = createAsyncThunk(
  'providers/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/providers/${id}`);
    return id;
  }
);

export const deleteProvider = createAsyncThunk(
  'providers/delete',
  async (id: number) => {
    await client.delete(`/providers/${id}`);
    return id;
  }
);

const providerAdapter = createEntityAdapter<Provider>();
const initialState = providerAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const providerSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        providerAdapter.upsertMany(
          state,
          safeArray(payload.providers.entities)
        );
        state.pagination = payload.providers.pagination;
      });
    });
    builder.addCase(fetchProviders.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchProviders.fulfilled, (state, { payload }) => {
      providerAdapter.upsertMany(state, safeArray(payload.providers));
      state.isLoading = false;
      state.pagination = payload.pagination;
    });
    builder.addCase(fetchProviders.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchProvider.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchProvider.fulfilled, (state, { payload }) => {
      providerAdapter.upsertMany(state, safeArray(payload.providers));
      state.isLoading = false;
    });
    builder.addCase(fetchProvider.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createProvider.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createProvider.fulfilled, (state, { payload }) => {
      providerAdapter.upsertMany(state, safeArray(payload.providers));
      state.isLoading = false;
    });
    builder.addCase(createProvider.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateProvider.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateProvider.fulfilled, (state, { payload }) => {
      providerAdapter.upsertMany(state, safeArray(payload.entities));
      state.isLoading = false;
    });
    builder.addCase(updateProvider.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteProvider.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteProvider.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteProvider.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
      providerAdapter.upsertMany(state, safeArray(payload.providers));
    });
  }
});

export const providerSelectors = providerAdapter.getSelectors<RootState>(
  state => state.providers
);

export default providerSlice.reducer;
