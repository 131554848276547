import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';

import Box from '@mui/material/Box';

const useStyles = makeStyles(theme => ({
  badgeContainer: {
    position: 'relative'
  },
  badge: {
    heigth: '1.3em',
    width: '1.3em',
    lineHeight: '1.3em',
    background: props => {
      if (props.color === 'default') {
        return theme.color.primary.main;
      }

      if (props.color === 'primary') {
        return theme.color.primary.main;
      }

      if (props.color === 'secondary') {
        return theme.color.secondary.main;
      }
    },
    borderRadius: '50%',
    alignItems: 'center',
    position: 'absolute'
  },
  badgeContent: {
    color: theme.color.common.white,
    fontSize: '.7em',
    fontWeight: 800
  }
}));

export default function Badge(props) {
  const classes = useStyles(props);
  const wrapperRef = useRef(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [wrapperHeight, setWrapperHeight] = useState(0);

  useEffect(() => {
    if (wrapperRef.current) {
      setWrapperWidth(wrapperRef.current.offsetWidth);
      setWrapperHeight(wrapperRef.current.offsetHeight);
    }
  }, [wrapperRef]);

  return (
    <>
      <Box
        className={classes.badgeContainer}
        display="inline-flex"
        ref={wrapperRef}
      >
        {props.children}
        <Box
          className={classes.badge}
          display="flex"
          justifyContent="center"
          style={{ left: wrapperWidth - 5, top: 0 - wrapperHeight / 2 }}
        >
          <Box className={classes.badgeContent}>
            {props.badgeContent > props.max
              ? props.max.toString() + '+'
              : props.badgeContent}
          </Box>
        </Box>
      </Box>
    </>
  );
}

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  badgeContent: PropTypes.number.isRequired,
  max: PropTypes.number,
  color: PropTypes.oneOf(['default', 'primary', 'secondary'])
};

Badge.defaultProps = {
  max: 10,
  color: 'default'
};
