import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';

import Button from './Button';
import Form from './Form';
import Label from './Label';
import Spacing from './Spacing';
import TextField from './TextField';

export default function FormFieldArray(props) {
  return (
    <div>
      <Label
        for={props.id}
        disabled={props.disabled}
        required={props.required && !props.requiredWithoutAsterisk}
        popover={{
          title: props.popoverTitle,
          text: props.popoverText
        }}
      >
        {props.label}
      </Label>
      <FieldArray name={props.id}>
        {({ fields }) =>
          fields.map((name, index) => (
            <Box key={`${name}_${index}`}>
              <Box display="flex" alignItems="center" gap={1}>
                <TextField
                  aria-labelledby={`${props.id}-label`}
                  id={name}
                  disabled={props.disabled}
                  fullWidth
                  required
                  requiredWithoutAsterisk
                />
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={props.disabled}
                  icon
                  onClick={() => fields.remove(index)}
                >
                  close
                </Button>
              </Box>
              <Form.Spacing />
            </Box>
          ))
        }
      </FieldArray>
    </div>
  );
}

FormFieldArray.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool
};

FormFieldArray.defaultProps = {
  required: false,
  disabled: false,
  requiredWithoutAsterisk: false
};
