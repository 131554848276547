import addMonths from './addMonths';

/**
 * Gets the nth weekday for a given `date`, `weekday` and `repeatCycle`
 * F.e: getNextWeekdayOfRepeatCycle(new Date(2024, 3, 15), 2, "monthly_second") => new Date(2021, 3, 16)
 * The weekdays are based on date.getDay(): 0 = sunday, 1 = monday...
 */
export default function getNextWeekdayOfRepeatCycle(
  date,
  weekday,
  repeatCycle
) {
  const repeatCycleMap = {
    monthly_first: 1,
    monthly_second: 2,
    monthly_third: 3,
    monthly_fourth: 4
  };

  if (!repeatCycleMap.hasOwnProperty(repeatCycle)) {
    throw new Error(
      'Invalid repeatCycle value. It must be one of "first", "second", "third", or "fourth".'
    );
  }

  const year = date.getFullYear();
  const month = date.getMonth();

  // Get the first day of the month
  const firstDayOfMonth = new Date(year, month, 1);
  const firstWeekdayOfMonth = firstDayOfMonth.getDay();

  // Calculate the offset to the first occurrence of the desired weekday
  let offset = (weekday - firstWeekdayOfMonth + 7) % 7;

  // Calculate the exact date of the nth weekday
  const nthWeekdayDate = 1 + offset + (repeatCycleMap[repeatCycle] - 1) * 7;

  // Check if the calculated date is within the same month
  const resultDate = new Date(date);
  resultDate.setDate(nthWeekdayDate);

  if (resultDate.getMonth() !== month) {
    throw new Error('The calculated date is out of the current month range.');
  }

  // resultDate < date -> skip over to the next month
  if (resultDate < date) {
    const newDate = addMonths(date, 1);
    newDate.setDate(1);
    return getNextWeekdayOfRepeatCycle(newDate, weekday, repeatCycle);
  }

  return resultDate;
}
