import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import Button from './Button';

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    width: '100vw',
    minHeight: '100vh',
    background: theme.color.background.grey
  },
  title: {
    fontWeight: 600,
    color: theme.color.text.light,
    lineHeight: '2.5em'
  },
  panel: {
    borderRadius: props => (props.size === 'fullscreen' ? '0' : '2px'),
    background: theme.color.background.default,
    maxWidth: '100%',
    height: props => (props.size === 'fullscreen' ? '100%' : 'auto'),
    width: props => {
      switch (props.size) {
        default:
        case 'small':
          return '20em';
        case 'medium':
          return '40em';
        case 'large':
          return '60em';
        case 'fullscreen':
          return '100%';
      }
    }
  }
}));

export default function Content(props) {
  const classes = useStyles(props);
  const buttonRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);

  useEffect(() => {
    if (props.back && props.title) {
      setButtonWidth(buttonRef.current.clientWidth);
    }
  }, [props.back, props.title]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.contentWrapper}
    >
      <Box className={classes.panel}>
        {(props.title || props.back) && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box ref={buttonRef}>
              {props.back && (
                <Button icon size="large" onClick={props.back}>
                  arrow-left
                </Button>
              )}
            </Box>
            <Box mr={`${buttonWidth}px`} className={classes.title}>
              {props.title}
            </Box>
            <Box />
          </Box>
        )}
        <Box px={3} py={2}>
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}

Content.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullscreen']).isRequired,
  title: PropTypes.string,
  back: PropTypes.func
};

Content.defaultProps = {
  children: undefined,
  topBar: false,
  title: undefined,
  back: undefined,
  drawer: undefined
};
