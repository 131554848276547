import React from 'react';
import { Box } from '@mui/system';

import FormGroup from '../FormGroup';
import FormatBoolean from '../FormatBoolean';
import FormatNumber from '../FormatNumber';
import Heading from '../Heading';
import InformationGrid from '../InformationGrid';
import MultiToggle from '../MultiToggle';
import NumberField from '../NumberField';
import Spacing from '../Spacing';
import TextField from '../TextField';
import Typeahead from '../Typeahead';
import Label from '../Label';

import jsonStrings from '../../utils/intl/jsonStrings';

const FIELD_ID_PREFIX = 'jsonField';

const FIELDS__REQUIRED_FIELD_NAME = 'required';
const FIELDS__DATA_TYPE_FIELD_NAME = 'type';
const FIELDS__EXPOSED_FIELD_NAME = 'exposed';
const FIELDS__IMPORTANT_FIELD_NAME = 'important';
const FIELDS__CATEGORY_FIELD_NAME = 'category';
const FIELDS__VALUE_FIELD_NAME = 'value';
const FIELDS__DISPLAY_LABEL_FIELD_NAME = 'portal_label';
// const FIELDS__INDOOR_OUTDOOR_LABEL_FIELD_NAME = 'indoor_outdoor_all';

const DATA_TYPE__ENUM_WITH_OPTIONS = /enum\(.*\)/;
// const DATA_TYPE__ENUM = 'enum';
const DATA_TYPE__BOOLEAN = 'boolean';
const DATA_TYPE__NUMBER = 'integer';
const DATA_TYPE__FLOAT = 'float';
const DATA_TYPE__TEXT_5 = 'text(5)';
const DATA_TYPE__TEXT_25 = 'text(25)';
const DATA_TYPE__TEXT_80 = 'text(80)';
const DATA_TYPE__TEXT_255 = 'text(255)';
const DATA_TYPE__TEXT_1000 = 'text(1000)';
const DATA_TYPE__MULTI_LINE_TEXT_1000 = 'multiline-text(1000)';
const DATA_TYPE__MULTI_LINE_TEXT_4000 = 'multiline-text(4000)';

// const ENUM_ITEM__FACILITY_TYPE = 'Anlagentyp';
// const ENUM_ITEM__CATEGORY = 'Kategorie';
// const ENUM_ITEM__FLOORING = 'Bodenbelagtyp';

const BOOLEAN_YES_STRINGS = ['j', 'ja', 'y', 'yes', 't', 'true', true];
const BOOLEAN_NO_STRINGS = ['n', 'nein', 'no', 'f', 'false', false];

// const searchGroupTypes = (searchTerm, groupTypes) =>
//   groupTypes.map(type => type.name.includes(searchTerm));

// const searchGroupFloorings = (searchTerm, groupFloorings) =>
//   groupFloorings.map(flooring => flooring.includes(searchTerm));

// const searchGroupCoverages = (searchTerm, groupCoverages) =>
//   groupCoverages.map(coverage => coverage.includes(searchTerm));

export function unique(value, index, self) {
  return self.indexOf(value) === index;
}

export function setJsonFieldValues(jsonFields, values) {
  const updatedJsonFields = { ...jsonFields };

  if (jsonFields && values && values[FIELD_ID_PREFIX]) {
    for (let field in jsonFields) {
      if (values[FIELD_ID_PREFIX]?.hasOwnProperty(field)) {
        let value = values[FIELD_ID_PREFIX][field];

        updatedJsonFields[field] = {
          ...jsonFields[field],
          value
        };
      } else {
        console.warn(
          `JSONFields: setJsonFieldValues could not find field '${FIELD_ID_PREFIX}.${field}' in values`
        );
      }
    }
  }

  return updatedJsonFields;
}

export function getJsonFieldValues(jsonFields = {}) {
  const result = {
    jsonFieldsTemplate: jsonFields,
    [FIELD_ID_PREFIX]: {}
  };

  if (jsonFields) {
    Object.keys(jsonFields).forEach(k => {
      result[FIELD_ID_PREFIX][k] = jsonFields[k][FIELDS__VALUE_FIELD_NAME];
    });
  }

  return result;
}

export function getJsonFieldInputComponent(
  value,
  item,
  type,
  required,
  important,
  internal,
  props,
  intl
) {
  if (DATA_TYPE__ENUM_WITH_OPTIONS.test(type)) {
    return getJsonFieldInputEnum(
      value,
      item,
      type,
      required,
      important,
      internal,
      type,
      props,
      intl
    );
  }

  switch (type) {
    // case DATA_TYPE__ENUM:
    //   return getJsonFieldInputEnum(
    //     value,
    //     item,
    //     type,
    //     required,
    //     important,
    //     internal,
    //     type,
    //     props,
    //     intl
    //   );

    case DATA_TYPE__BOOLEAN:
      return getJsonFieldInputBoolean(
        value,
        item,
        required,
        important,
        internal,
        props,
        intl
      );

    case DATA_TYPE__NUMBER:
      return getJsonFieldInputNumeric(
        value,
        item,
        required,
        important,
        internal,
        props,
        0
      );

    case DATA_TYPE__FLOAT:
      return getJsonFieldInputNumeric(
        value,
        item,
        required,
        important,
        internal,
        props,
        17
      );

    case DATA_TYPE__TEXT_5:
      return getJsonFieldInputString(
        value,
        item,
        required,
        important,
        internal,
        props,
        5,
        false
      );

    case DATA_TYPE__TEXT_25:
      return getJsonFieldInputString(
        value,
        item,
        required,
        important,
        internal,
        props,
        25,
        false
      );

    case DATA_TYPE__TEXT_80:
      return getJsonFieldInputString(
        value,
        item,
        required,
        important,
        internal,
        props,
        80,
        false
      );

    case DATA_TYPE__TEXT_255:
      return getJsonFieldInputString(
        value,
        item,
        required,
        important,
        internal,
        props,
        255,
        false
      );

    case DATA_TYPE__TEXT_1000:
      return getJsonFieldInputString(
        value,
        item,
        required,
        important,
        internal,
        props,
        1000,
        false
      );

    case DATA_TYPE__MULTI_LINE_TEXT_1000:
      return getJsonFieldInputString(
        value,
        item,
        required,
        important,
        internal,
        props,
        1000,
        true
      );

    case DATA_TYPE__MULTI_LINE_TEXT_4000:
      return getJsonFieldInputString(
        value,
        item,
        required,
        important,
        internal,
        props,
        4000,
        true
      );

    default:
      console.error(`Unknown json field data type: ${type}`);
      return null;
  }
}

export function getJsonFieldInputEnum(
  _value,
  item,
  type,
  required,
  important,
  internal,
  props,
  _intl
) {
  const options = type
    .substring(type.indexOf('enum(') + 5, type.lastIndexOf(')'))
    .split(',');

  return (
    <>
      <Spacing y={0.5} />
      <Typeahead
        id={`${FIELD_ID_PREFIX}.${item}`}
        label={
          <Label.Indicator important={important} internal={internal}>
            {item}
          </Label.Indicator>
        }
        disabled={props.disabled}
        fullWidth
        options={options}
        getOptionLabel={option => option}
        isOptionEqualToValue={(option, value) => option === value}
        required={required}
      />
    </>
  );

  // if (type === DATA_TYPE__ENUM) {
  //   if (item === ENUM_ITEM__FACILITY_TYPE) {
  //     return (
  //       <>
  //         <Spacing y={0.5} />
  //         <Typeahead
  //           id={`${FIELD_ID_PREFIX}.${item}`}
  //           label={
  //             <Label.Indicator important={important} internal={internal}>
  //               {item}
  //             </Label.Indicator>
  //           }
  //           disabled={props.disabled}
  //           fullWidth
  //           onInputChange={term => searchGroupTypes(term, props.groupTypes)}
  //           options={GroupTypes.map(type => type.name)}
  //           getOptionLabel={option =>
  //             jsonStrings[`group_type_${option}`]
  //               ? intl.formatMessage(jsonStrings[`group_type_${option}`])
  //               : option
  //           }
  //           isOptionEqualToValue={(option, value) => option === value}
  //           // required={required}
  //         />
  //       </>
  //     );
  //   }

  //   if (item === ENUM_ITEM__FACILITY_TYPE) {
  //     return (
  //       <>
  //         <Spacing y={0.5} />
  //         <Typeahead
  //           id={`${FIELD_ID_PREFIX}.${item}`}
  //           label={
  //             <Label.Indicator important={important} internal={internal}>
  //               {item}
  //             </Label.Indicator>
  //           }
  //           disabled={props.disabled}
  //           fullWidth
  //           onInputChange={term => searchGroupTypes(term, props.groupTypes)}
  //           options={GroupTypes.map(type => type.name)}
  //           getOptionLabel={option =>
  //             jsonStrings[`group_type_${option}`]
  //               ? intl.formatMessage(jsonStrings[`group_type_${option}`])
  //               : option
  //           }
  //           isOptionEqualToValue={(option, value) => option === value}
  //           // required={required}
  //         />
  //       </>
  //     );
  //   }

  //   if (item === ENUM_ITEM__CATEGORY) {
  //     return (
  //       <>
  //         <Spacing y={0.5} />
  //         <Typeahead
  //           id={`${FIELD_ID_PREFIX}.${item}`}
  //           label={
  //             <Label.Indicator important={important} internal={internal}>
  //               {item}
  //             </Label.Indicator>
  //           }
  //           disabled={props.disabled}
  //           fullWidth
  //           onInputChange={term =>
  //             searchGroupCoverages(term, props.groupCoverages)
  //           }
  //           options={GroupCoverages}
  //           getOptionLabel={option =>
  //             jsonStrings[option]
  //               ? intl.formatMessage(jsonStrings[option])
  //               : option
  //           }
  //           isOptionEqualToValue={(option, value) => option === value}
  //           // required={required}
  //         />
  //       </>
  //     );
  //   }

  //   if (item === ENUM_ITEM__FLOORING) {
  //     return (
  //       <>
  //         <Spacing y={0.5} />
  //         <Typeahead
  //           id={`${FIELD_ID_PREFIX}.${item}`}
  //           label={
  //             <Label.Indicator important={important} internal={internal}>
  //               {item}
  //             </Label.Indicator>
  //           }
  //           disabled={props.disabled}
  //           fullWidth
  //           onInputChange={term =>
  //             searchGroupFloorings(term, props.groupFloorings)
  //           }
  //           options={GroupFloorings}
  //           getOptionLabel={option =>
  //             jsonStrings[`group_flooring_${option}`]
  //               ? intl.formatMessage(jsonStrings[`group_flooring_${option}`])
  //               : option
  //           }
  //           isOptionEqualToValue={(option, value) => option === value}
  //         />
  //       </>
  //     );
  //   }
  // }
}

export function getJsonFieldInputBoolean(
  _value,
  item,
  required,
  important,
  internal,
  props,
  intl
) {
  return (
    <MultiToggle
      id={`${FIELD_ID_PREFIX}.${item}`}
      label={
        <Label.Indicator important={important} internal={internal}>
          {item}
        </Label.Indicator>
      }
      disabled={props.disabled}
      options={[
        {
          title: intl.formatMessage(jsonStrings['yes']),
          index: true
        },
        ...(required
          ? []
          : [
              {
                title: intl.formatMessage(jsonStrings['undefined']),
                index: null
              }
            ]),
        {
          title: intl.formatMessage(jsonStrings['no']),
          index: false
        }
      ]}
      // required={required}
    />
  );
}

export function getJsonFieldInputNumeric(
  _value,
  item,
  _required,
  important,
  internal,
  props,
  decimalScale
) {
  return (
    <>
      <Spacing y={0.5} />
      <NumberField
        id={`${FIELD_ID_PREFIX}.${item}`}
        label={
          <Label.Indicator important={important} internal={internal}>
            {item}
          </Label.Indicator>
        }
        disabled={props.disabled}
        allowNegative={false}
        decimalScale={decimalScale}
        // required={required}
      />
    </>
  );
}

export function getJsonFieldInputString(
  _value,
  item,
  _required,
  important,
  internal,
  props,
  maxLength,
  multiLine
) {
  return (
    <>
      <Spacing y={0.5} />
      <TextField
        id={`${FIELD_ID_PREFIX}.${item}`}
        name={item}
        label={
          <Label.Indicator important={important} internal={internal}>
            {item}
          </Label.Indicator>
        }
        disabled={props.disabled}
        fullWidth={maxLength > 5}
        maxLength={maxLength}
        multiline={multiLine}
        // required={required}
        type="text"
      />
    </>
  );
}

export function renderFields(fields, categories, props) {
  const tmpCategories = Object.keys(fields)
    // Apply filter to get categories
    .filter(item => props.filter(fields[item]))
    .map(item => fields[item][FIELDS__CATEGORY_FIELD_NAME])
    .filter(unique)
    .sort()
    .map(category => {
      return {
        name: category,
        items: renderCategoryItems(
          // Apply filter to get fields
          Object.fromEntries(
            Object.entries(fields).filter(item => props.filter(item[1]))
          ),
          category,
          props
        ).filter(item => item !== null),
        order: categories?.filter(c => c.category_name === category)[0]?.order
      };
    })
    .filter(category => category.items?.length > 0)
    .sort((a, b) => a.order - b.order);

  return tmpCategories.map(category => {
    if (category.items.length) {
      return (
        <React.Fragment key={category.name}>
          <InformationGrid
            labelWidth={props.labelWidth}
            title={
              <Heading
                type="h2"
                sideline
                icon={props.disableCategoryIcons ? null : 'info-circle'}
              >
                {category.name}
              </Heading>
            }
            key={category.name}
          >
            {category.items}
          </InformationGrid>
        </React.Fragment>
      );
    }

    return null;
  });
}

export function renderCategoryItems(fields, category, props) {
  return Object.keys(fields)
    .filter(
      item =>
        fields[item][FIELDS__CATEGORY_FIELD_NAME] === category &&
        // Filter out `unset` fields
        fields[item][FIELDS__VALUE_FIELD_NAME] !== null
    )
    .sort((a, b) => fields[a].index - fields[b].index)
    .map(item => {
      const dataType = fields[item][FIELDS__DATA_TYPE_FIELD_NAME];
      const internal = !fields[item][FIELDS__EXPOSED_FIELD_NAME];
      const label = props.internal
        ? item
        : fields[item][FIELDS__DISPLAY_LABEL_FIELD_NAME]?.length
          ? fields[item][FIELDS__DISPLAY_LABEL_FIELD_NAME]
          : item;
      let value = fields[item][FIELDS__VALUE_FIELD_NAME];

      if (props.internal || !internal) {
        if (dataType === DATA_TYPE__BOOLEAN) {
          if (BOOLEAN_YES_STRINGS.includes(value)) {
            value = <FormatBoolean>{true}</FormatBoolean>;
          }

          if (BOOLEAN_NO_STRINGS.includes(value)) {
            value = <FormatBoolean>{false}</FormatBoolean>;
          }
        } else if (dataType === DATA_TYPE__NUMBER) {
          if (value) {
            value = <FormatNumber>{Number(value)}</FormatNumber>;
          }
        } else if (
          dataType === DATA_TYPE__FLOAT &&
          !['x-Wert (ETRS 89)', 'y-Wert (ETRS 89)'].includes(item)
        ) {
          value = <FormatNumber>{Number(value)}</FormatNumber>;
        } else if (['x-Wert (ETRS 89)', 'y-Wert (ETRS 89)'].includes(item)) {
          value = `${value}`.replace('.', ',');
        }

        return (
          <InformationGrid.Item internal={internal} value={label} key={label}>
            {value}
          </InformationGrid.Item>
        );
      }

      return null;
    });
}

export function renderEditFields(fields, categories, props, intl) {
  if (fields) {
    const tmpCategories = Object.keys(fields)
      // Apply filter to get categories
      .filter(item => props.filter(fields[item]))
      .map(item => fields[item][FIELDS__CATEGORY_FIELD_NAME])
      .filter(unique)
      .sort()
      .map(category => {
        return {
          name: category,
          items: renderEditCategoryItems(
            // Apply filter to get fields
            Object.fromEntries(
              Object.entries(fields).filter(item => props.filter(item[1]))
            ),
            category,
            props,
            intl
          ),
          order: categories?.filter(c => c.category_name === category)[0]?.order
        };
      })
      .sort((a, b) => a.order - b.order);

    return tmpCategories.map((category, i) => {
      return (
        <FormGroup
          expectedInputs={category.items?.length}
          title={category.name}
          key={category.name}
          index={
            Number.isInteger(props.startIndex) ? props.startIndex + i : null
          }
          expandable={
            props.expandable &&
            props.expandableCategories?.filter(c => c.name === category.name)[0]
              ?.changedFields?.length === 0
          }
        >
          {category.items}
        </FormGroup>
      );
    });
  }
}

export function renderEditCategoryItems(fields, category, props, intl) {
  return Object.keys(fields)
    .filter(item => fields[item][FIELDS__CATEGORY_FIELD_NAME] === category)
    .sort((a, b) => fields[a].index - fields[b].index)
    .map(item => {
      const dataType = fields[item][FIELDS__DATA_TYPE_FIELD_NAME];
      const required = fields[item][FIELDS__REQUIRED_FIELD_NAME];
      const important = fields[item][FIELDS__IMPORTANT_FIELD_NAME];
      const internal = !fields[item][FIELDS__EXPOSED_FIELD_NAME];
      const value = fields[item][FIELDS__VALUE_FIELD_NAME];

      return (
        <Box key={item}>
          {getJsonFieldInputComponent(
            value,
            item,
            dataType,
            required,
            important,
            internal,
            props,
            intl
          )}
          <Spacing y={2} />
        </Box>
      );
    });
}
