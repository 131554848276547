import { isBefore } from './index';

/**
 * Check if given date is next week
 */
export default function isNextWeek(dt) {
  const td = new Date();
  const nw = new Date(td.getFullYear(), td.getMonth(), td.getDate() + 8);

  return isBefore(td, dt) && isBefore(dt, nw);
}
