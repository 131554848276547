import { AnyAction } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit';

import { BookingPlan, Pagination, UUID } from '../interfaces';
import { RootState } from '.';
import { client } from '../api/client';
import { bookingPlansEntity } from '../schemas';
import { paginate, safeArray } from '../utils';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const fetchBookingPlans = createAsyncThunk(
  'bookingPlans/single',
  async (
    filter: Pagination & {
      group_id: UUID;
      mode: string;
      day_from: string;
      day_to: string;
      status?: string;
      published?: boolean;
      with_holiday_conflicts?: boolean;
      with_public_holiday_conflicts?: boolean;
    }
  ) => {
    const response = await client.get('/booking_plan', filter);

    return response.data?.data;
  }
);

const bookingPlanAdapter = createEntityAdapter<BookingPlan>();
const initialState = bookingPlanAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const bookingPlanSlice = createSlice({
  name: 'bookingPlans',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        bookingPlanAdapter.upsertMany(
          state,
          safeArray(payload.bookingPlans.entities)
        );
        state.isLoading = false;
        state.pagination = payload.bookingPlans.pagination;
      });
    });
    builder.addCase(fetchBookingPlans.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchBookingPlans.rejected, state => {
      state.isLoading = false;
    });
  }
});

export const bookingPlanSelectors = bookingPlanAdapter.getSelectors<RootState>(
  state => state.bookingPlans
);

export default bookingPlanSlice.reducer;
