import React from 'react';
import PropTypes from 'prop-types';
import FormatNumber from './FormatNumber';

export default function FormatLatLng(props) {
  return (
    <>
      {props.lat ? <FormatNumber>{props.lat}</FormatNumber> : '-'}
      {` | `}
      {props.lng ? <FormatNumber>{props.lng}</FormatNumber> : '-'}
    </>
  );
}

FormatLatLng.propTypes = {
  lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

FormatLatLng.defaultProps = {
  lat: undefined,
  lng: undefined
};
