/**
 * Check if given input is a valid Date.
 */
export default function isDate(d) {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    if (Number.isNaN(d.getTime())) {
      return false;
    }

    return true;
  }

  return false;
}
