import React from 'react';

import PartialContent from './PartialContent';
import Spacing from './Spacing';
import Text from './Text';
import Void from './Void';

export default function FormPresenterPartialContent(props) {
  const { title, spacing = 1 } = props;

  return (
    <>
      {title ? <Text bold>{`${title}:`}</Text> : <Void />}
      <PartialContent
        visibleContent={props.visibleContent}
        hiddenContent={props.hiddenContent}
        linkComponent={props.linkComponent}
      />
      <Spacing y={spacing} />
    </>
  );
}
