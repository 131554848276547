import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { client } from '../api/client';
import { unitAttributesEntity } from '../schemas';
import { fetchUnit, fetchUnits } from './unit.slice';
import { UnitAttribute } from '../interfaces';
import { RootState } from '.';
import { fetchBooking, fetchBookings } from './booking.slice';
import { safeArray } from '../utils';
import { fetchGroup, updateGroup } from './group.slice';
import { fetchRequest } from './request.slice';
import { fetchDomain } from './domain.slice';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const fetchUnitAttributes = createAsyncThunk(
  'unitAttributes/all',
  async () => {
    const response = await client.get('/unit_attributes');
    const normalized = normalize(response.data.data, [unitAttributesEntity]);

    return normalized.entities;
  }
);

const unitAttributeAdapter = createEntityAdapter<UnitAttribute>();
const initialState = unitAttributeAdapter.getInitialState({
  isLoading: false
});
const unitAttributeSlice = createSlice({
  name: 'unitAttributes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        unitAttributeAdapter.upsertMany(
          state,
          safeArray(payload.unitAttributes.entities)
        );
      });
    });
    builder.addCase(fetchUnitAttributes.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchUnitAttributes.fulfilled, (state, { payload }) => {
      unitAttributeAdapter.upsertMany(state, safeArray(payload.unitAttributes));
      state.isLoading = false;
    });
    builder.addCase(fetchUnitAttributes.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchDomain.fulfilled, (state, { payload }) => {
      unitAttributeAdapter.upsertMany(state, safeArray(payload.unitAttributes));
    });
    builder.addCase(fetchGroup.fulfilled, (state, { payload }) => {
      unitAttributeAdapter.upsertMany(state, safeArray(payload.unitAttributes));
    });
    builder.addCase(fetchUnit.fulfilled, (state, { payload }) => {
      unitAttributeAdapter.upsertMany(state, safeArray(payload.unitAttributes));
    });
    builder.addCase(fetchUnits.fulfilled, (state, { payload }) => {
      unitAttributeAdapter.upsertMany(state, safeArray(payload.unitAttributes));
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      unitAttributeAdapter.upsertMany(state, safeArray(payload.unitAttributes));
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      unitAttributeAdapter.upsertMany(state, safeArray(payload.unitAttributes));
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      unitAttributeAdapter.upsertMany(state, safeArray(payload.unitAttributes));
    });
    builder.addCase(updateGroup.fulfilled, (state, { payload }) => {
      unitAttributeAdapter.upsertMany(state, safeArray(payload.unitAttributes));
    });
  }
});

export const unitAttributeSelectors =
  unitAttributeAdapter.getSelectors<RootState>(state => state.unitAttributes);

export default unitAttributeSlice.reducer;
