import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';

import Heading from './Heading';
import Button from './Button';
import Spacing from './Spacing';

const useStyles = makeStyles(theme => ({
  headingFullWidth: {
    width: '100%'
  },
  formGroup: {
    width: '100%',
    maxWidth: '52.5rem'
  },
  index: {
    background: theme.color.primary.main,
    borderRadius: '50%',
    color: theme.color.primary.contrastText,
    fontSize: theme.font.size.small,
    height: '1.5rem',
    lineHeight: '1.5rem',
    textAlign: 'center',
    width: '1.5rem'
  }
}));

export default function FormGroup(props) {
  const classes = useStyles(props);
  const [expanded, setExpanded] = useState(false);
  const [asyncRendered, setAsyncRendered] = useState(props.disableAsyncRender);

  useEffect(() => {
    setTimeout(
      () => {
        setAsyncRendered(true);
      },
      (props.index ? props.index : 1) * 10
    );
  }, []);

  return (
    <Box
      role="group"
      display="inline-flex"
      aria-label={props.title}
      className={classes.formGroup}
      data-class="form-group"
      data-testid={props['data-testid']}
    >
      {asyncRendered ? (
        <Box mb={4} width={1} style={{ boxSizing: 'border-box' }}>
          <Box width={1} display="flex" justifyContent="space-between">
            <Box mb={1} width={1} display="flex" alignItems="center">
              {props.index ? (
                <Box flexShrink={0} className={classes.index} mr={1}>
                  {props.index}
                </Box>
              ) : null}
              <Heading
                type="h2"
                sideline
                icon={props.icon}
                className={classes.headingFullWidth}
                popoverIcon={props.popoverIcon}
                popoverText={props.popoverText}
                popoverTitle={props.popoverTitle}
              >
                {props.title}
              </Heading>
            </Box>
            {props.expandable ? (
              <Button
                onClick={() => setExpanded(!expanded)}
                icon
                // TBD: Intl
                aria-label={`${expanded ? 'Schließe' : 'Öffne'} Formgruppe ${
                  props.title
                }`}
                data-testid={
                  props['data-testid']
                    ? `${props['data-testid']}-expander`
                    : undefined
                }
              >
                {expanded ? 'arrow-up' : 'arrow-down'}
              </Button>
            ) : null}
          </Box>
          <div
            style={{
              display:
                (props.expandable && expanded) || !props.expandable
                  ? 'initial'
                  : 'none'
            }}
          >
            {props.children}
          </div>
        </Box>
      ) : (
        <Box gap={1} width="100%" mb={4}>
          <Box display="flex" gap={1} width="100%" mb={2}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={256} />
          </Box>
          {[
            ...Array(
              props.expectedInputs + Math.ceil(props.expectedInputs * 0.2)
            ).keys()
          ].map(k => (
            <React.Fragment key={k}>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={128} />
              <Skeleton variant="rounded" width="100%" height={32} />
              <Spacing y={2} />
            </React.Fragment>
          ))}
        </Box>
      )}
    </Box>
  );
}

FormGroup.propTypes = {
  title: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  expandable: PropTypes.bool,
  expectedInputs: PropTypes.number,
  icon: PropTypes.string,
  required: PropTypes.bool,
  disableAsyncRender: PropTypes.bool,
  'data-testid': PropTypes.string,
  children: PropTypes.node.isRequired
};

FormGroup.defaultProps = {
  fullWidth: false,
  expandable: false,
  expectedInputs: 5,
  disableAsyncRender: false,
  'data-testid': undefined,
  icon: undefined
};
