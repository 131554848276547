import { AnyAction } from 'redux';
import { normalize } from 'normalizr';
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { Notification, Pagination } from '../interfaces';
import { RootState } from '.';
import { paginate, safeArray } from '../utils';
import { client } from '../api/client';
import { notificationsEntity } from '../schemas/notification.schema';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const createNotification = createAsyncThunk(
  'notifications/create',
  async notification => {
    const response = await client.post('/notifications', { notification });
    const normalized = normalize(response.data.data, notificationsEntity);

    return normalized.entities;
  }
);

export const fetchNotification = createAsyncThunk(
  'notifications/single',
  async (id: string) => {
    const response = await client.get(`/notifications/${id}`);
    const normalized = normalize(response.data.data, notificationsEntity);

    return normalized.entities;
  }
);

export const fetchNotifications = createAsyncThunk(
  'notifications/all',
  async (filter: Pagination) => {
    const response = await client.get(`/notifications`, filter);
    const normalized = normalize(response.data.data, [notificationsEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const archiveNotification = createAsyncThunk(
  'notifications/archive',
  async (id: number) => {
    await client.put(`/archive/notifications/${id}`);
    return id;
  }
);

export const unarchiveNotification = createAsyncThunk(
  'notifications/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/notifications/${id}`);
    return id;
  }
);

export const deleteNotification = createAsyncThunk(
  'notifications/delete',
  async (id: number) => {
    await client.delete(`/notifications/${id}`);
    return id;
  }
);

const notificationAdapter = createEntityAdapter<Notification>();
const initialState = notificationAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        notificationAdapter.upsertMany(
          state,
          safeArray(payload.notifications.entities)
        );
        state.isLoading = false;
        state.pagination = payload.notifications.pagination;
      });
    });
    builder.addCase(createNotification.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createNotification.fulfilled, (state, { payload }) => {
      notificationAdapter.upsertMany(state, safeArray(payload.notifications));
      state.isLoading = false;
    });
    builder.addCase(createNotification.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchNotification.fulfilled, (state, { payload }) => {
      notificationAdapter.upsertMany(state, safeArray(payload.notifications));
      state.isLoading = false;
    });
    builder.addCase(fetchNotification.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchNotification.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchNotifications.fulfilled, (state, { payload }) => {
      notificationAdapter.upsertMany(state, safeArray(payload.notifications));
      state.isLoading = false;
      state.pagination = payload.pagination;
    });
    builder.addCase(fetchNotifications.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchNotifications.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(archiveNotification.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(archiveNotification.fulfilled, (state, { payload }) => {
      notificationAdapter.removeOne(state, payload.toString());
      state.isLoading = false;
    });
    builder.addCase(archiveNotification.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteNotification.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteNotification.fulfilled, (state, { payload }) => {
      notificationAdapter.removeOne(state, payload.toString());
      state.isLoading = false;
    });
    builder.addCase(deleteNotification.rejected, state => {
      state.isLoading = false;
    });
  }
});

export const notificationSelectors =
  notificationAdapter.getSelectors<RootState>(state => state.notifications);

export default notificationSlice.reducer;
