import React from 'react';

export default function Void(props) {
  return (
    <div
      style={{
        display: 'none'
      }}
    />
  );
}
