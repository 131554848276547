import { isSameDayDate } from '../date/index';

export default function getEventListEvents(events) {
  const result = [];

  if (events && events.length) {
    events.sort((a, b) => a.start - b.start);

    let prevEvent = events[0];
    let day = { day: events[0].start, events: [] };

    events.forEach(e => {
      if (isSameDayDate(e.start, prevEvent.start)) {
        day.events.push(e);
      } else {
        result.push(day);
        day = { day: e.start, events: [e] };
      }

      prevEvent = e;
    });

    result.push(day);
  }

  return result;
}
