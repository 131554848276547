import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  spacing: {
    height: props =>
      theme.spacing(props.y) ? theme.spacing(props.y) : `${props.x * 8}px`,
    width: props =>
      theme.spacing(props.x) ? theme.spacing(props.x) : `${props.x * 8}px`,
    flexShrink: 0
  }
}));

export default function Spacing(props) {
  const classes = useStyles(props);
  return <div className={classes.spacing} />;
}

Spacing.propTypes = {
  x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  y: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Spacing.defaultProps = {
  x: 0,
  y: 0
};
