import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import hrefFromQuery from '../utils/hrefFromQuery';
import { Request, Pagination } from '../interfaces';
import { RootState } from '.';
import { client } from '../api/client';
import { requestEntity } from '../schemas';
import { safeArray, paginate } from '../utils';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const fetchRequest = createAsyncThunk(
  'requests/single',
  async (id: string) => {
    const response = await client.get(`/booking_requests/${id}`);
    const normalized = normalize(response.data.data, requestEntity);

    return normalized.entities;
  }
);

export const fetchRequestWithAllPreloads = createAsyncThunk(
  'requests/single',
  async (id: string) => {
    const response = await client.get(
      `/booking_requests/${id}?preload_all=true`
    );
    const normalized = normalize(response.data.data, requestEntity);

    return normalized.entities;
  }
);

export const fetchRequests = createAsyncThunk(
  'requests/all',
  async (
    filter: Pagination & {
      order_by: string;
      preload_simple_events?: boolean;
      preload_consumer_teams?: boolean;
      drafts?: boolean;
    }
  ) => {
    const response = await client.get(`/booking_requests`, filter);
    const normalized = normalize(response.data.data, [requestEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const fetchRequestsWithOptions = createAsyncThunk(
  'requests/all',
  async (options: any) => {
    const query = hrefFromQuery('/booking_requests', options);
    let response = await client.get(query);
    const normalized = normalize(
      options.booking_id
        ? { ...response.data.data, booking_id: options.booking_id }
        : response.data.data,
      [requestEntity]
    );

    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const createRequest = createAsyncThunk(
  'requests/create',
  async (request: Request) => {
    const response = await client.post('/booking_requests', {
      booking_request: request
    });
    const normalized = normalize<
      any,
      {
        requests: { [key: string]: Request };
      }
    >(response.data.data, requestEntity);

    return normalized.entities;
  }
);

export const updateRequest = createAsyncThunk(
  'requests/update',
  async ({ id, request }: { id: string; request: Partial<Request> }) => {
    const response = await client.put(`/booking_requests/${id}`, {
      booking_request: request
    });
    const normalized = normalize(response.data.data, requestEntity);

    return normalized.entities;
  }
);

export const archiveRequest = createAsyncThunk(
  'requests/archive',
  async (id: number) => {
    await client.put(`/archive/booking_requests/${id}`);
    return id;
  }
);

export const unarchiveRequest = createAsyncThunk(
  'requests/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/booking_requests/${id}`);
    return id;
  }
);

export const deleteRequest = createAsyncThunk(
  'requests/delete',
  async (id: string) => {
    await client.delete(`/booking_requests/${id}`);
    return id;
  }
);

const requestAdapter = createEntityAdapter<Request>();
const initialState = requestAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const requestSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        requestAdapter.upsertMany(state, safeArray(payload.requests.entities));
        state.isLoading = false;
        state.pagination = payload.requests.pagination;
      });
    });
    builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
      requestAdapter.upsertMany(state, safeArray(payload.requests));
      state.pagination = payload.pagination;
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      requestAdapter.upsertMany(state, safeArray(payload.requests));
    });
    builder.addCase(createRequest.fulfilled, (state, { payload }) => {
      requestAdapter.upsertMany(state, safeArray(payload.requests));
    });
    builder.addCase(updateRequest.fulfilled, (state, { payload }) => {
      requestAdapter.upsertMany(state, safeArray(payload.requests));
    });
  }
});

export const requestSelectors = requestAdapter.getSelectors<RootState>(
  state => state.requests
);

export default requestSlice.reducer;
