import React from 'react';

import Text from './Text';
import Spacing from './Spacing';
import Void from './Void';

export default function FormPresenterTitle(props) {
  const { avatar, title, spacing = 1 } = props;

  return (
    <>
      {avatar ? (
        <div style={{ marginTop: '.5em', marginRight: '.5em' }}>
          {avatar}
          <Spacing x={1} />
        </div>
      ) : (
        <Void />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Text color="dark">{title}</Text>
      </div>
      <Spacing y={spacing} />
    </>
  );
}
