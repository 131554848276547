import getConfig from 'next/config';
import { AnyAction } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import { createSlice } from '@reduxjs/toolkit';

import { FeatureState } from '../interfaces';

const env = getConfig()?.publicRuntimeConfig;

const initialState: FeatureState = {
  calendarDayView: env.isDevelopment || false,
  calendarWeekView: env.isDevelopment || false
};

const featureSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    setToggles(state: FeatureState, { payload }: AnyAction) {
      return { ...payload };
    }
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state: FeatureState, { payload }: AnyAction) => ({
      ...payload.feature
    }));
  }
});

export const { setToggles } = featureSlice.actions;

export default featureSlice.reducer;
