import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import domtoimage from 'dom-to-image';
import { CircularProgress } from '@mui/material';
import { IconButton } from '@mui/material';
import { saveAs } from 'file-saver';
import { useState } from 'react';

import Icon from './Icon';

function triggerResizeEvent() {
  window.dispatchEvent(new Event('resize'));
}

function createPrintPlaceholder() {
  const placeholderDiv = (
    <div
      style={{
        background: 'white',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        overflow: 'hidden',
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 1000
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <CircularProgress />
      </div>
    </div>
  );

  var element = document.createElement('div');
  element.innerHTML = '<div id="printPlaceholder"></div>';
  document.body.appendChild(element);
  ReactDOM.render(placeholderDiv, document.querySelector('#printPlaceholder'));

  return element;
}

function removePrintPlaceholder(element) {
  if (element) {
    document.body.removeChild(element);
  }
}

export default function PrintButton(props) {
  const [style, setStyle] = useState(null);

  function applyPrintStyling() {
    if (props.margin || props.height || props.width || props.zoom) {
      var styles = `${props.selector} {`;

      if (props.margin) {
        styles += `margin: ${props.margin} !important; box-sizing: border-box;`;
      }

      if (props.width) {
        styles += `width: ${props.width} !important;`;
      }

      if (props.height) {
        styles += `height: ${props.height} !important;`;
      }

      styles += '}';

      if (props.zoom) {
        styles += `html {font-size: ${props.zoom}% !important;}`;
      }

      var styleSheet = document.createElement('style');
      styleSheet.innerText = styles;
      document.head.appendChild(styleSheet);

      triggerResizeEvent();
    }

    setStyle({ display: 'none' });

    return styleSheet;
  }

  function removePrintStylesheet(printStylesheet) {
    triggerResizeEvent();
    setStyle({ display: 'block' });

    if (printStylesheet) {
      document.head.removeChild(printStylesheet);
    }
  }

  function createPrintHeader() {
    if (props.header) {
      const element = document.createElement('div');
      element.innerHTML = '<div id="printHeader"></div>';
      document.querySelector(props.selector).prepend(element);
      ReactDOM.render(props.header, document.querySelector('#printHeader'));

      return element;
    }

    return null;
  }

  function removePrintHeader(element) {
    if (element) {
      document.querySelector(props.selector).removeChild(element);
    }
  }

  function downloadCanvas() {
    const printPlaceholder = createPrintPlaceholder();
    const printStylesheet = applyPrintStyling();
    const printHeader = createPrintHeader();

    setTimeout(() => {
      if (typeof props.handleBeforePrint === 'function') {
        props.handleBeforePrint();
      }

      setTimeout(() => {
        domtoimage
          .toPng(document.querySelector(props.selector))
          .then(dataUrl => {
            if (props.saveAs) {
              saveAs(dataUrl, props.fileName ?? 'file.png');
            } else {
              const printWindow = window.open();
              printWindow.document.write(
                `<img ${
                  props.outputImageAlt ? `alt="${props.outputImageAlt}"` : ''
                } style="width: 100%;" src=\'` +
                  dataUrl +
                  "'/>"
              );
            }

            removePrintStylesheet(printStylesheet);
            removePrintHeader(printHeader);

            setTimeout(() => {
              if (typeof props.handleAfterPrint === 'function') {
                props.handleAfterPrint();
                setTimeout(() => {
                  removePrintPlaceholder(printPlaceholder);
                }, props.timeout);
              } else {
                removePrintPlaceholder(printPlaceholder);
              }
            }, props.timeout);
          });
      }, props.timeout);
    }, props.timeout);
  }

  return (
    <IconButton
      data-testid="print-button"
      onClick={downloadCanvas}
      disabled={props.disabled}
      style={style}
      title={props.buttonTitle ?? 'print'}
    >
      <Icon name="print" aria-label={null} />
    </IconButton>
  );
}

PrintButton.propTypes = {
  buttonTitle: PropTypes.string,
  fileName: PropTypes.string,
  handleAfterPrint: PropTypes.func,
  disabled: PropTypes.bool,
  handleBeforePrint: PropTypes.func,
  zoom: PropTypes.number,
  header: PropTypes.any,
  height: PropTypes.string,
  margin: PropTypes.string,
  saveAs: PropTypes.bool,
  selector: PropTypes.string.isRequired,
  timeout: PropTypes.number,
  outputImageAlt: PropTypes.string,
  width: PropTypes.string
};

PrintButton.defaultProps = {
  buttonTitle: undefined,
  disabled: false,
  fileName: 'file.png',
  handleAfterPrint: undefined,
  handleBeforePrint: undefined,
  header: undefined,
  height: undefined,
  margin: undefined,
  saveAs: false,
  timeout: 250,
  outputImageAlt: undefined,
  width: undefined
};
