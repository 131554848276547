import { AnyAction } from 'redux';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { fetchBooking, fetchBookings } from './booking.slice';
import { RootState } from '.';
import { safeArray } from '../utils';
import { fetchGroup, updateGroup } from './group.slice';
import { fetchRequest } from './request.slice';
import { fetchUnit, fetchUnits } from './unit.slice';
import { fetchDomain } from './domain.slice';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const unitInventoryAdapter = createEntityAdapter();
const initialState = unitInventoryAdapter.getInitialState({
  isLoading: false
});
const unitInventorySlice = createSlice({
  name: 'unitInventories',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        unitInventoryAdapter.upsertMany(
          state,
          safeArray(payload.unitInventories.entities)
        );
      });
    });
    builder.addCase(fetchGroup.fulfilled, (state, { payload }) => {
      unitInventoryAdapter.upsertMany(
        state,
        safeArray(payload.unitInventories)
      );
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      unitInventoryAdapter.upsertMany(
        state,
        safeArray(payload.unitInventories)
      );
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      unitInventoryAdapter.upsertMany(
        state,
        safeArray(payload.unitInventories)
      );
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      unitInventoryAdapter.upsertMany(
        state,
        safeArray(payload.unitInventories)
      );
    });
    builder.addCase(fetchDomain.fulfilled, (state, { payload }) => {
      unitInventoryAdapter.upsertMany(
        state,
        safeArray(payload.unitInventories)
      );
    });
    builder.addCase(fetchUnit.fulfilled, (state, { payload }) => {
      unitInventoryAdapter.upsertMany(
        state,
        safeArray(payload.unitInventories)
      );
    });
    builder.addCase(fetchUnits.fulfilled, (state, { payload }) => {
      unitInventoryAdapter.upsertMany(
        state,
        safeArray(payload.unitInventories)
      );
    });
    builder.addCase(updateGroup.fulfilled, (state, { payload }) => {
      unitInventoryAdapter.upsertMany(
        state,
        safeArray(payload.unitInventories)
      );
    });
  }
});

export const unitInventorySelectors =
  unitInventoryAdapter.getSelectors<RootState>(state => state.unitInventories);

export default unitInventorySlice.reducer;
