/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';
import { consumersEntity } from './consumer.schema';
import { providersEntity } from './provider.schema';
import { organizationMembersEntity } from './organizationMember.schema';
import getDefaultAddress from '../selectors/address/getDefaultAddress';
import getDefaultContact from '../selectors/contact/getDefaultContact';

export const organizationsEntity = new schema.Entity(
  'organizations',
  {
    organization_members: [organizationMembersEntity],
    consumers: [consumersEntity],
    providers: [providersEntity]
  },
  {
    processStrategy: organization => ({
      ...organization,
      defaultAddress: getDefaultAddress(organization?.addresses),
      defaultContact: getDefaultContact(organization?.contacts)
    })
  }
);
