import Cookies from 'js-cookie';
import axios from 'axios';

import { SESSION_COOKIE } from 'shared/Redux/api/session';
import { isClient } from '../../App/utils/Globals';

export default function logoutSideEffects() {
  axios.defaults.headers.common.Authorization = null;

  Cookies.remove('auth');
  Cookies.remove(SESSION_COOKIE);

  if (isClient()) {
    localStorage.clear();
  }
}

export function logoutRedirectUrl(url) {
  if (url?.startsWith('/login')) {
    return url;
  } else if (
    ['/dashboard', '/404']
      .map(x => (url || '').startsWith(x))
      .reduce((a, b) => a || b)
  ) {
    return '/login';
  } else {
    return url ? `/login?redirectUrl=${url}` : '/login';
  }
}
