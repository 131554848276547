import BoringAvatar from 'boring-avatars';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => {
  function getSize(size) {
    switch (size) {
      case 'small':
        return '2';
      case 'medium':
        return '4';
      case 'large':
        return '6';
      default:
        return parseFloat(size);
    }
  }

  return {
    avatarBox: {
      flexShrink: 0,
      borderRadius: '50%',
      width: props => `${getSize(props.size)}em`,
      height: props => `${getSize(props.size)}em`,
      alignItems: 'center',
      background: theme.color.primary.main,
      '& svg': {
        height: '95%',
        width: '95%'
      }
    },
    font: {
      color: theme.color.common.white,
      fontWeight: 800,
      fontSize: props => `${getSize(props.size) / 2}em`
    },
    pictureBox: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      borderRadius: '50%'
    },
    avatarImg: {
      borderRadius: '50%',
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  };
});

export default function Avatar(props) {
  const classes = useStyles(props);

  function getInitials() {
    if (!!!props.name) {
      return null;
    }

    const splittedName = props.name.split(' ');
    const firstLetter = splittedName[0].charAt(0);
    const lastLetter = splittedName[splittedName.length - 1].charAt(0);

    return `${firstLetter}${lastLetter}`;
  }

  return (
    <Box className={classes.avatarBox} justifyContent="center" display="flex">
      {props.src === null && props.fallbackMode !== 'initials' ? (
        <BoringAvatar
          size={250}
          name={props.name || 'default'}
          title={props.alt || props.name}
          variant="beam"
          colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
        />
      ) : props.src === null && props.fallbackMode === 'initials' ? (
        <Box className={classes.font}>{getInitials()}</Box>
      ) : (
        <img className={classes.avatarImg} src={props.src} alt={props.alt} />
      )}
    </Box>
  );
}

Avatar.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['small', 'medium', 'large'])
  ])
};

Avatar.defaultProps = {
  alt: '',
  src: null,
  size: 'medium'
};
