import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { fetchDomain, fetchDomains } from './domain.slice';
import { Group, Pagination } from '../interfaces';
import { RootState } from '.';
import { paginate, safeArray } from '../utils';
import { groupsEntity } from '../schemas';
import { client } from '../api/client';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const createGroup = createAsyncThunk('groups/create', async group => {
  const response = await client.post('/groups', {
    group
  });
  const normalized = normalize(response.data.data, groupsEntity);

  return normalized.entities;
});

export const fetchGroup = createAsyncThunk(
  'groups/single',
  async (id: string) => {
    const response = await client.get(`/groups/${id}`);
    const normalized = normalize(response.data.data, groupsEntity);

    return normalized.entities;
  }
);

export const fetchGroupWithUnits = createAsyncThunk(
  'groups/single',
  async (id: string) => {
    const response = await client.get(`/groups/${id}?preload_units=true`);
    const normalized = normalize(response.data.data, groupsEntity);

    return normalized.entities;
  }
);

export const fetchPortalGroup = createAsyncThunk(
  'groups/single',
  async (id: string) => {
    const response = await client.get(`/portal_groups/${id}`);
    const normalized = normalize(response.data.data, groupsEntity);

    return normalized.entities;
  }
);

export const fetchPortalGroupWithUnits = createAsyncThunk(
  'groups/single',
  async (id: string) => {
    const response = await client.get(
      `/portal_groups/${id}?preload_units=true`
    );
    const normalized = normalize(response.data.data, groupsEntity);

    return normalized.entities;
  }
);

export const fetchGroups = createAsyncThunk(
  'groups/all',
  async (filter: Pagination) => {
    const response = await client.get(`/groups`, filter);
    const normalized = normalize(response.data.data, [groupsEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

// TODO: Add pagination parameter.
export const fetchGroupsByBoundary = createAsyncThunk(
  'groups/all',
  async (boundary: String) => {
    const response = await client.get(`/groups?boundary=${boundary}`, {
      page: 0,
      page_size: 10000
    });
    const normalized = normalize(response.data.data, [groupsEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const fetchPortalGroups = createAsyncThunk(
  'groups/all',
  async (filter: Pagination) => {
    const response = await client.get(`/groups?portal_visibility=true`, filter);
    const normalized = normalize(response.data.data, [groupsEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const updateGroup = createAsyncThunk(
  'groups/update',
  async ({ id, group }: { id: string; group: Group }) => {
    const response = await client.put(`/groups/${id}`, {
      group
    });
    const normalized = normalize(response.data.data, groupsEntity);

    return normalized.entities;
  }
);

export const archiveGroup = createAsyncThunk(
  'groups/archive',
  async (id: number) => {
    await client.put(`/archive/groups/${id}`);
    return id;
  }
);

export const unarchiveGroup = createAsyncThunk(
  'groups/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/groups/${id}`);
    return id;
  }
);

export const deleteGroup = createAsyncThunk(
  'groups/delete',
  async (id: number) => {
    await client.delete(`/groups/${id}`);
    return id;
  }
);

export const fetchGroupHasDates = createAsyncThunk(
  'groups/has_dates',
  async (id: string) => {
    const response = await client.get(`/groups/has_dates/${id}`);
    return response.data;
  }
);

const groupAdapter = createEntityAdapter<Group>();
const initialState = groupAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const groupSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        groupAdapter.upsertMany(state, safeArray(payload.groups.entities));
        state.pagination = payload.groups.pagination;
      });
    });
    builder.addCase(fetchGroups.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchGroups.fulfilled, (state, { payload }) => {
      groupAdapter.upsertMany(state, safeArray(payload.groups));
      state.isLoading = false;
      state.pagination = payload.pagination;
    });
    builder.addCase(fetchGroups.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchGroup.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchGroup.fulfilled, (state, { payload }) => {
      groupAdapter.upsertMany(state, safeArray(payload.groups));
      state.isLoading = false;
    });
    builder.addCase(fetchGroup.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createGroup.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createGroup.fulfilled, (state, { payload }) => {
      groupAdapter.upsertMany(state, safeArray(payload.groups));
      state.isLoading = false;
    });
    builder.addCase(createGroup.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateGroup.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateGroup.fulfilled, (state, { payload }) => {
      groupAdapter.upsertMany(state, safeArray(payload.groups));
      state.isLoading = false;
    });
    builder.addCase(updateGroup.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteGroup.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteGroup.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteGroup.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchDomain.fulfilled, (state, { payload }) => {
      groupAdapter.upsertMany(state, safeArray(payload.groups));
    });
    builder.addCase(fetchDomains.fulfilled, (state, { payload }) => {
      groupAdapter.upsertMany(state, safeArray(payload.groups));
    });
  }
});

export const groupSelectors = groupAdapter.getSelectors<RootState>(
  state => state.groups
);

export default groupSlice.reducer;
