import isDate from './isDate';
import timeConvertToString from '../time/convertToString';

/**
 * Converts a date local to a string
 */
export default function convertToLocalString(date) {
  if (!isDate(date)) return '';

  return `${date.getFullYear()}-${
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}T${
    timeConvertToString(date, 'de').pretty
  }:00`;
}
