import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import Icon from './Icon';

const useStyles = makeStyles(theme => ({
  footer: {
    width: '100%',
    boxSizing: 'border-box',
    background: props =>
      props.color === 'primary'
        ? theme.color.common.grey.dark
        : theme.color.background.grey,
    color: props =>
      props.color === 'primary'
        ? theme.color.primary.contrastText
        : theme.color.text.main,
    padding: '1em 24px',
    paddingBottom: props => (props.color === 'primary' ? '70px' : 0),
    '& a': {
      color: props =>
        props.color === 'primary'
          ? theme.color.primary.contrastText
          : theme.color.text.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    [theme.mediaQueries.small]: {
      padding: '.5em 0'
    }
  },
  backToTop: {
    display: 'flex',
    alignItems: 'center',
    float: 'right',
    paddingRight: '.75rem',
    '& a': {
      marginRight: '.5rem'
    }
  },
  logo: {
    maxWidth: '120px',
    '& img': {
      maxWidth: '100%'
    },
    [theme.mediaQueries.small]: {
      padding: '1rem 0 1.5rem .7rem'
    }
  },
  desktopSections: {
    display: 'inline-flex',
    paddingLeft: '5%',
    margin: 0,
    marginBottom: 25,
    width: '100%',
    boxSizing: 'border-box',
    listStyle: 'none',
    '& li': {
      lineHeight: '1.5em',
      marginBottom: '.5em',
      '& li:hover': {
        textDecoration: 'underline'
      }
    },
    [theme.mediaQueries.small]: {
      display: 'none'
    }
  },
  mobileSections: {
    display: 'none',
    margin: '0 0 1.5625rem 0',
    padding: 0,
    cursor: 'pointer',
    borderBottom: props =>
      `1px solid ${
        props.color === 'primary'
          ? theme.color.common.grey.main
          : theme.color.common.grey.light
      }`,
    [theme.mediaQueries.small]: {
      display: 'block'
    }
  },
  mobileSectionItem: {
    padding: '1rem .75rem',
    margin: 0,
    borderTop: props =>
      `1px solid ${
        props.color === 'primary'
          ? theme.color.common.grey.main
          : theme.color.common.grey.light
      }`,
    '& h3': {
      width: '100%',
      margin: 0,
      fontSize: '1.0625rem',
      lineHeight: '1.353em'
    },
    '& svg': {
      width: '2rem',
      height: '2rem'
    },
    '&:last-of-type': {
      boxShadow: props =>
        props.color === 'primary' ? null : '0 4px 4px rgb(144 144 144 / 50%)'
    }
  },
  mobileSectionActive: {
    boxShadow: props =>
      props.color === 'primary' ? null : '0 4px 4px rgb(144 144 144 / 50%)'
  },
  mobileSection: {
    '& li': {
      background: props =>
        props.color === 'primary' ? theme.color.primary.dark : 'transparent'
    },
    '& li:hover': {
      background: props =>
        props.color === 'primary' ? theme.color.primary.dark : 'transparent',
      textDecoration: 'underline'
    }
  },
  desktopSection: {
    width: '15%',
    marginLeft: '3%',
    maxWidth: '210px',
    '& h3': {
      fontSize: '1.0625rem',
      marginTop: '1em',
      marginBottom: '.4em',
      fontWeight: 700
    },
    '& ul': {
      display: 'block',
      listStyle: 'none',
      padding: 0,
      margin: 0
    }
  },
  claim: {
    paddingLeft: '8%',
    lineHeight: 1.5,
    marginTop: '20px',
    '& p': {
      marginTop: 0
    },
    '& a': {
      textDecoration: 'underline'
    },
    [theme.mediaQueries.small]: {
      paddingLeft: '.7rem'
    }
  }
}));

export default function Footer(props) {
  const classes = useStyles(props);
  const [activeItem, setActiveItem] = useState(
    props.color === 'secondary' ? 0 : null
  );
  const FooterTag = props.color === 'primary' ? 'footer' : 'div';

  return (
    <FooterTag className={classes.footer}>
      {props.color === 'secondary' && (
        <div className={classes.backToTop}>
          <a href="#">{props.intlBackToTop}</a>
          <Icon
            lib="fa"
            color="white"
            square="secondary"
            name="arrow-up-long"
          />
        </div>
      )}
      <div className={classes.logo}>{props.logo}</div>
      <div>
        <ul className={classes.desktopSections}>
          {props.sections.map((section, i) => (
            <li
              className={classes.desktopSection}
              key={`${section.title}-${i}`}
            >
              <h3>{section.title}</h3>
              <ul>
                {section.entries.map((entry, i) => (
                  <li key={`${entry.title}-${i}`}>
                    {props.link(entry.title, entry.href)}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        <ul className={classes.mobileSections}>
          {props.sections.map((section, i) => {
            const id = `${section.title}-${i}`;

            return (
              <React.Fragment key={id}>
                <ListItemButton
                  onClick={() => setActiveItem(i)}
                  component="li"
                  className={`${classes.mobileSectionItem} ${
                    activeItem === true || i === activeItem
                      ? classes.mobileSectionActive
                      : null
                  }`}
                  // Disable interaction since berlin default mode is always open
                  // onClick={() => {
                  //   if (id === activeItem) setActiveItem();
                  //   if (id !== activeItem) setActiveItem(id);
                  // }}
                >
                  <h3>{section.title}</h3>
                  {activeItem === true || i === activeItem ? (
                    <Icon
                      size="large"
                      color={
                        props.color === 'primary' ? 'contrastPrimary' : 'text'
                      }
                      name="arrow-up"
                    />
                  ) : (
                    <Icon
                      size="large"
                      color={
                        props.color === 'primary' ? 'contrastPrimary' : 'text'
                      }
                      name="arrow-down"
                    />
                  )}
                </ListItemButton>
                <Collapse
                  in={activeItem === true || i === activeItem}
                  timeout="auto"
                  unmountOnExit
                >
                  <List
                    component="ul"
                    disablePadding
                    className={classes.mobileSection}
                  >
                    {section.entries.map((entry, i) => (
                      <ListItemButton
                        component="li"
                        key={`${entry.title}-${i}`}
                        sx={{ pl: 4 }}
                      >
                        <ListItemText
                          primary={props.link(entry.title, entry.href)}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          })}
        </ul>
      </div>
      {props.claim && (
        <div className={classes.claim}>
          <p>{props.claim}</p>
        </div>
      )}
    </FooterTag>
  );
}

Footer.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  logo: PropTypes.node,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      entries: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  link: PropTypes.func,
  intlBackToTop: PropTypes.string,
  claim: PropTypes.node
};

Footer.defaultProps = {
  link: (title, href) => <a href={href}>{title}</a>,
  intlBackToTop: 'Zum Seitenanfang'
};
