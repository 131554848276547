import { AnyAction } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { normalize } from 'normalizr';

import { RootState } from '.';
import { client } from '../api/client';
import { paginate, safeArray } from '../utils';

import { ConsumerTeam } from '../interfaces';
import { consumerTeamsEntity } from '../schemas';
import { fetchBooking, fetchBookings } from './booking.slice';
import { fetchRequest, fetchRequests } from './request.slice';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const fetchConsumerTeam = createAsyncThunk(
  'consumerTeams/byId',
  async consumerTeamId => {
    const response = await client.get(`/consumer_teams/${consumerTeamId}`);
    const normalized = normalize(response.data.data, consumerTeamsEntity);

    return normalized.entities;
  }
);

export const fetchConsumerTeams = createAsyncThunk(
  'consumerTeams/all',
  async filter => {
    const response = await client.get('/consumer_teams', filter);
    const normalized = normalize(response.data.data, [consumerTeamsEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const createConsumerTeam = createAsyncThunk(
  'consumerTeams/create',
  async team => {
    const response = await client.post('/consumer_teams', {
      consumer_team: team
    });
    const normalized = normalize(response.data.data, consumerTeamsEntity);

    return normalized.entities;
  }
);

export const updateConsumerTeam = createAsyncThunk(
  'consumerTeams/update',
  async ({ id, consumerTeam }: { id: string; consumerTeam: ConsumerTeam }) => {
    const response = await client.put(`/consumer_teams/${id}`, {
      consumer_team: consumerTeam
    });
    const normalized = normalize(response.data.data, consumerTeamsEntity);

    return normalized.entities;
  }
);

export const archiveConsumerTeam = createAsyncThunk(
  'consumerTeams/archive',
  async (id: number) => {
    await client.put(`/archive/consumer_teams/${id}`);
    return id;
  }
);

export const unarchiveConsumerTeam = createAsyncThunk(
  'consumerTeams/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/consumer_teams/${id}`);
    return id;
  }
);

export const deleteConsumerTeam = createAsyncThunk(
  'consumer_teams/delete',
  async (id: number) => {
    await client.delete(`/consumer_teams/${id}`);
    return id;
  }
);

const consumerTeamAdapter = createEntityAdapter<ConsumerTeam>();
const initialState = consumerTeamAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const consumerTeamSlice = createSlice({
  name: 'consumerTeams',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        consumerTeamAdapter.upsertMany(
          state,
          safeArray(payload.consumerTeams.entities)
        );
      });
    });
    builder.addCase(fetchConsumerTeams.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchConsumerTeams.fulfilled, (state, { payload }) => {
      consumerTeamAdapter.upsertMany(state, safeArray(payload.consumerTeams));
      state.isLoading = false;
      state.pagination = payload.pagination;
    });
    builder.addCase(fetchConsumerTeams.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchConsumerTeam.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchConsumerTeam.fulfilled, (state, { payload }) => {
      consumerTeamAdapter.upsertMany(state, safeArray(payload.consumerTeams));
      state.isLoading = false;
    });
    builder.addCase(fetchConsumerTeam.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createConsumerTeam.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createConsumerTeam.fulfilled, (state, { payload }) => {
      consumerTeamAdapter.upsertMany(state, safeArray(payload.consumerTeams));
      state.isLoading = false;
    });
    builder.addCase(createConsumerTeam.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateConsumerTeam.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateConsumerTeam.fulfilled, (state, { payload }) => {
      consumerTeamAdapter.upsertMany(state, safeArray(payload.consumerTeams));
      state.isLoading = false;
    });
    builder.addCase(updateConsumerTeam.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteConsumerTeam.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteConsumerTeam.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteConsumerTeam.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      consumerTeamAdapter.upsertMany(state, safeArray(payload.consumerTeams));
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      consumerTeamAdapter.upsertMany(state, safeArray(payload.consumerTeams));
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      consumerTeamAdapter.upsertMany(state, safeArray(payload.consumerTeams));
    });
    builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
      consumerTeamAdapter.upsertMany(state, safeArray(payload.consumerTeams));
    });
  }
});

export const consumerTeamSelectors =
  consumerTeamAdapter.getSelectors<RootState>(state => state.consumerTeams);

export default consumerTeamSlice.reducer;
