import PropTypes from 'prop-types';

export default function FormatNumber(props) {
  // TBD: Intl
  return props.children?.toLocaleString('de-DE');
}

FormatNumber.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

FormatNumber.defaultProps = {
  children: undefined
};
