import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { client } from '../api/client';
import { holidaysEntity } from '../schemas';
import { Holiday, Pagination } from '../interfaces';
import { fetchProviders } from './provider.slice';
import { RootState } from '.';
import { fetchBooking, fetchBookings } from './booking.slice';
import { fetchRequest, fetchRequests } from './request.slice';
import { paginate, safeArray } from '../utils';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const fetchHoliday = createAsyncThunk(
  'holidays/byId',
  async holidayId => {
    const response = await client.get(`/holidays/${holidayId}`);
    const normalized = normalize(response.data.data, holidaysEntity);

    return normalized.entities;
  }
);

export const fetchHolidays = createAsyncThunk(
  'holidays/all',
  async (filter: Pagination) => {
    const response = await client.get('/holidays', filter);

    const normalized = normalize(response.data.data, [holidaysEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const createHoliday = createAsyncThunk(
  'providerHolidays/create',
  async holiday => {
    const response = await client.post('/holidays', { holiday });
    const normalized = normalize(response.data.data, holidaysEntity);

    return normalized.entities;
  }
);

export const updateHoliday = createAsyncThunk(
  'holidays/update',
  async ({ id, holiday }: { id: string; holiday: Holiday }) => {
    const response = await client.put(`/holidays/${id}`, {
      holiday
    });
    const normalized = normalize(response.data.data, holidaysEntity);

    return normalized.entities;
  }
);

export const archiveHoliday = createAsyncThunk(
  'holidays/archive',
  async (id: number) => {
    await client.put(`/archive/holidays/${id}`);
    return id;
  }
);

export const unarchiveHoliday = createAsyncThunk(
  'holidays/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/holidays/${id}`);
    return id;
  }
);

export const deleteHoliday = createAsyncThunk(
  'holidays/delete',
  async (id: number) => {
    await client.delete(`/holidays/${id}`);
    return id;
  }
);

const holidayAdapter = createEntityAdapter<Holiday>();
const initialState = holidayAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const holidaySlice = createSlice({
  name: 'holidays',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        holidayAdapter.upsertMany(state, safeArray(payload.holidays.entities));
        state.isLoading = false;
        state.pagination = payload.holidays.pagination;
      });
    });
    builder.addCase(fetchHoliday.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchHoliday.fulfilled, (state, { payload }) => {
      holidayAdapter.upsertMany(state, safeArray(payload.holidays));
      state.isLoading = false;
    });
    builder.addCase(fetchHoliday.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchHolidays.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchHolidays.fulfilled, (state, { payload }) => {
      holidayAdapter.upsertMany(state, safeArray(payload.holidays));
      state.isLoading = false;
      state.pagination = payload.pagination;
    });
    builder.addCase(fetchHolidays.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createHoliday.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createHoliday.fulfilled, (state, { payload }) => {
      holidayAdapter.upsertMany(state, safeArray(payload.holidays));
      state.isLoading = false;
    });
    builder.addCase(createHoliday.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateHoliday.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateHoliday.fulfilled, (state, { payload }) => {
      holidayAdapter.upsertMany(state, safeArray(payload.holidays));
      state.isLoading = false;
    });
    builder.addCase(updateHoliday.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(archiveHoliday.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(archiveHoliday.fulfilled, (state, { payload }) => {
      holidayAdapter.removeOne(state, payload.toString());
      state.isLoading = false;
    });
    builder.addCase(archiveHoliday.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteHoliday.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteHoliday.fulfilled, (state, { payload }) => {
      holidayAdapter.removeOne(state, payload.toString());
      state.isLoading = false;
    });
    builder.addCase(deleteHoliday.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchProviders.fulfilled, (state, { payload }) => {
      holidayAdapter.upsertMany(state, safeArray(payload.holidays));
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      holidayAdapter.upsertMany(state, safeArray(payload.holidays));
    });
    builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
      holidayAdapter.upsertMany(state, safeArray(payload.holidays));
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      holidayAdapter.upsertMany(state, safeArray(payload.holidays));
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      holidayAdapter.upsertMany(state, safeArray(payload.holidays));
    });
  }
});

export const holidaySelectors = holidayAdapter.getSelectors<RootState>(
  state => state.holidays
);

export default holidaySlice.reducer;
