/**
 * Gets first day of the week of a given date
 */
export default function getQueryString(dt) {
  if (dt) {
    const year = dt.getFullYear();
    const month = `0${dt.getMonth() + 1}`.slice(-2);
    const day = `0${dt.getDate()}`.slice(-2);

    return `${year}-${month}-${day}`;
  }
}
