import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from '../utils/react/classNames';

const useStyles = makeStyles(theme => ({
  OuterWrapper: {
    display: props => (props.loading ? 'flex' : 'none'),
    background: theme.color.background.default,
    borderRadius: '50%',
    justifyContent: 'center',
    padding: '1em',
    '& div': {
      margin: '0 auto'
    }
  }
}));

export default function LoadingIndicator(props) {
  const classes = useStyles(props);

  return (
    <div
      data-testid="loading-indicator"
      className={classNames([classes.OuterWrapper, props.className])}
    >
      <CircularProgress aria-label={props['aria-label']} />
    </div>
  );
}

LoadingIndicator.propTypes = {
  ['aria-label']: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool
};

LoadingIndicator.defaultProps = {
  ['aria-label']: undefined,
  className: undefined,
  loading: false
};
