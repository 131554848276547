/**
 * Gets an array of weekday names
 */
export default function getWeekdaysForLocale(locale) {
  const format = new Intl.DateTimeFormat(locale, { weekday: 'long' });
  const weekdays = [];

  for (let day = 0; day < 7; day++) {
    // 06.01.2020 was a monday
    const iterableDate = new Date(Date.UTC(2020, 0, 6 + day, 0, 0, 0));
    weekdays.push(format.format(iterableDate));
  }

  return weekdays;
}
