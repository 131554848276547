import subtractDays from './subtractDays';

/**
 * Gets first day of the week of a given date
 */
export default function getStartOfWeek(date) {
  const dt = new Date(date);
  const diff = dt.getDate() - dt.getDay() + (dt.getDay() === 0 ? -6 : 1);

  return new Date(dt.setDate(diff));
}
