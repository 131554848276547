/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';

export const holidaysEntity = new schema.Entity(
  'holidays',
  {},
  {
    processStrategy: holiday => ({
      ...holiday,
      start: new Date(holiday.start),
      end: new Date(holiday.end)
    })
  }
);
