import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { client } from '../api/client';
import { bookingsEntity } from '../schemas';
import { Booking, Pagination, Request } from '../interfaces';
import { RootState } from '.';
import { paginate, safeArray } from '../utils';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const fetchPublicBooking = createAsyncThunk(
  'bookings/single',
  async (id: string) => {
    const response = await client.get(`/public/bookings/${id}`);
    const normalized = normalize(response.data, bookingsEntity);

    return normalized.entities;
  }
);

export const fetchBooking = createAsyncThunk(
  'bookings/single',
  async (id: string) => {
    const response = await client.get(`/bookings/${id}`);
    const normalized = normalize(response.data.data, bookingsEntity);

    return normalized.entities;
  }
);

export const fetchBookings = createAsyncThunk(
  'bookings/all',
  async (filter: Pagination) => {
    const response = await client.get('/bookings', filter);
    const normalized = normalize(response.data.data, [bookingsEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const createBookingByBookingRequest = createAsyncThunk(
  'bookings/createBookingByBookingRequest',
  async (request: Request) => {
    const response = await client.post('/bookings', {
      booking_request: request
    });
    const normalized = normalize(response.data.data, bookingsEntity);

    return normalized.entities;
  }
);

export const createBooking = createAsyncThunk(
  'bookings/create',
  async booking => {
    const response = await client.post('/bookings', {
      booking
    });
    const normalized = normalize(response.data.data, bookingsEntity);

    return normalized.entities;
  }
);

export const updateBooking = createAsyncThunk(
  'bookings/update',
  async ({ id, booking }: { id: string; booking: Partial<Booking> }) => {
    const response = await client.put(`/bookings/${id}`, { booking });
    const normalized = normalize(response.data.data, bookingsEntity);

    return normalized.entities;
  }
);

export const archiveBooking = createAsyncThunk(
  'bookings/archive',
  async (id: number) => {
    await client.put(`/archive/bookings/${id}`);
    return id;
  }
);

export const unarchiveBooking = createAsyncThunk(
  'bookings/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/bookings/${id}`);
    return id;
  }
);

export const deleteBooking = createAsyncThunk(
  'bookings/delete',
  async (id: number) => {
    await client.delete(`/bookings/${id}`);
    return id;
  }
);

const bookingAdapter = createEntityAdapter<Booking>();
const initialState = bookingAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const bookingSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        bookingAdapter.upsertMany(state, safeArray(payload.bookings.entities));
        state.isLoading = false;
        state.pagination = payload.bookings.pagination;
      });
    });
    builder.addCase(fetchBookings.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      bookingAdapter.upsertMany(state, safeArray(payload.bookings));
      state.isLoading = false;
      state.pagination = payload.pagination;
    });
    builder.addCase(fetchBookings.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchBooking.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      bookingAdapter.upsertMany(state, safeArray(payload.bookings));
      state.isLoading = false;
    });
    builder.addCase(fetchBooking.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createBooking.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createBooking.fulfilled, (state, { payload }) => {
      bookingAdapter.upsertMany(state, safeArray(payload.bookings));
      state.isLoading = false;
    });
    builder.addCase(createBooking.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createBookingByBookingRequest.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(
      createBookingByBookingRequest.fulfilled,
      (state, { payload }) => {
        bookingAdapter.upsertMany(state, safeArray(payload.bookings));
        state.isLoading = false;
      }
    );
    builder.addCase(createBookingByBookingRequest.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateBooking.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateBooking.fulfilled, (state, { payload }) => {
      bookingAdapter.upsertMany(state, safeArray(payload.bookings));
      state.isLoading = false;
    });
    builder.addCase(updateBooking.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteBooking.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteBooking.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteBooking.rejected, state => {
      state.isLoading = false;
    });
  }
});

export const bookingSelectors = bookingAdapter.getSelectors<RootState>(
  state => state.bookings
);

export default bookingSlice.reducer;
