import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import React from 'react';

export default function FormInlineGroup(props) {
  return (
    <Box display="flex" alignItems="flex-start" flexWrap="wrap" gap={1}>
      {props.children.map((child, index) => {
        return (
          <React.Fragment key={`${index}-${child?.props?.id}`}>
            {child}
          </React.Fragment>
        );
      })}
    </Box>
  );
}

FormInlineGroup.propTypes = {
  children: PropTypes.node.isRequired
};

FormInlineGroup.defaultProps = {};
