import isDate from '../date/isDate';

export default function convertToString(dt, lang = 'de-DE') {
  if (!isDate(dt)) return { pretty: '', str: '' };

  const hours = dt.getHours() <= 9 ? `0${dt.getHours()}` : dt.getHours();
  const minutes =
    dt.getMinutes() <= 9 ? `0${dt.getMinutes()}` : dt.getMinutes();

  return {
    pretty: new Intl.DateTimeFormat(lang, {
      hour: 'numeric',
      minute: 'numeric'
    }).format(dt),
    str: `${hours}${minutes}`
  };
}
