import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  colorLine: props => ({
    height: '3px',
    width: '100%',
    background: props.color
  })
}));

export default function ColorLine(props) {
  const classes = useStyles(props);

  return <div className={classes.colorLine} />;
}

ColorLine.propTypes = {
  color: PropTypes.string.isRequired
};

ColorLine.defaultProps = {};
