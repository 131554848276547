/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';

export const seasonsEntity = new schema.Entity(
  'seasons',
  {},
  {
    processStrategy: season => ({
      ...season,
      start: new Date(season.start),
      end: new Date(season.end)
    })
  }
);
