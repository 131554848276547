/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';

export const publicHolidaysEntity = new schema.Entity(
  'publicHolidays',
  {},
  {
    processStrategy: publicHoliday => ({
      ...publicHoliday,
      date: new Date(publicHoliday.date)
    })
  }
);
