import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';

import FaArrowRightIcon from '../../public/fa-arrow-right.svg';
import FaArrowUpLongIcon from '../../public/fa-arrow-up-long.svg';
import FaArrowUpIcon from '../../public/fa-arrow-up.svg';
import FaArrowDownIcon from '../../public/fa-arrow-down.svg';
import FaCalendarIcon from '../../public/fa-calendar.svg';
import FaCircleDotIcon from '../../public/fa-circle-dot.svg';
import FaCircleSolidIcon from '../../public/fa-circle-solid.svg';
import FaCheckCircleIcon from '../../public/fa-check-circle.svg';
import FaCopyIcon from '../../public/fa-copy.svg';
import FaCloseIcon from '../../public/fa-close.svg';
import FaCloseCircleIcon from '../../public/fa-close-circle.svg';
import FaDocument from '../../public/fa-document.svg';
import FaDownload from '../../public/fa-download.svg';
import FaExternalLink from '../../public/fa-external-link.svg';
import FaInfoCircleIcon from '../../public/fa-info-circle.svg';
import FaInfoIcon from '../../public/fa-info.svg';
import FaLocationMarkerIcon from '../../public/fa-location-marker.svg';
import FaMapMarked from '../../public/fa-map-marked.svg';
import FaMail from '../../public/fa-mail.svg';
import FaMenuIcon from '../../public/fa-menu.svg';
import FaPenIcon from '../../public/fa-pen.svg';
import FaRotateLeft from '../../public/fa-rotate-left.svg';
import FaSlidersIcon from '../../public/fa-sliders.svg';
import FaTableIcon from '../../public/fa-table.svg';
import FaDeleteIcon from '../../public/fa-trash.svg';
import FaUniversalAccess from '../../public/fa-universal-access.svg';

import AccountIcon from '../../public/account.svg';
import AddIcon from '../../public/add.svg';
import AdminPanelIcon from '../../public/admin-panel.svg';
import ArrowDownIcon from '../../public/arrow-down.svg';
import ArrowDropDownSharp from '../../public/arrow-drop-down-sharp.svg';
import ArrowDropUpSharp from '../../public/arrow-drop-up-sharp.svg';
import ArrowLeftIcon from '../../public/arrow-left.svg';
import ArrowRightIcon from '../../public/arrow-right.svg';
import ArrowUpIcon from '../../public/arrow-up.svg';
import BlockIcon from '../../public/block.svg';
import BulletIcon from '../../public/bullet.svg';
import CalendarIcon from '../../public/calendar.svg';
import ChatIcon from '../../public/chat.svg';
import ChartIcon from '../../public/chart.svg';
import CheckCircleIcon from '../../public/check-circle.svg';
import CheckIcon from '../../public/check.svg';
import CoveredIcon from '../../public/covered.svg';
import CircleIcon from '../../public/circle.svg';
import ClockIcon from '../../public/clock.svg';
import CloseCircleIcon from '../../public/close-circle.svg';
import CloseIcon from '../../public/close.svg';
import DeleteIcon from '../../public/delete.svg';
import DescriptionIcon from '../../public/description.svg';
import DocumentIcon from '../../public/document.svg';
import DotIcon from '../../public/dot.svg';
import EmptyIcon from '../../public/empty.svg';
import EventRepeatIcon from '../../public/event-repeat.svg';
import EyeIcon from '../../public/eye.svg';
import EyeStrikethroughIcon from '../../public/eye-strikethrough.svg';
import FilterIcon from '../../public/filter.svg';
import GroupAddIcon from '../../public/group-add.svg';
import HelpIcon from '../../public/help.svg';
import Hourglass from '../../public/hourglass.svg';
import InfoCircleIcon from '../../public/info-circle.svg';
import InventoryIcon from '../../public/inventory.svg';
import ListIcon from '../../public/list.svg';
import LocationCityIcon from '../../public/location-city.svg';
import LocationMarkerIcon from '../../public/location-marker.svg';
import LockIcon from '../../public/lock.svg';
import LogoutIcon from '../../public/logout.svg';
import MailIcon from '../../public/mail.svg';
import MapIcon from '../../public/map.svg';
import MenuIcon from '../../public/menu.svg';
import MoreIcon from '../../public/more.svg';
import OpenIcon from '../../public/open.svg';
import PenIcon from '../../public/pen.svg';
import PrintIcon from '../../public/print.svg';
import RepeatIcon from '../../public/repeat.svg';
import ReportIcon from '../../public/report.svg';
import SaveIcon from '../../public/save.svg';
import SearchIcon from '../../public/search.svg';
import SendIcon from '../../public/send.svg';
import SettingIcon from '../../public/setting.svg';
import SortIcon from '../../public/sort.svg';
import SunnyIcon from '../../public/sunny.svg';
import SupervisorAccountIcon from '../../public/supervisor-account.svg';
import TextboxPlusIcon from '../../public/textbox-plus.svg';
import ThumbUpDownIcon from '../../public/thumb-up-down.svg';
import TodayIcon from '../../public/today.svg';
import UndoIcon from '../../public/undo.svg';
import UnlockIcon from '../../public/unlock.svg';
import UncoveredIcon from '../../public/uncovered.svg';
import VisibilityIcon from '../../public/visibility.svg';
import VisibilityOffIcon from '../../public/visibility-off.svg';
import WarningIcon from '../../public/warning.svg';

const useStyles = makeStyles(theme => {
  function getIconSize(size) {
    switch (size) {
      case 'small':
        return '0.875em';
      case 'medium':
      default:
        return '1em';
      case 'large':
        return '1.5em';
      case 'fullsize':
        return '100%';
    }
  }

  function getColor(color, disabled) {
    switch (color) {
      case 'default':
      default:
        return disabled
          ? theme.color.common.grey.light
          : theme.color.common.grey.main;
      case 'light':
        return disabled
          ? theme.color.common.grey.light
          : theme.color.text.light;
      case 'text':
        return disabled ? theme.color.common.grey.main : theme.color.text.main;
      case 'link':
        return disabled ? theme.color.common.grey.main : theme.color.text.link;
      case 'primary':
        return disabled ? theme.color.primary.light : theme.color.primary.main;
      case 'secondary':
        return disabled
          ? theme.color.secondary.light
          : theme.color.secondary.main;
      case 'contrastPrimary':
        return theme.color.primary.contrastText;
      case 'contrastSecondary':
        return theme.color.secondary.contrastText;
      case 'white':
        return theme.color.common.white;
      case 'green':
        return theme.color.success.main;
      case 'red':
        return theme.color.error.main;
      case 'error':
        return disabled ? theme.color.error.light : theme.color.error.main;
      case 'warning':
        return disabled ? theme.color.warning.light : theme.color.warning.main;
    }
  }

  return {
    icon: {
      border: props =>
        props.circle
          ? `1px solid ${getColor(props.color, props.disabled)}`
          : null,
      borderRadius: props => (props.circle ? '50%' : 0),
      display: 'flex',
      background: props => {
        if (props.circle) {
          return theme.color.background.default;
        }

        return props.square ? getColor(props.square, props.disabled) : null;
      },
      flexDirection: 'column',
      justifyContent: 'center',
      padding: props => (props.circle || props.square ? 1 : 0),
      height: props => getIconSize(props.size),
      width: props => getIconSize(props.size),
      fill: props => getColor(props.color, props.disabled)
    }
  };
});

export default function Icon(props) {
  const classes = useStyles(props);

  const getIcon = (lib, name) => {
    switch (lib) {
      case 'fa':
        switch (name) {
          case 'arrow-down':
            return FaArrowDownIcon;
          case 'arrow-up':
            return FaArrowUpIcon;
          case 'arrow-up-long':
            return FaArrowUpLongIcon;
          case 'arrow-right':
            return FaArrowRightIcon;
          case 'calendar':
            return FaCalendarIcon;
          case 'circle-dot':
            return FaCircleDotIcon;
          case 'copy':
            return FaCopyIcon;
          case 'circle-solid':
            return FaCircleSolidIcon;
          case 'check-circle':
            return FaCheckCircleIcon;
          case 'close':
            return FaCloseIcon;
          case 'close-circle':
            return FaCloseCircleIcon;
          case 'download':
            return FaDownload;
          case 'document':
            return FaDocument;
          case 'external-link':
            return FaExternalLink;
          case 'info-circle':
            return FaInfoCircleIcon;
          case 'info':
            return FaInfoIcon;
          case 'location-marker':
            return FaLocationMarkerIcon;
          case 'map-marked':
            return FaMapMarked;
          case 'mail':
            return FaMail;
          case 'menu':
            return FaMenuIcon;
          case 'pen':
            return FaPenIcon;
          case 'rotate-left':
            return FaRotateLeft;
          case 'sliders':
            return FaSlidersIcon;
          case 'table':
            return FaTableIcon;
          case 'delete':
            return FaDeleteIcon;
          case 'universal-access':
            return FaUniversalAccess;
          default:
            console.warn(
              'Icon: ',
              `Can not display icon '${props.name}'.No matching icon found. `
            );
        }

      case 'mui':
        switch (name) {
          case 'account':
            return AccountIcon;
          case 'add':
            return AddIcon;
          case 'admin-panel':
            return AdminPanelIcon;
          case 'arrow-down':
            return ArrowDownIcon;
          case 'arrow-drop-down-sharp':
            return ArrowDropDownSharp;
          case 'arrow-drop-up-sharp':
            return ArrowDropUpSharp;
          case 'arrow-left':
            return ArrowLeftIcon;
          case 'arrow-right':
            return ArrowRightIcon;
          case 'arrow-up':
            return ArrowUpIcon;
          case 'block':
            return BlockIcon;
          case 'bullet':
            return BulletIcon;
          case 'calendar':
            return CalendarIcon;
          case 'chart':
            return ChartIcon;
          case 'chat':
            return ChatIcon;
          case 'check':
            return CheckIcon;
          case 'check-circle':
            return CheckCircleIcon;
          case 'circle':
            return CircleIcon;
          case 'close-circle':
            return CloseCircleIcon;
          case 'close':
            return CloseIcon;
          case 'clock':
            return ClockIcon;
          case 'covered':
            return CoveredIcon;
          case 'delete':
            return DeleteIcon;
          case 'description':
            return DescriptionIcon;
          case 'document':
            return DocumentIcon;
          case 'dot':
            return DotIcon;
          case 'empty':
            return EmptyIcon;
          case 'event-repeat':
            return EventRepeatIcon;
          case 'eye':
            return EyeIcon;
          case 'eye-strikethrough':
            return EyeStrikethroughIcon;
          case 'filter':
            return FilterIcon;
          case 'group-add':
            return GroupAddIcon;
          case 'help':
            return HelpIcon;
          case 'hourglass':
            return Hourglass;
          case 'info-circle':
            return InfoCircleIcon;
          case 'inventory':
            return InventoryIcon;
          case 'list':
            return ListIcon;
          case 'logout':
            return LogoutIcon;
          case 'location-city':
            return LocationCityIcon;
          case 'location-marker':
            return LocationMarkerIcon;
          case 'lock':
            return LockIcon;
          case 'mail':
            return MailIcon;
          case 'map':
            return MapIcon;
          case 'menu':
            return MenuIcon;
          case 'more':
            return MoreIcon;
          case 'open':
            return OpenIcon;
          case 'pen':
            return PenIcon;
          case 'print':
            return PrintIcon;
          case 'report':
            return ReportIcon;
          case 'repeat':
            return RepeatIcon;
          case 'save':
            return SaveIcon;
          case 'send':
            return SendIcon;
          case 'setting':
            return SettingIcon;
          case 'search':
            return SearchIcon;
          case 'sort':
            return SortIcon;
          case 'sunny':
            return SunnyIcon;
          case 'supervisor-account':
            return SupervisorAccountIcon;
          case 'textbox-plus':
            return TextboxPlusIcon;
          case 'thumb-up-down':
            return ThumbUpDownIcon;
          case 'today':
            return TodayIcon;
          case 'unlock':
            return UnlockIcon;
          case 'undo':
            return UndoIcon;
          case 'uncovered':
            return UncoveredIcon;
          case 'visibility':
            return VisibilityIcon;
          case 'visibility-off':
            return VisibilityOffIcon;
          case 'warning':
            return WarningIcon;
          default:
            console.warn(
              'Icon: ',
              `Can not display icon '${props.name}'.No matching icon found. `
            );
        }
    }
  };

  const wrapper = {};
  wrapper.icon = getIcon(props.lib, props.name);

  return wrapper.icon ? (
    <span title={props.title}>
      <wrapper.icon
        aria-label={
          props['aria-label'] === undefined ? props.name : props['aria-label']
        }
        className={classes.icon}
        aria-hidden={props['aria-hidden']}
        aria-describedby={props['aria-describedby']}
      />
    </span>
  ) : null;
}

Icon.propTypes = {
  name: PropTypes.oneOf([
    'account',
    'add',
    'admin-panel',
    'arrow-down',
    'arrow-drop-down-sharp',
    'arrow-drop-up-sharp',
    'arrow-left',
    'arrow-right',
    'arrow-up',
    'arrow-up-long',
    'arrow-down',
    'block',
    'bullet',
    'calendar',
    'chart',
    'chat',
    'check',
    'check-circle',
    'circle',
    'clock',
    'circle-dot',
    'circle-solid',
    'copy',
    'close',
    'close-circle',
    'covered',
    'delete',
    'description',
    'document',
    'dot',
    'download',
    'empty',
    'event-repeat',
    'external-link',
    'eye',
    'eye-strikethrough',
    'filter',
    'group-add',
    'help',
    'hourglass',
    'info-circle',
    'info',
    'inventory',
    'list',
    'location-city',
    'location-marker',
    'lock',
    'logout',
    'mail',
    'menu',
    'map',
    'map-marked',
    'more',
    'open',
    'pen',
    'print',
    'repeat',
    'report',
    'rotate-left',
    'save',
    'search',
    'send',
    'setting',
    'sort',
    'sunny',
    'supervisor-account',
    'sliders',
    'textbox-plus',
    'thumb-up-down',
    'today',
    'uncovered',
    'undo',
    'universal-access',
    'unlock',
    'visibility',
    'visibility-off',
    'warning'
  ]).isRequired,
  color: PropTypes.oneOf([
    'default',
    'light',
    'link',
    'text',
    'primary',
    'error',
    'warning',
    'secondary',
    'contrastPrimary',
    'contrastSecondary',
    'green',
    'red',
    'white'
  ]),
  ['aria-hidden']: PropTypes.bool,
  ['aria-label']: PropTypes.string,
  ['aria-describedby']: PropTypes.string,
  lib: PropTypes.oneOf(['mui', 'fa']),
  circle: PropTypes.bool,
  square: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullsize'])
};

Icon.defaultProps = {
  disabled: false,
  ['aria-hidden']: undefined,
  ['aria-label']: undefined,
  ['aria-describedby']: undefined,
  circle: false,
  square: undefined,
  lib: 'mui',
  color: 'default',
  size: 'medium'
};
