import { addDays, setTimeOfDate } from './index';

/**
 *  Gets the next given weekday and time by a date of a given date.
 *  Example:
 *   dtTarget: So, 20. Mai 15 Uhr
 *   dtWeekdayAndTime: Di, 10. Jan 19 Uhr
 *   => Di, 22. Mai 19 Uhr
 */
export default function setNextWeekdayOfDate(dtTarget, dtWeekdayAndTime) {
  let dt = new Date(dtTarget);
  // const targetDt = new Date(1995, 3, 15, 15, 0); Sa
  // const weekdayAndTimeDt = new Date(1995, 3, 12, 19, 0); So
  const targetWeekday = dtTarget.getDay(); // 0: Sunday, 1: Monday ... 6: Saturday
  const wantedWeekday = dtWeekdayAndTime.getDay();

  let dayDiff = 0;

  while ((targetWeekday + dayDiff) % 7 !== wantedWeekday) {
    dayDiff += 1;
  }

  dt = addDays(dt, dayDiff);

  return setTimeOfDate(dt, dtWeekdayAndTime);
}
