import React, { useEffect, useState } from 'react';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { getIn } from 'final-form';
import { useTheme } from '@mui/styles';

import FormDirectory from './FormDirectory';
import FormGroup from './FormGroup';
import FormInlineGroup from './FormInlineGroup';
import FormSpacing from './FormSpacing';

function WhenFieldChanges({ field, becomes, set, to, fullfills, execute }) {
  return (
    <Field name={set} subscription={{}} validateFields={[]}>
      {(
        // No subscription. We only use Field to get to the change function
        { input: { onChange } }
      ) => (
        <FormSpy subscription={{}}>
          {({ form }) => (
            <OnChange name={field}>
              {value => {
                if (fullfills !== undefined && fullfills(value)) {
                  execute ? execute(field) : onChange(to);
                } else if (fullfills === undefined) {
                  if (becomes === undefined) {
                    execute ? execute(field) : onChange(to);
                  } else {
                    if (value === becomes) {
                      execute ? execute(field) : onChange(to);
                    }
                  }
                }
              }}
            </OnChange>
          )}
        </FormSpy>
      )}
    </Field>
  );
}

// Based on https://github.com/final-form/final-form-focus
const noop = () => {};

function createDecorator(scrollOffset) {
  return form => {
    const focusOnFirstError = errors => {
      // But we use getRegisteredFields to get order of fields
      const fields = form.getRegisteredFields();

      for (let i = 0; i < fields.length; i++) {
        if (getIn(errors, fields[i]) !== undefined) {
          const errorField = document.getElementById(fields[i]);

          if (errorField) {
            const errorFieldRect = errorField?.getBoundingClientRect();

            const top =
              errorFieldRect.top -
              document.body?.getBoundingClientRect().top -
              2 * scrollOffset;

            const bottom =
              errorFieldRect.bottom -
              document.body?.getBoundingClientRect().top -
              (0.5 * window?.screen?.height - 2 * scrollOffset);

            const scrollTop =
              errorFieldRect.height + 2 * scrollOffset < window?.screen?.height
                ? top
                : bottom;

            window?.scrollTo({
              top: scrollTop,
              left: 0,
              behavior: 'smooth'
            });
          } else {
            console.warn(
              `formScrollToError: Field with id '${fields[i]}' could not be found`
            );
          }

          return;
        }
      }
    };

    const originalSubmit = form.submit;

    let state = {};
    const unsubscribe = form.subscribe(
      nextState => {
        state = nextState;
      },
      { errors: true, submitErrors: true }
    );

    // What to do after submit
    const afterSubmit = () => {
      const { errors, submitErrors } = state;

      if (errors && Object.keys(errors).length) {
        focusOnFirstError(errors);
      } else if (submitErrors && Object.keys(submitErrors).length) {
        focusOnFirstError(submitErrors);
      }
    };

    // Rewrite submit function
    form.submit = () => {
      const result = originalSubmit.call(form);
      if (result && typeof result.then === 'function') {
        // async
        result.then(afterSubmit, noop);
      } else {
        // sync
        afterSubmit();
      }
      return result;
    };

    return () => {
      unsubscribe();
      form.submit = originalSubmit;
    };
  };
}

const scrollDecorator = scrollOffset => createDecorator(scrollOffset);

function Form(props) {
  const theme = useTheme();
  const [decorators] = useState([
    ...(props.decorators || []),
    ...(props.disableScrollToError
      ? []
      : [
          scrollDecorator(
            theme.contentOffset ? parseInt(theme.contentOffset, 10) : 0
          )
        ])
  ]);

  return (
    <FinalForm
      {...props}
      subscription={{ submitting: true, pristine: true, submitError: true }}
      decorators={decorators}
    />
  );
}

Form.Directory = FormDirectory;
Form.Group = FormGroup;
Form.InlineGroup = FormInlineGroup;
Form.Spacing = FormSpacing;
Form.WhenFieldChanges = WhenFieldChanges;

export default Form;
