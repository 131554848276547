import addDays from './addDays';

/**
 * Gets the date of the given next weekday
 * F.e: getNextWeekday(new Date(2021,0,1), 2) => new Date(2021, 0, 5)
 *      Returns the next tuesday of 1.1.2021
 * The weekdays are based on dt.getDay(): 0 = sunday, 1 = monday...
 */
export default function getNextWeekday(dt, weekday) {
  let date = new Date(dt);

  while (date.getDay() !== weekday) {
    date = addDays(date, 1);
  }

  return date;
}
