// import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  hr: {
    width: '100%',
    height: '1px',
    background: theme.color.common.grey.main
  }
}));

export default function Hr() {
  const classes = useStyles();

  return <Box className={classes.hr} />;
}

Hr.propTypes = {};

Hr.defaultProps = {};
