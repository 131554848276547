export default function isDummyEmail(email: String): boolean {
  if (
    !!email &&
    email?.endsWith('@dummy.tssv') &&
    email?.length === 47 &&
    email?.split('-').length === 5
  ) {
    return true;
  }

  return false;
}
