import { isBefore } from './index';

/**
 * Check if given date is last week
 */
export default function isLastWeek(dt) {
  const td = new Date();
  const lw = new Date(td.getFullYear(), td.getMonth(), td.getDate() - 8);

  return isBefore(dt, td) && isBefore(lw, dt);
}
