import PropTypes from 'prop-types';
import React from 'react';
import Pagination from '@mui/lab/Pagination';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  pagination: {
    ...(theme.components?.pagination ? theme.components?.pagination : {})
  }
}));

export default function _Pagination(props) {
  const classes = useStyles(props);

  return (
    <Pagination
      className={classes.pagination}
      count={props.count}
      onChange={props.handleChange}
      page={props.page}
    />
  );
}

_Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired
};

_Pagination.defaultProps = {
  count: 0,
  page: 0
};
