import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, useField } from 'react-final-form';
import { makeStyles } from '@mui/styles';

import NumberField from './NumberField';
import Spacing from './Spacing';
import Label from './Label';
import { compose, required, time, timeRange } from '../utils/form/validators';
import { isObject } from '../utils';

// TBD: intl
const PLACEHOLDER_HOUR = 'S';
const PLACEHOLDER_MINUTE = 'M';

const useStyles = makeStyles(theme => ({
  wrapperFieldset: {
    border: 0,
    padding: 0
  },
  timePicker: {
    width: '5em'
  }
}));

export default function TimeRangePicker(props) {
  const classes = useStyles(props);
  const field = useField(props.id);
  const [touched, setTouched] = useState({ start: false, end: false });

  useEffect(() => {
    setTouched({ start: false, end: false });
  }, [field.meta.touched]);

  function handleChange(key, v, e) {
    field.input.onChange({
      ...field.input.value,
      [key]: v
    });

    if (props.onChange) props.onChange(v, e);
  }

  function handleBlur(key, e) {
    const { value } = e?.target;

    if (value !== '') {
      let updatedValue = value.replace(':', '');

      if (
        updatedValue.includes(PLACEHOLDER_HOUR) ||
        updatedValue.includes(PLACEHOLDER_MINUTE)
      ) {
        const hoursZeroed = updatedValue.replace(/S/g, '0');
        const minutesZerod = hoursZeroed.replace(/M/g, '0');
        updatedValue = minutesZerod;
      }

      if (
        Number(updatedValue) >= 2400 ||
        (Number(updatedValue) === 0 && key === 'end')
      ) {
        updatedValue = '2359';
      }

      field.input.onChange({
        ...field.input.value,
        [key]: updatedValue
      });
    }

    const updatedTouched = { ...touched, [key]: true };
    setTouched(updatedTouched);

    if (
      (updatedTouched.start && updatedTouched.end) ||
      (field.input.value?.start && field.input.value?.end)
    ) {
      field.input.onBlur();

      if (props.onBlur) props.onBlur();
    }
  }

  function handleFocus() {
    field.input.onFocus();

    if (props.onFocus) props.onFocus();
  }

  function getValidators() {
    const validators = [timeRange];

    validators.push(v => time(v?.start));
    validators.push(v => time(v?.end));

    if (props.required) {
      validators.push(v => required(v?.start));
      validators.push(v => required(v?.end));
    }

    return validators;
  }

  return (
    <Field
      name={props.id}
      validate={compose(getValidators())}
      validateFields={props.validateFields || []}
    >
      {({ meta }) => {
        const error =
          props.error || ((meta.error || meta.submitError) && meta.touched);
        let errorText =
          props.errorText ||
          (meta.touched ? meta.error || meta.submitError : '');

        errorText = isObject(errorText)
          ? Object.values(errorText).join('')
          : errorText;

        return (
          <FormControl error={error} disabled={props.disabled}>
            <fieldset className={classes.wrapperFieldset}>
              <Label
                for={props.id}
                as="legend"
                disabled={props.disabled}
                required={props.required && !props.requiredWithoutAsterisk}
                popover={{
                  title: props.popoverTitle,
                  text: props.popoverText
                }}
              >
                {props.label}
              </Label>
              <Box id={props.id} display="flex">
                <Box className={classes.timePicker}>
                  <Label hidden for={`${props.id}.start`}>
                    {/* TBD: intl*/}
                    Von
                  </Label>
                  <NumberField
                    formatProps={{
                      format: '##:##',
                      // TBD: intl
                      mask: [
                        PLACEHOLDER_HOUR,
                        PLACEHOLDER_HOUR,
                        PLACEHOLDER_MINUTE,
                        PLACEHOLDER_MINUTE
                      ]
                    }}
                    // TBD: intl
                    placeholder={`${PLACEHOLDER_HOUR}${PLACEHOLDER_HOUR}:${PLACEHOLDER_MINUTE}${PLACEHOLDER_MINUTE}`}
                    endIcon="clock"
                    aria-describedby={`${props.id}-helper-text`}
                    disabled={props.disabled}
                    onFocus={handleFocus}
                    onChange={(v, e) => handleChange('start', v, e)}
                    onBlur={e => handleBlur('start', e)}
                    error={error}
                    fullWidth
                    mode="string"
                    autoComplete="off"
                    id={`${props.id}.start`}
                  />
                </Box>
                <Spacing x={1} />
                <Box className={classes.timePicker}>
                  <Label hidden for={`${props.id}.end`}>
                    {/* TBD: intl*/}
                    Bis
                  </Label>
                  <NumberField
                    formatProps={{
                      format: '##:##', // TBD: intl
                      mask: [
                        PLACEHOLDER_HOUR,
                        PLACEHOLDER_HOUR,
                        PLACEHOLDER_MINUTE,
                        PLACEHOLDER_MINUTE
                      ]
                    }}
                    // TBD: intl
                    placeholder={`${PLACEHOLDER_HOUR}${PLACEHOLDER_HOUR}:${PLACEHOLDER_MINUTE}${PLACEHOLDER_MINUTE}`}
                    endIcon="clock"
                    disabled={props.disabled}
                    onFocus={handleFocus}
                    onChange={(v, e) => handleChange('end', v, e)}
                    onBlur={e => handleBlur('end', e)}
                    error={error}
                    fullWidth
                    mode="string"
                    autoComplete="off"
                    id={`${props.id}.end`}
                  />
                </Box>
              </Box>
              {(errorText || props.helperText) && (
                <FormHelperText
                  style={{ margin: 0 }}
                  id={`${props.id}-helper-text`}
                >
                  {errorText || props.helperText}
                </FormHelperText>
              )}
            </fieldset>
          </FormControl>
        );
      }}
    </Field>
  );
}

TimeRangePicker.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  error: PropTypes.bool,
  label: PropTypes.node,
  required: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool,
  defaultValue: PropTypes.string,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string
};

TimeRangePicker.defaultProps = {
  disabled: false,
  error: false,
  label: undefined,
  required: false,
  requiredWithoutAsterisk: false,
  defaultValue: undefined,
  errorText: undefined,
  helperText: undefined,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  value: undefined
};
