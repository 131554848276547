import { isSameDate } from './index';

/**
 * Check if given date is last day
 */
export default function isLastDay(dt) {
  const td = new Date();
  const nd = new Date(td.setDate(td.getDate() - 1));

  return isSameDate(nd, dt);
}
