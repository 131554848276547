/**
 * Gets the span between two dates
 */
export default function getSpan(a, b) {
  const milliseconds = Math.abs(b.getTime() - a.getTime());
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  return { milliseconds, seconds, minutes, hours, days };
}
