import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { ConsumerDivision, Pagination } from '../interfaces';
import { RootState } from '.';
import { client } from '../api/client';
import { consumerDivisionsEntity } from '../schemas';
import { paginate, safeArray } from '../utils';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const fetchConsumerDivision = createAsyncThunk(
  'consumerDivisions/byId',
  async consumerDivisionId => {
    const response = await client.get(
      `consumer_divisions/${consumerDivisionId}`
    );
    const normalized = normalize(response.data.data, consumerDivisionsEntity);

    return normalized.entities;
  }
);

export const fetchConsumerDivisions = createAsyncThunk(
  'consumerDivisions/all',
  async (filter: Pagination) => {
    const response = await client.get('/consumer_divisions', filter);
    const normalized = normalize(response.data.data, [consumerDivisionsEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const createConsumerDivision = createAsyncThunk(
  'consumerDivisions/create',
  async division => {
    const response = await client.post('/consumer_divisions', {
      consumer_division: division
    });
    const normalized = normalize(response.data.data, consumerDivisionsEntity);

    return normalized.entities;
  }
);

export const updateConsumerDivision = createAsyncThunk(
  'consumerDivisions/update',
  async ({
    id,
    consumerDivision
  }: {
    id: string;
    consumerDivision: ConsumerDivision;
  }) => {
    const response = await client.put(`/consumer_divisions/${id}`, {
      consumer_division: consumerDivision
    });
    const normalized = normalize(response.data.data, consumerDivisionsEntity);

    return normalized.entities;
  }
);

export const archiveConsumerDivision = createAsyncThunk(
  'consumerDivisions/archive',
  async (id: number) => {
    await client.put(`/archive/consumer_divisions/${id}`);
    return id;
  }
);

export const unarchiveConsumerDivision = createAsyncThunk(
  'consumerDivisions/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/consumer_divisions/${id}`);
    return id;
  }
);

export const deleteConsumerDivision = createAsyncThunk(
  'consumer_divisions/delete',
  async (id: number) => {
    await client.delete(`/consumer_divisions/${id}`);
    return id;
  }
);

const consumerDivisionAdapter = createEntityAdapter<ConsumerDivision>();
const initialState = consumerDivisionAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const consumerDivisionSlice = createSlice({
  name: 'consumerDivisions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        consumerDivisionAdapter.upsertMany(
          state,
          safeArray(payload.consumerDivisions.entities)
        );
        state.pagination = payload.consumerDivisions.pagination;
      });
    });
    builder.addCase(fetchConsumerDivision.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchConsumerDivision.fulfilled, (state, { payload }) => {
      consumerDivisionAdapter.upsertMany(
        state,
        safeArray(payload.consumerDivisions)
      );
      state.isLoading = false;
    });
    builder.addCase(fetchConsumerDivision.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchConsumerDivisions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchConsumerDivisions.fulfilled, (state, { payload }) => {
      consumerDivisionAdapter.upsertMany(
        state,
        safeArray(payload.consumerDivisions)
      );
      state.pagination = payload.pagination;
      state.isLoading = false;
    });
    builder.addCase(fetchConsumerDivisions.rejected, state => {
      state.isLoading = false;
    });
  }
});

export const consumerDivisionSelectors =
  consumerDivisionAdapter.getSelectors<RootState>(
    state => state.consumerDivisions
  );

export default consumerDivisionSlice.reducer;
