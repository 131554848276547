/**
 * Gets an array of months names
 */
export default function getMonthsForLocale(locale) {
  const format = new Intl.DateTimeFormat(locale, { month: 'long' });
  const months = [];

  for (let month = 0; month < 12; month++) {
    const iterableDate = new Date(Date.UTC(2020, month, 1, 0, 0, 0));
    months.push(format.format(iterableDate));
  }

  return months;
}
