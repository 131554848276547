import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';

import Button from './Button';
import ElementPicker from './ElementPicker';

const useStyles = makeStyles(theme => ({
  elementPickerElement: {
    paddingLeft: '0.75em',
    paddingRight: '0.75em',
    fontWeight: 400,
    margin: 0,
    background: ({ state }) => {
      if (state.isActive) {
        return state.disabled ? 'transparent' : theme.color.background.grey;
      } else {
        return 'transparent';
      }
    },
    borderColor: ({ state }) => {
      if (state.isActive) {
        return state.disabled
          ? theme.color.border.main
          : theme.color.primary.main;
      } else {
        return state.disabled
          ? theme.color.background.grey
          : theme.color.border.light;
      }
    },
    color: ({ state }) => {
      if (state.isActive) {
        return state.disabled ? theme.color.text.light : theme.color.text.main;
      } else {
        return state.disabled ? theme.color.text.light : theme.color.text.main;
      }
    },
    '&:focus-visible': {
      background: ({ state }) =>
        state.isActive ? theme.color.background.grey : 'transparent'
    }
  }
}));

export default function ElementPickerElement(props) {
  const elementPickerContext = useContext(ElementPicker.Context);
  const isActive =
    elementPickerContext.mode === 'toggle'
      ? props.index === elementPickerContext.indices
      : elementPickerContext.indices?.find(index => index === props.index);

  const disabled = props.disabled || elementPickerContext.disabled;
  const classes = useStyles({ props, state: { isActive, disabled } });

  function updateIndices() {
    if (isActive) {
      elementPickerContext.removeIndex(props.index);
    } else {
      elementPickerContext.addIndex(props.index);
    }
  }

  return (
    <Button
      className={classes.elementPickerElement}
      disabled={disabled}
      color={isActive ? 'primary' : 'default'}
      variant="outlined"
      data-selected={isActive}
      onClick={updateIndices}
    >
      {props.children}
    </Button>
  );
}

ElementPickerElement.propTypes = {
  children: PropTypes.string.isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  active: PropTypes.bool
};

ElementPickerElement.defaultProps = {
  onClick: () => {},
  disabled: false,
  active: false
};
