import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Box from '@mui/material/Box';

import TabbarTab from './TabbarTab';
import TabbarTabs from './TabbarTabs';
import TabbarTabPanel from './TabbarTabPanel';
import { useEffect } from 'react';

const TabbarContext = React.createContext({});

export default function Tabbar(props) {
  const [index, setIndex] = useState(props.value);

  function setTab(i) {
    setIndex(i);

    if (props.onChange && i !== undefined) props.onChange(i);
  }

  useEffect(() => {
    if (props.value !== index) {
      setIndex(props.value);
    }
  }, [props.value]);

  return (
    <TabbarContext.Provider value={{ index, setTab, disabled: props.disabled }}>
      {props.children}
    </TabbarContext.Provider>
  );
}

Tabbar.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Tabbar.defaultProps = {
  children: undefined,
  onChange: undefined,
  disabled: false,
  value: undefined
};

Tabbar.Tab = TabbarTab;
Tabbar.Tabs = TabbarTabs;
Tabbar.TabPanel = TabbarTabPanel;
Tabbar.Context = TabbarContext;
