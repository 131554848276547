import PropTypes from 'prop-types';
import React from 'react';
import { FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { classNames } from '../utils';

const useStyles = makeStyles(theme => ({
  error: {
    ...(theme.components?.error?.root ? theme.components?.error?.root : {})
  }
}));

export default function Error(props) {
  const classes = useStyles();

  return (
    <FormHelperText
      className={classNames([classes.error, props.className])}
      style={{ margin: 0 }}
      error
    >
      {props.children}
    </FormHelperText>
  );
}

Error.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

Error.defaultProps = {
  children: undefined,
  className: PropTypes.string
};
