import { AnyAction } from 'redux';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { fetchBooking, fetchBookings } from './booking.slice';
import { fetchConsumerTeams } from './consumerTeam.slice';
import { OccasionPrimaryCategory } from '../interfaces';
import { RootState } from '.';
import { fetchRequest } from './request.slice';
import { fetchOccasions } from './occasion.slice';
import { safeArray } from '../utils';
import HYDRATE_FIX from './_HYDRATE_FIX';

const occasionPrimaryCategoryAdapter =
  createEntityAdapter<OccasionPrimaryCategory>();
const initialState = occasionPrimaryCategoryAdapter.getInitialState({
  isLoading: false
});
const occasionPrimaryCategoriesSlice = createSlice({
  name: 'occasionPrimaryCategories',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        occasionPrimaryCategoryAdapter.upsertMany(
          state,
          safeArray(payload.occasionPrimaryCategories.entities)
        );
      });
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      occasionPrimaryCategoryAdapter.upsertMany(
        state,
        safeArray(payload.occasionPrimaryCategories)
      );
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      occasionPrimaryCategoryAdapter.upsertMany(
        state,
        safeArray(payload.occasionPrimaryCategories)
      );
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      occasionPrimaryCategoryAdapter.upsertMany(
        state,
        safeArray(payload.occasionPrimaryCategories)
      );
    });
    builder.addCase(fetchConsumerTeams.fulfilled, (state, { payload }) => {
      occasionPrimaryCategoryAdapter.upsertMany(
        state,
        safeArray(payload.occasionPrimaryCategories)
      );
    });
    builder.addCase(fetchOccasions.fulfilled, (state, { payload }) => {
      occasionPrimaryCategoryAdapter.upsertMany(
        state,
        safeArray(payload.occasionPrimaryCategories)
      );
    });
  }
});

export const occasionPrimaryCategorySelectors =
  occasionPrimaryCategoryAdapter.getSelectors<RootState>(
    state => state.occasionPrimaryCategories
  );

export default occasionPrimaryCategoriesSlice.reducer;
