/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';
import { providerMembersEntity } from './providerMember.schema';
import { domainsEntity } from './domain.schema';
import { seasonsEntity } from './season.schema';
import getDefaultAddress from '../selectors/address/getDefaultAddress';
import getDefaultContact from '../selectors/contact/getDefaultContact';

export const providersEntity = new schema.Entity(
  'providers',
  {
    provider_members: [providerMembersEntity],
    domains: [domainsEntity],
    seasons: [seasonsEntity]
  },
  {
    processStrategy: provider => ({
      ...provider,
      defaultAddress: getDefaultAddress(provider.addresses),
      defaultContact: getDefaultContact(provider.contacts)
    })
  }
);
