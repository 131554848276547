/* https://github.com/final-form/final-form/issues/37#issuecomment-417715696 */
export default function fieldMemoize(fn) {
  let lastArg;
  let lastResult;

  return arg => {
    if (JSON.stringify(arg) !== JSON.stringify(lastArg)) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
}
