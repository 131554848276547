import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';

import Icon from './Icon';

const useStyles = makeStyles(theme => ({
  status: {
    border: `1px solid ${theme.color.common.grey.main}`,
    borderRadius: '50%',
    height: '1em',
    width: '1em'
  },
  declined: {
    backgroundColor: theme.color.background.secondary
  },
  accepted: {
    backgroundColor: theme.color.background.primary
  },
  completed: {
    backgroundColor: theme.color.success.main
  },
  number: {
    alignItems: 'center',
    color: theme.color.text.main,
    fontSize: theme.font.size.small,
    height: props => (props.expanded ? 'auto' : '1em'),
    textAlign: 'center',
    width: props => (props.expanded ? 'auto' : '1em')
  }
}));

export default function StatusIndicator(props) {
  const classes = useStyles(props);

  return (
    <Box display="flex" flexDirection={props.expanded ? 'column' : 'row'}>
      <Box
        display="flex"
        flexDirection={props.expanded ? 'row' : 'column'}
        title={props.titleAccepted}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={`${classes.status} ${classes.accepted}`}
        >
          <Icon name="check" color="white" size="small" />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={props.expanded ? 0 : 0.5}
          ml={props.expanded ? 1 : 0.25}
          className={classes.number}
        >
          {props.accepted}
          {props.expanded ? ` ${props.titleAccepted}` : ''}
        </Box>
      </Box>
      {!props.statusLocked && (
        <Box
          ml={props.expanded ? 0 : 0.5}
          mt={props.expanded ? 1 : 0}
          display="flex"
          flexDirection={props.expanded ? 'row' : 'column'}
          title={props.titleUnrated}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.status}
          ></Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={props.expanded ? 0 : 0.5}
            ml={props.expanded ? 1 : 0.25}
            className={classes.number}
          >
            {props.unrated}
            {props.expanded ? ` ${props.titleUnrated}` : ''}
          </Box>
        </Box>
      )}
      <Box
        ml={props.expanded ? 0 : 0.5}
        mt={props.expanded ? 1 : 0}
        display="flex"
        flexDirection={props.expanded ? 'row' : 'column'}
        title={props.titleDeclined}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={`${classes.status} ${classes.declined}`}
        >
          <Icon name="close" color="white" size="small" />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={props.expanded ? 0 : 0.5}
          ml={props.expanded ? 1 : 0.25}
          className={classes.number}
        >
          {props.declined}
          {props.expanded ? ` ${props.titleDeclined}` : ''}
        </Box>
      </Box>
      {(props.statusPublished || props.cancelled > 0) && (
        <Box
          ml={props.expanded ? 0 : 0.5}
          mt={props.expanded ? 1 : 0}
          display="flex"
          flexDirection={props.expanded ? 'row' : 'column'}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={`${classes.status} ${classes.declined}`}
          >
            <Icon
              name="block"
              color="white"
              size="small"
              title={props.titleCancelled}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={props.expanded ? 0 : 0.5}
            ml={props.expanded ? 1 : 0.25}
            className={classes.number}
          >
            {props.cancelled}
            {props.expanded ? ` ${props.titleCancelled}` : ''}
          </Box>
        </Box>
      )}
    </Box>
  );
}

StatusIndicator.propTypes = {
  accepted: PropTypes.number,
  declined: PropTypes.number,
  unrated: PropTypes.number,
  expanded: PropTypes.bool,
  statusLocked: PropTypes.bool,
  statusPublished: PropTypes.bool,
  titleAccepted: PropTypes.string,
  titleCompleted: PropTypes.string,
  titleCancelled: PropTypes.string,
  titleDeclined: PropTypes.string,
  titleUnrated: PropTypes.string
};

StatusIndicator.defaultProps = {
  accepted: 0,
  declined: 0,
  unrated: 0,
  expanded: false,
  statusLocked: false,
  statusPublished: false,
  titleAccepted: '',
  titleDeclined: '',
  titleUnrated: '',
  titleCompleted: '',
  titleCancelled: ''
};
