import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';

import isAfter from '../../utils/date/isAfter';
import isBefore from '../../utils/date/isBefore';
import isBetween from '../../utils/date/isBetween';
import isSameDate from '../../utils/date/isSameDate';

const styles = theme => {
  const getSize = size => {
    switch (size) {
      case 'small':
        return '2em';
      default:
      case 'medium':
        return '3em';
      case 'large':
        return '4em';
    }
  };

  return {
    wrapper: {
      display: 'inline-flex'
    },
    box: {
      color: theme.color.text.main,
      fontWeight: 'bold'
    },
    dot: {
      borderRadius: '1em',
      height: '5px',
      margin: '0.25em auto 0',
      width: '5px'
    },
    day: {
      cursor: 'default',
      display: 'flex',
      height: props => getSize(props.size),
      justifyContent: 'center',
      lineHeight: props => getSize(props.size),
      margin: '0 auto 0.25em',
      textAlign: 'center',
      verticalAlign: 'middle',
      width: props => getSize(props.size)
    },
    hidden: {
      display: 'none'
    },
    selected: {
      color: theme.color.primary.contrastText,
      background: theme.color.primary.main
    },
    today: {
      background: theme.color.primary.light
    },
    selectable: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.color.primary.contrastText,
        background: theme.color.primary.main
      }
    },
    forbidden: {
      color: theme.color.text.light,
      cursor: 'not-allowed'
    }
  };
};

// eslint-disable-next-line no-unused-vars
function inRange(props) {
  if (props.minDate && !props.maxDate) {
    if (
      !isAfter(props.day, props.minDate) &&
      !isSameDate(props.day, props.minDate)
    ) {
      return false;
    }
  } else if (!props.minDate && props.maxDate) {
    if (
      !isBefore(props.day, props.maxDate) &&
      !isSameDate(props.day, props.maxDate)
    ) {
      return false;
    }
  } else if (props.minDate && props.maxDate) {
    if (
      !isBetween(props.minDate, props.day, props.maxDate) &&
      !isSameDate(props.day, props.minDate) &&
      !isSameDate(props.day, props.maxDate)
    ) {
      return false;
    }
  }

  return true;
}

class Day extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);

    this.state = {
      events: [],
      dayStyle: {}
    };
  }

  componentDidMount() {
    this.setState({
      events: this.props.events || []
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      events: nextProps || []
    });
  }

  getDayClasses() {
    const { classes, compareDate, month, day, onClick } = this.props;
    let dayClasses = {};

    if (month !== day.getMonth()) {
      dayClasses = `${classes.day} ${classes.hidden}`;
    } else if (onClick && compareDate && isSameDate(day, compareDate)) {
      dayClasses = `${classes.day} ${classes.selected}`;
    } else if (day && isSameDate(day, new Date())) {
      dayClasses = `${classes.day} ${classes.today}`;
    } else {
      dayClasses = classes.day;
    }

    if (onClick) {
      if (!inRange(this.props)) {
        dayClasses = `${dayClasses} ${classes.forbidden}`;
      } else {
        dayClasses = `${dayClasses} ${classes.selectable}`;
      }
    }

    return dayClasses;
  }

  getDots() {
    const { classes } = this.props;

    return this.props.events.map(event => (
      <Box key={event.id} width={1 / 3}>
        <div
          style={{ background: event.ground_color }}
          className={classes.dot}
        />
      </Box>
    ));
  }

  handleClick() {
    if (this.props.onClick && inRange(this.props)) {
      this.props.onClick(this.props.day);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Box
        display="flex"
        className={classes.wrapper}
        justifyContent="center"
        width={1 / 7}
      >
        <Box className={classes.box} onClick={this.handleClick}>
          <div className={this.getDayClasses()}>{this.props.day.getDate()}</div>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {this.getDots.bind(this)()}
          </Box>
        </Box>
      </Box>
    );
  }
}

Day.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  compareDate: PropTypes.instanceOf(Date),
  day: PropTypes.instanceOf(Date).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  events: PropTypes.array,
  month: PropTypes.number,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

Day.defaultProps = {
  compareDate: undefined,
  events: [],
  month: undefined,
  maxDate: undefined,
  minDate: undefined,
  onClick: undefined,
  size: 'medium'
};

export default withStyles(styles)(Day);
