import {
  addDays,
  getEndOfWeek,
  getStartOfMonth,
  getStartOfWeek
} from 'groundkeeper-component-library';

const getMonth = (date, fillGaps) => {
  const firstOfMonth = getStartOfMonth(date);
  const weeks = new Array(6);
  let currentWeek = getStartOfWeek(firstOfMonth);
  let endOfWeek;
  let startOfWeek;

  for (let w = 0; w < weeks.length; w += 1) {
    startOfWeek = getStartOfWeek(currentWeek);
    endOfWeek = getEndOfWeek(currentWeek);

    const days = [];

    for (let d = startOfWeek; d <= endOfWeek; d = addDays(d, 1)) {
      if (fillGaps) {
        days.push(d);
      } else if (d.getMonth() === date.getMonth()) {
        days.push(d);
      }
    }

    weeks[w] = days;

    currentWeek = addDays(currentWeek, 7);
  }

  return weeks.filter(w => w.length);
};

export default function getCalendar(date) {
  const bufferedMonths = { prev: [], current: [], next: [] };

  if (!date) return bufferedMonths;

  const wantedYear = date.getFullYear();
  const wantedMonth = date.getMonth();

  for (let i = 0; i < 12; i++) {
    let key;
    if (i < wantedMonth) key = 'prev';
    if (i === wantedMonth) key = 'current';
    if (i > wantedMonth) key = 'next';

    bufferedMonths[key].push(getMonth(new Date(wantedYear, i, 1)));
  }

  return bufferedMonths;
}
