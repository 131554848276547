import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Portal } from '@mui/base';
import { makeStyles } from '@mui/styles';

import Paper from './Paper';
import Void from './Void';

import ModalActions from './ModalActions';
import ModalContent from './ModalContent';
import ModalHeading from './ModalHeading';
import Button from './Button';

const useStyles = makeStyles(theme => {
  const hasHeading = children =>
    children?.length
      ? children.filter(c => c !== null && c.type === ModalHeading).length
      : false;

  return {
    modal: {
      backgroundColor: 'rgba(0,0,0, 0.4)',
      fontFamily: theme.font.primary,
      height: '100vh',
      left: 0,
      position: 'fixed',
      top: 0,
      width: '100vw',
      zIndex: 99,
      ...(theme.components?.modal?.root ? theme.components?.modal?.root : {})
    },
    closeButton: {
      position: props => (hasHeading(props.children) ? 'absolute' : 'initial'),
      display: props => (hasHeading(props.children) ? 'block' : 'flex'),
      justifyContent: 'flex-end',
      right: theme.spacing(1),
      top: theme.spacing(1),
      ...(theme.components?.modal?.closeButton
        ? theme.components?.modal?.closeButton
        : {})
    },
    window: {
      background: theme.color.background.default,
      boxSizing: 'border-box',
      borderRadius: '.25rem',
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '80%',
      overflow: 'auto',
      minWidth: '15rem',
      maxWidth: '95%',
      position: 'relative',
      width: props => {
        switch (props.size) {
          case 'small':
            return '25rem';
          case 'medium':
            return '30rem';
          case 'large':
            return '45rem';
          case 'huge':
            return '70rem';
          default:
            return 'auto';
        }
      },
      ...(theme.components?.modal?.window
        ? theme.components?.modal?.window
        : {})
    }
  };
});

export default function Modal(props) {
  const classes = useStyles(props);
  const [open, setOpen] = useState(props.open);
  const openRef = useRef(open);
  const closeButtonRef = useRef();

  useEffect(() => setOpen(props.open), [props.open]);

  useEffect(() => {
    openRef.current = open;

    if (open) {
      setTimeout(() => {
        if (closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      });
    }

    // Hide scrollbar on modal open
    document.body.style.overflow = open ? 'hidden' : null;
  }, [open]);

  const handleCloseEsc = useCallback(event => {
    if (event.key === 'Escape' && openRef.current === true) {
      setOpen(false);
      props.onClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleCloseEsc, false);

    return () => {
      document.removeEventListener('keydown', handleCloseEsc, false);
      document.body.style.overflow = null;
    };
  }, []);

  return open ? (
    <Portal>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.modal}
        data-testid={props['data-testid']}
        // onClick={e => e.stopPropagation()}
      >
        <Paper className={classes.window}>
          {typeof props.onClose === 'function' && props.closeButton && (
            <div className={classes.closeButton}>
              <Button
                ref={closeButtonRef}
                icon
                aria-label={
                  props.closeButtonAriaLabel
                    ? props.closeButtonAriaLabel
                    : 'close'
                }
                onClick={() => {
                  setOpen(false);
                  props.onClose();
                }}
                lib={props.closeButtonLib}
                title={
                  props.closeButtonTitle ? props.closeButtonTitle : 'Schließen'
                }
              >
                close
              </Button>
            </div>
          )}
          {props.children}
        </Paper>
      </Box>
    </Portal>
  ) : (
    <Void />
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  'data-testid': PropTypes.string,
  open: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'huge']),
  closeButtonLib: PropTypes.oneOf(['fa', 'mui']),
  onClose: PropTypes.func,
  closeButton: PropTypes.bool,
  closeButtonAriaLabel: PropTypes.string,
  closeButtonTitle: PropTypes.string
};

Modal.defaultProps = {
  open: false,
  onClose: null,
  size: undefined,
  closeButton: true,
  closeButtonLib: undefined,
  closeButtonAriaLabel: 'Schließen',
  closeButtonTitle: 'Schließen'
};

Modal.Heading = ModalHeading;
Modal.Content = ModalContent;
Modal.Actions = ModalActions;
