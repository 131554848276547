import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Spacing from './Spacing';
import Text from './Text';
import TextShortener from './TextShortener';
import Void from './Void';

export default function FormPresenterMultiLine(props) {
  const {
    captionShowLess = 'show less',
    captionShowMore = 'show more',
    display = 'inline',
    popoverHover,
    popoverIcon,
    popoverText,
    popoverTitle,
    spacing = 1,
    title,
    value
  } = props;

  return (
    <>
      <Box style={{ display }}>
        {title ? <Text bold>{`${title}:`}</Text> : <Void />}
        <Text color="dark">
          <>
            <Spacing y={1} />
            <TextShortener
              fontSize="1em"
              length={200}
              caption_less={captionShowLess}
              caption_more={captionShowMore}
            >
              {value}
            </TextShortener>
          </>
        </Text>
      </Box>
      <Spacing y={spacing} />
    </>
  );
}

FormPresenterMultiLine.propTypes = {
  value: PropTypes.object
};

FormPresenterMultiLine.defaultProps = {
  value: null
};
