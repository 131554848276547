import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import Box from '@mui/material/Box';

const DEFAULT_FONTSIZE = '0.8em';

const useStyles = makeStyles(theme => ({
  showMoreLess: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.color.text.link,
    display: 'flex',
    fontSize: props => (props.fontSize ? props.fontSize : DEFAULT_FONTSIZE)
  },
  text: {
    fontSize: props => (props.fontSize ? props.fontSize : DEFAULT_FONTSIZE)
  }
}));

function isShortable(text, length) {
  return text?.length > length;
}

export function shortenText(text, length) {
  if (!isShortable(text, length)) {
    return text;
  }
  return text?.length ? text.substr(0, length - 1) + '...' : '';
}

export default function TextShortener(props) {
  const classes = useStyles(props);
  const [expanded, setExpanded] = useState(!!props.expanded);
  const [text, setText] = useState(props.text);

  useEffect(() => {
    let isExpanded = false;

    if (props.children && props.children.length > props.length) {
      isExpanded = false;
    } else {
      isExpanded = true;
    }

    if (props.expanded !== isExpanded) {
      isExpanded = props.expanded;
    }

    if (isExpanded) {
      setText(props.children);
      setExpanded(true);
    } else {
      setText(shortenText(props.children, props.length));
      setExpanded(false);
    }
  }, [props.children, props.expanded]);

  function onClickHandler() {
    props.onClick();
    if (!expanded) {
      setText(props.children);
      setExpanded(true);
    } else {
      setText(shortenText(props.children, props.length));
      setExpanded(false);
    }
  }

  return (
    <>
      <Box className={classes.text}>{text}</Box>
      {isShortable(text, props.length) &&
      props.caption_less &&
      props.caption_more ? (
        !expanded ? (
          <Box
            onClick={() => onClickHandler()}
            className={classes.showMoreLess}
          >
            {props.caption_more}
          </Box>
        ) : (
          <Box
            onClick={() => onClickHandler()}
            className={classes.showMoreLess}
          >
            {props.caption_less}
          </Box>
        )
      ) : null}
    </>
  );
}

TextShortener.propTypes = {
  caption_less: PropTypes.string,
  caption_more: PropTypes.string,
  children: PropTypes.string,
  expanded: PropTypes.bool,
  fontSize: PropTypes.string,
  length: PropTypes.number.isRequired,
  onClick: PropTypes.func
};

TextShortener.defaultProps = {
  children: [],
  expanded: false,
  fontSize: DEFAULT_FONTSIZE,
  onClick: () => {}
};
