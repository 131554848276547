import { isSameDate } from './index';

/**
 * Check if given date is next day
 */
export default function isNextDay(dt) {
  const td = new Date();
  const nd = new Date(td.setDate(td.getDate() + 1));

  return isSameDate(nd, dt);
}
