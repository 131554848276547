import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import PropTypes, { element } from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { Field } from 'react-final-form';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Button,
  Checkbox,
  Label,
  Icon,
  TextField,
  sortObjects
} from 'groundkeeper-component-library';

import { compose, required } from '../utils/form/validators';

const useStyles = makeStyles(theme => ({
  formControl: {
    maxWidth: props => (props.fullWidth ? null : '31.25em')
  },
  dropdownContainer: {
    cursor: 'pointer',
    display: 'inline-block',
    maxWidth: props => (props.fullWidth ? null : '31.25em'),
    minWidth: 'fit-content',
    position: 'relative',
    width: '100%',
    '&.disabled': {
      cursor: 'default'
    },
    '&.error': {
      '& div[aria-expanded="false"]': {
        borderColor: theme.color.error.main
      }
    }
  },
  optionContainer: {
    background: '#fff',
    border: `1px solid ${theme.color.border.main}`,
    borderBottomLeftRadius: '.25em',
    borderBottomRightRadius: '.25em',
    borderTop: '0 !important',
    boxSizing: 'border-box',
    left: 0,
    position: 'absolute',
    width: 'calc(100%)',
    zIndex: 100,
    '&:hover': {
      border: `1px solid ${theme.color.border.dark}`
    }
  },
  dropdown: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    paddingTop: '.5em',
    paddingBottom: '.5em',
    paddingLeft: '.75em',
    height: '37px',
    width: 'calc(100%)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    border: props =>
      props.showOptions
        ? `2px solid ${theme.color.primary.main}`
        : `1px solid ${theme.color.border.main}`,
    borderRadius: '4px',
    '&:hover': {
      border: props => {
        if (props.disabled) return null;

        return props.showOptions
          ? `2px solid ${theme.color.border.dark}`
          : `1px solid black`;
      }
    },
    '& svg': {
      transition: 'transform .2s'
    },
    '&[aria-expanded="true"]': {
      // borderBottomLeftRadius: 0,
      // borderBottomRightRadius: 0,

      '& svg': {
        transform: 'rotate(180deg)'
      }
    },
    ...(theme.components?.multiSelect?.dropdown
      ? theme.components?.multiSelect?.dropdown
      : {})
  },
  dropdownArrowIcon: {
    transition: 'none !important',
    '& svg': {
      fill: theme.color.common.grey.main
    },
    ...(theme.components?.multiSelect?.dropdownArrowIcon
      ? theme.components?.multiSelect?.dropdownArrowIcon
      : {})
  },
  dropdownInfo: {
    alignItems: 'center',
    display: 'inline-flex',
    gap: '.5em',
    marginLeft: '.5em'
  },
  tag: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '.3em',
    marginRight: '.3em',
    fontFamily: theme.font.primary,
    color: props =>
      props.disabled ? theme.color.text.light : theme.color.text.main,
    fontSize: theme.font?.size?.input,
    background: theme.color.background.grey,
    borderRadius: '4px',
    cursor: 'default',
    '& svg': {
      fill: `${theme.color.text.main}`,
      cursor: props => (props.disabled ? 'default' : 'pointer'),
      transform: 'rotate(0) !important'
    },
    ...(theme.components?.multiSelect?.tag
      ? theme.components?.multiSelect?.tag
      : {})
  },
  tagName: {
    maxWidth: '8em',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.mediaQueries.tiny]: {
      maxWidth: '5.5em'
    },
    ...(theme.components?.multiSelect?.tagName
      ? theme.components?.multiSelect?.tagName
      : {})
  },
  tagAction: {
    display: 'inline-flex',
    flexDirection: 'column',
    height: '.875em',
    justifyContent: 'center',
    marginLeft: '.3em'
  },
  selectedCounter: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '25px',
    width: '25px',
    fontFamily: theme.font.primary,
    color: props =>
      props.disabled ? theme.color.text.light : theme.color.text.main,
    fontSize: theme.font?.size?.input,
    background: theme.color.background.grey,
    borderRadius: '4px',
    ...(theme.components?.multiSelect?.selectedCounter
      ? theme.components?.multiSelect?.selectedCounter
      : {})
  },
  checkboxContainer: {
    postion: 'relative',
    maxHeight: '70vh',
    overflow: 'auto',
    [theme.breakpoints.up('xs')]: {
      maxHeight: '20vh'
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: '40vh'
    }
  },
  checkbox: {
    minWidth: '1em',
    margin: '.3em',
    marginLeft: 0,
    ...(theme.components?.multiSelect?.checkbox
      ? theme.components?.multiSelect?.checkbox
      : {})
  },
  searchContainer: {
    position: 'relative',
    '& svg': {
      position: 'absolute',
      right: '.5em',
      top: '50%',
      transform: 'translate(0, -50%)',
      cursor: props => (props.disabled ? 'default' : 'pointer')
    }
  },
  searchInput: {
    fontSize: theme.font?.size?.input,
    '& fieldset': {
      border: 0,
      borderBottom: `1px solid ${theme.color.border.main}`,
      borderRadius: 0
    },
    '& ::placeholder': {
      fontSize: theme.font?.size?.input,
      color: theme.color.common.grey.main,
      opacity: 1
    },
    ...(theme.components?.multiSelect?.searchInput
      ? theme.components?.multiSelect?.searchInput
      : {})
  },
  removeAllTags: {
    height: '1.3em',
    width: '1.3em',
    border: 0,
    background: 'none',
    '& svg': {
      cursor: props => (props.disabled ? 'default' : 'pointer'),
      fill: '#0000008a',
      height: '.75em',
      transform: 'translate(0) !important',
      width: '.75em'
    },
    ...(theme.components?.multiSelect?.removeAllTags
      ? theme.components?.multiSelect?.removeAllTags
      : {})
  },
  listItem: {
    listStyle: 'none',
    padding: '.5em 0',
    margin: 0,
    '& li': {
      padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
      lineHeight: '1em',
      '&:focus': {
        outline: 'none',
        background: theme.color.border.light
      }
    }
  },
  selectedItems: {
    position: 'relative',
    '&::before': {
      display: 'block',
      position: 'absolute',
      bottom: '0',
      left: '50%',
      transform: 'translate(-50%, 0)',
      content: '""',
      height: '1px',
      width: 'calc(100% - .5em)',
      background: `${theme.color.border.main}`
    },
    ...(theme.components?.multiSelect?.selectedItems
      ? theme.components?.multiSelect?.selectedItems
      : {})
  },
  dots: {
    color: `${theme.color.common.grey.main}`,
    marginLeft: '.5em'
  },
  placeholder: {
    fontFamily: theme.font.primary,
    color: theme.color.text.light,
    fontSize: theme.font?.size?.input,
    fontWeight: 400,
    userSelect: 'none',
    ...(theme.components?.multiSelect?.placeholder
      ? theme.components?.multiSelect?.placeholder
      : {})
  },
  textError: {
    color: '#f44336',
    ...(theme.components?.multiSelect?.error
      ? theme.components?.multiSelect?.error
      : {})
  },
  textHelper: {
    margin: 0,
    ...(theme.components?.multiSelect?.textHelper
      ? theme.components?.multiSelect?.textHelper
      : {})
  },
  infoText: {
    fontSize: theme.font?.size?.input,
    fontWeight: 400,
    cursor: 'default',
    ...(theme.components?.multiSelect?.infoText
      ? theme.components?.multiSelect?.infoText
      : {})
  },
  // listEntry: {
  //   '&:hover': {
  //     background: theme.color.background.grey
  //   }
  // },
  listEntryActive: {
    background: theme.color.background.grey
  },
  connectedItems: {
    background: theme.color.background.grey
  },
  notConnectedItems: {
    cursor: 'not-allowed',
    '&:hover': {
      background: 'transparent'
    }
  },
  closeButton: {
    padding: 0,
    '&, & svg': {
      height: '.5em',
      width: '.5em'
    },
    '& svg': {
      fill: theme.color.common.grey.main
    }
  }
}));

function MultiSelectAdapter({ input, meta, ...rest }) {
  return (
    <MultiSelect
      {...input}
      {...rest}
      selected={input.value === '' ? [] : input.value}
      onChange={value => input.onChange(value)}
      onBlur={() => meta.touched && input.onBlur()}
      error={!!(meta.touched && meta.error)}
      errorText={meta.touched ? meta.error : ''}
    />
  );
}

function MultiSelectField(props) {
  function getValidators() {
    const validators = [];

    if (props.required) validators.push(required);

    return validators;
  }

  return (
    <Field
      name={props.id}
      {...props}
      component={MultiSelectAdapter}
      validate={compose(getValidators())}
      validateFields={props.validateFields || []}
    />
  );
}

export default function MultiSelect(props) {
  const [showOptions, setShowOptions] = useState(false);
  const [selected, setSelected] = useState(props.selected);
  const [userInput, setUserInput] = useState('');
  const [hovered, setHovered] = useState(undefined);
  const [currentEntry, setCurrentEntry] = useState();

  const tagsWrapperRef = useRef();
  const tagsRef = useRef();
  const [pagination, setPagination] = useState({
    page: props.defaultPage,
    size: props.defaultPageSize
  });

  const currentEntryRef = useRef();
  currentEntryRef.current = currentEntry;
  const showOptionsRef = useRef();
  showOptionsRef.current = showOptions;
  const propsRef = useRef();
  propsRef.current = props;

  const scrollContainerRef = useRef();
  const wrapperRef = useRef(null);
  const classes = useStyles({ ...props, showOptions });

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  useEffect(() => {
    setCurrentEntry(0);
  }, [showOptions]);

  useEffect(() => {
    document.addEventListener('keydown', hideDropdownByKeyboard);
    document.addEventListener('mousedown', hideDropdownByMouse);

    return () => {
      document.removeEventListener('keydown', hideDropdownByKeyboard);
      document.removeEventListener('mousedown', hideDropdownByMouse);
    };
  }, []);

  const handlePageChange = async e => {
    e.stopPropagation();

    const updatedPagination = { ...pagination, page: pagination.page + 1 };
    setPagination(updatedPagination);

    await props.onInputChange(
      userInput,
      e,
      props.pagination
        ? {
            pagination: updatedPagination
          }
        : {}
    );
  };

  const showDropdown = () => {
    if (!props.disabled) {
      const updatedPagination = {
        page: props.defaultPage,
        size: props.defaultPageSize
      };
      setPagination(updatedPagination);
      setShowOptions(!showOptions);

      props.onInputChange(
        '',
        undefined,
        props.pagination ? { pagination: updatedPagination } : {}
      );
      props.onFocus();
    }
  };

  const hideDropdown = () => {
    setShowOptions(false);
    setUserInput('');
    props.onInputChange('', undefined, props.pagination ? { pagination } : {});
    props.onBlur();
  };

  const hideDropdownByMouse = e => {
    if (
      showOptionsRef.current &&
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target)
    ) {
      hideDropdown();
    }
  };

  const hideDropdownByKeyboard = e => {
    if (showOptionsRef.current) {
      // Hide by escape
      if (e.key === 'Escape') {
        hideDropdown();
      }
      // Navigate by arrow keys
      else if (e.key === 'ArrowDown') {
        const targets = document.querySelectorAll(
          `[data-option-index='${currentEntryRef?.current}']`
        );

        // Scroll if element outside of view
        if (targets.length && scrollContainerRef.current) {
          const targetRect = targets[0]?.getBoundingClientRect();
          const scrollContainerRect =
            scrollContainerRef.current?.getBoundingClientRect();
          if (
            targetRect.bottom + targetRect.height >
            scrollContainerRect.bottom
          ) {
            scrollContainerRef.current?.scrollTo({
              top: scrollContainerRef.current?.scrollTop + targetRect.height,
              left: 0
            });
          }
        }

        setCurrentEntry(c =>
          Math.min(c + 1, selected.length + searchItems()?.length - 1)
        );
      } else if (e.key === 'ArrowUp') {
        const targets = document.querySelectorAll(
          `[data-option-index='${currentEntryRef?.current}']`
        );

        // Scroll if element outside of view
        if (targets.length && scrollContainerRef.current) {
          const targetRect = targets[0]?.getBoundingClientRect();
          const scrollContainerRect =
            scrollContainerRef.current?.getBoundingClientRect();

          if (
            targetRect.top - targetRect.height * 1.3 <
            scrollContainerRect.top
          ) {
            scrollContainerRef.current?.scrollTo({
              top:
                scrollContainerRef.current?.scrollTop - targetRect.height * 1.3,
              left: 0
            });
          }
        }

        setCurrentEntry(c => Math.max(c - 1, 0));
      }
      // Add/Remove by Enter
      else if (
        e.key === 'Enter' &&
        !Number.isNaN(currentEntryRef.current) &&
        document.activeElement === document.getElementById(`${props.id}-search`)
      ) {
        e.preventDefault();
        const targets = document.querySelectorAll(
          `[data-option-index='${currentEntryRef?.current}']`
        );
        if (targets.length) {
          targets[0].click();
        }
      } else {
        setCurrentEntry(0);
      }

      // Hide by focus loss
      setTimeout(() => {
        if (!wrapperRef?.current?.contains(document.activeElement)) {
          hideDropdown();
        }
      });
    }
  };

  const handleChange = (v, e) => {
    e?.preventDefault();
    const updatedPagination = {
      page: props.defaultPage,
      size: props.defaultPageSize
    };
    setPagination(updatedPagination);
    setUserInput(v);
    props.onInputChange(
      v,
      e,
      props.pagination ? { pagination: updatedPagination } : {}
    );
  };

  const selectedItem = (items, type) => {
    const liDefaultView = (e, i) => (
      <span className={classes.tag} key={`${props.getOptionLabel(e)}-${i}`}>
        <span className={classes.tagName}>{props.getOptionLabel(e)}</span>
        <span
          className={classes.tagAction}
          onClick={e => {
            removeTag(e, i);
          }}
        >
          <IconButton
            disabled={props.disabled}
            className={classes.closeButton}
            data-testid="multiselect-clear"
            // TBD: Intl
            aria-label={`Element ${props.getOptionLabel(
              e
            )} aus Auswahl entfernen`}
          >
            <Icon name="close" />
          </IconButton>
        </span>
      </span>
    );

    const liListView = (e, i) => (
      <li
        onClick={() => {
          addTag(e);
        }}
        key={`${props.getOptionLabel(e)}-${i}`}
        tabIndex={-1}
        role="option"
        aria-selected={true}
        data-option-index={i}
        onMouseEnter={() => {
          setCurrentEntry(i);
        }}
        className={`${classes.listEntry}${
          currentEntry === i ? ` ${classes.listEntryActive}` : ''
        }`}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            addTag(e);
          }
        }}
      >
        <Checkbox
          color="primary"
          classes={{
            box: classes.checkbox
          }}
          checked
          readOnly
        >
          {props.getOptionLabel(e)}
        </Checkbox>
      </li>
    );

    if (props.ordered) {
      items.sort((a, b) => {
        return a.order - b.order;
      });
    }

    return items.map((e, i) => {
      if (type === 'list') {
        return liListView(e, i);
      } else {
        return liDefaultView(e, i);
      }
    });
  };

  const selectedList = () => {
    return (
      <ul className={`${classes.listItem} ${classes.selectedItems}`}>
        {selectedItem(selected, 'list')}
      </ul>
    );
  };

  const tags = items => (
    <>
      <Box ref={tagsWrapperRef} sx={{ display: 'flex', flex: 1 }}>
        <Box ref={tagsRef}>
          {selected.length > 0 &&
            selectedItem(items).slice(0, props.selectionListDisplayLimit)}
          {selected.length > props.selectionListDisplayLimit && (
            <span className={classes.dots}>...</span>
          )}
        </Box>
      </Box>
      <div className={classes.dropdownInfo}>
        <span className={classes.selectedCounter}>
          {props.showTags && selected.length > 0 && selected.length}
        </span>
        <IconButton
          tabIndex={0}
          type="button"
          disabled={props.disabled}
          className={classes.removeAllTags}
          onClick={unselectAll}
          data-testid="multiselect-clear-all"
          // TBD: Intl
          aria-label="Alle Elemente aus der Auswahl entfernen"
        >
          <Icon
            name="close"
            classes={{
              icon: classes.removeAllTagsIcon
            }}
          />
        </IconButton>
      </div>
    </>
  );

  const addTag = element => {
    if (!props.disabled) {
      let current = [...selected];
      const index = selected.findIndex(x =>
        props.isOptionEqualToValue(x, element)
      );

      index === -1 ? current.unshift(element) : current.splice(index, 1);

      setSelected([...current]);
      setUserInput('');

      props.onChange([...current]);
    }
  };

  const removeTag = (e, i) => {
    e.stopPropagation();
    if (!props.disabled) {
      let current = selected;
      current.splice(i, 1);
      setSelected([...current]);

      props.onChange([...current]);
    }
  };

  const unselectAll = e => {
    e.stopPropagation();

    if (!props.disabled) {
      setSelected([]);
      props.onChange([]);
      props.onBlur();
    }
  };

  const searchInput = id => (
    <div className={classes.searchContainer}>
      <TextField
        autoComplete="off"
        id={`${id}-search`}
        name={`${id}-search`}
        placeholder={props.searchPlaceholder}
        onChange={handleChange}
        classes={{
          muiTextField: classes.searchInput
        }}
        value={userInput}
        asSearchInput
        autoFocus
        fullWidth
      />
      <Icon name="search" />
    </div>
  );

  const sortOptions = options => {
    return sortObjects(
      options.map(option => {
        const label = props.getOptionLabel(option);

        return {
          preserved: option,
          label
        };
      }),
      'label'
    ).map(option => option.preserved);
  };

  const searchItems = () => {
    const filterItems = propsRef.current?.options.filter(
      item =>
        !selected.filter(s => propsRef.current?.isOptionEqualToValue(s, item))
          .length
    );

    const result = filterItems
      ? propsRef.current?.pagination
        ? filterItems
        : filterItems.filter(
            item =>
              propsRef.current
                ?.getOptionLabel(item)
                .toLowerCase()
                .indexOf(userInput.toLowerCase()) > -1
          )
      : [];

    return props.sort ? sortOptions(result) : result;
  };

  const dropdownItems = () => {
    const canItemBeAdded = e => {
      if (selected.length > 0) {
        for (var i = 0; i < selected.length; i++) {
          if (
            selected[i].order == e.order + 1 ||
            selected[i].order == e.order - 1
          ) {
            return true;
          }
        }

        return false;
      }

      return true;
    };

    const liStyle = e => {
      const selectedNotNull =
        e.order == hovered &&
        selected.some(s => s.order == hovered - 1 || s.order == hovered + 1)
          ? classes.connectedItems
          : classes.notConnectedItems;

      const selectedNull =
        e.order == hovered || e.order + 1 == hovered || e.order - 1 == hovered
          ? classes.connectedItems
          : null;

      if (props.ordered) {
        if (selected.length > 0) {
          return selectedNotNull;
        } else {
          return selectedNull;
        }
      }

      return null;
    };

    const li = (e, i) => {
      const isDisabled =
        props.isOptionDisabled?.(e) ||
        (props.ordered && !canItemBeAdded(e)) ||
        props.disabled ||
        (props.max && selected.length >= props.max);

      return (
        <li
          title={isDisabled ? props.disabledOptionTitle : null}
          className={`${liStyle(e)} ${classes.listEntry}${
            currentEntry === selected.length + i
              ? ` ${classes.listEntryActive}`
              : ''
          }`}
          tabIndex={-1}
          onMouseEnter={() => {
            setCurrentEntry(selected.length + i);
            setHovered(e.order);
          }}
          onMouseLeave={() => {
            setHovered();
          }}
          role="option"
          aria-selected={false}
          data-option-index={selected.length + i}
          onClick={ev => {
            ev.preventDefault();

            if (!isDisabled) {
              if (props.ordered && canItemBeAdded(e)) {
                addTag(e);
              } else if (!props.ordered) {
                addTag(e);
              }
            }
          }}
          onKeyDown={event => {
            if (!isDisabled) {
              if (event.key === 'Enter' && props.ordered && canItemBeAdded(e)) {
                addTag(e);
              } else if (event.key === 'Enter' && !props.ordered) {
                addTag(e);
              }
            }
          }}
          key={`${props.getOptionLabel(e)}-${e.value}-${i}`}
        >
          <Checkbox
            classes={{
              box: classes.checkbox
            }}
            key={`${props.getOptionLabel(e)}-${e.value}-${i}`}
            value={e.value}
            color="primary"
            checked={false}
            readOnly
            disabled={isDisabled}
          >
            {props.getOptionLabel(e)}
          </Checkbox>
        </li>
      );
    };

    const noOptionsText = text => (
      <li className={classes.infoText}>
        <span key="info-text">{text}</span>
      </li>
    );

    const items = searchItems();
    if (props.ordered) {
      items.sort((a, b) => {
        return a.order - b.order;
      });
    }

    return (
      <ul className={classes.listItem}>
        {items.length > 0
          ? items.map((e, i) => li(e, i))
          : noOptionsText(props.optionText)}
        {props.pagination &&
        props.options.length &&
        pagination.page * pagination.size <= props.options.length ? (
          <li>
            <Button
              disabled={props.disabled}
              fullWidth
              color="primary"
              startIcon="add"
              onClick={handlePageChange}
            >
              {props.loadMoreText}
            </Button>
          </li>
        ) : null}
      </ul>
    );
  };

  return (
    <FormControl
      className={classes.formControl}
      fullWidth
      error={props.error}
      disabled={props.disabled}
    >
      <Label
        for={`${props.id}-input`}
        disabled={props.disabled}
        required={props.required && !props.requiredWithoutAsterisk}
        popover={{
          title: props.popoverTitle,
          text: props.popoverText
        }}
      >
        {props.label}
      </Label>
      <div
        id={props.id}
        ref={wrapperRef}
        className={`
        ${classes.dropdownContainer}
        ${props.disabled ? 'disabled' : ''}
        ${props.error ? 'error' : ''}
      `}
      >
        <div
          className={classes.dropdown}
          data-testid="multiselect-close"
          onClick={showDropdown}
        >
          {/* <span tabIndex={0}></span> */}
          {selected.length === 0 ? (
            <Box
              sx={{ display: 'flex', flex: 1 }}
              className={classes.placeholder}
            >
              {props.placeholder}
            </Box>
          ) : null}
          {props.showTags && selected.length > 0 && tags(selected)}
          <IconButton
            onClick={showDropdown}
            disabled={props.disabled}
            // TBD: Intl
            aria-label={
              showOptions ? 'Optionen anzeigen' : 'Optionen verstecken'
            }
            className={classes.dropdownArrowIcon}
          >
            <Icon
              name={
                showOptions ? 'arrow-drop-up-sharp' : 'arrow-drop-down-sharp'
              }
            />
          </IconButton>
        </div>
        {showOptions && props.options && (
          <div className={classes.optionContainer}>
            {showOptions && props.searchable && searchInput(props.id)}
            <div className={classes.checkboxContainer} ref={scrollContainerRef}>
              {showOptions && selected.length > 0 && selectedList()}
              {dropdownItems()}
            </div>
          </div>
        )}
        <input
          id={`${props.id}-input`}
          style={{ display: 'none' }}
          aria-hidden="true"
          tabIndex="-1"
          defaultValue={
            selected?.length ? selected.map(s => props.getOptionLabel(s)) : null
          }
        />
        {(props.errorText || props.helperText) && (
          <FormHelperText
            classes={{ root: classes.textHelper, error: classes.textError }}
            aria-label={props.errorText || props.helperText}
            error={props.error}
          >
            {props.errorText || props.helperText}
          </FormHelperText>
        )}
      </div>
    </FormControl>
  );
}

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array,
  searchPlaceholder: PropTypes.string,
  selected: PropTypes.array,
  getOptionLabel: PropTypes.func.isRequired,
  isOptionEqualToValue: PropTypes.func.isRequired,
  isOptionDisabled: PropTypes.func,
  disabledOptionTitle: PropTypes.string,
  searchable: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  max: PropTypes.number,
  fullWidth: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  sort: PropTypes.bool,
  showTags: PropTypes.bool,
  defaultPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  pagination: PropTypes.bool,
  placeholder: PropTypes.string,
  optionText: PropTypes.string,
  disabled: PropTypes.bool,
  ordered: PropTypes.bool,
  required: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool,
  selectionListDisplayLimit: PropTypes.number
};

MultiSelect.defaultProps = {
  searchPlaceholder: 'Suchen…',
  selected: [],
  error: false,
  label: undefined,
  sort: false,
  errorText: undefined,
  helperText: undefined,
  pagination: false,
  fullWidth: false,
  isOptionDisabled: () => {},
  disabledOptionTitle: undefined,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onInputChange: () => {},
  defaultPage: 1,
  defaultPageSize: 10,
  searchable: false,
  max: undefined,
  showTags: false,
  placeholder: 'Bitte wählen',
  optionText: 'Kein Ergebnis',
  loadMoreText: 'Mehr laden',
  disabled: false,
  ordered: false,
  required: false,
  requiredWithoutAsterisk: false,
  selectionListDisplayLimit: 1
};

MultiSelect.Field = MultiSelectField;
