export default function sortObjects(array, key) {
  return array.sort((a, b) => {
    const neutralA =
      typeof a[key]?.toLowerCase === 'function' ? a[key].toLowerCase() : a;
    const neutralB =
      typeof b[key]?.toLowerCase === 'function' ? b[key].toLowerCase() : b;

    if (neutralA < neutralB) {
      return -1;
    }
    if (neutralA > neutralB) {
      return 1;
    }
    return 0;
  });
}
