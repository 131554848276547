import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { RootState } from '.';
import { Season, Pagination } from '../interfaces';
import { client } from '../api/client';
import { paginate, safeArray } from '../utils';
import { seasonsEntity } from '../schemas';

import { fetchBooking, fetchBookings } from './booking.slice';
import { fetchOrganization, fetchOrganizations } from './organization.slice';
import { fetchProvider, fetchProviders } from './provider.slice';
import { fetchRequest, fetchRequests } from './request.slice';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const fetchSeason = createAsyncThunk('seasons/byId', async seasonId => {
  const response = await client.get(`/seasons/${seasonId}`);
  const normalized = normalize(response.data.data, seasonsEntity);

  return normalized.entities;
});

export const fetchSeasons = createAsyncThunk(
  'seasons/all',
  async (filter: Pagination) => {
    const response = await client.get('/seasons', filter);
    const normalized = normalize(response.data.data, [seasonsEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const createSeason = createAsyncThunk(
  'providerSeasons/create',
  async season => {
    const response = await client.post('/seasons', { season });
    const normalized = normalize(response.data.data, seasonsEntity);

    return normalized.entities;
  }
);

export const updateSeason = createAsyncThunk(
  'seasons/update',
  async ({ id, season }: { id: string; season: Season }) => {
    const response = await client.put(`/seasons/${id}`, {
      season
    });
    const normalized = normalize(response.data.data, seasonsEntity);

    return normalized.entities;
  }
);

export const archiveSeason = createAsyncThunk(
  'seasons/archive',
  async (id: number) => {
    await client.put(`/archive/seasons/${id}`);
    return id;
  }
);

export const unarchiveSeason = createAsyncThunk(
  'seasons/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/seasons/${id}`);
    return id;
  }
);

export const deleteSeason = createAsyncThunk(
  'seasons/delete',
  async (id: number) => {
    await client.delete(`/seasons/${id}`);
    return id;
  }
);

const seasonAdapter = createEntityAdapter<Season>();
const initialState = seasonAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const seasonSlice = createSlice({
  name: 'seasons',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        seasonAdapter.upsertMany(state, safeArray(payload.seasons.entities));
        state.isLoading = false;
        state.pagination = payload.seasons.pagination;
      });
    });
    builder.addCase(fetchSeason.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchSeason.fulfilled, (state, { payload }) => {
      seasonAdapter.upsertMany(state, safeArray(payload.seasons));
      state.isLoading = false;
    });
    builder.addCase(fetchSeason.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchSeasons.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchSeasons.fulfilled, (state, { payload }) => {
      seasonAdapter.upsertMany(state, safeArray(payload.seasons));
      state.isLoading = false;
      state.pagination = payload.pagination;
    });
    builder.addCase(fetchSeasons.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createSeason.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createSeason.fulfilled, (state, { payload }) => {
      seasonAdapter.upsertMany(state, safeArray(payload.seasons));
      state.isLoading = false;
    });
    builder.addCase(createSeason.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateSeason.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateSeason.fulfilled, (state, { payload }) => {
      seasonAdapter.upsertMany(state, safeArray(payload.seasons));
      state.isLoading = false;
    });
    builder.addCase(updateSeason.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteSeason.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteSeason.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteSeason.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrganization.fulfilled, (state, { payload }) => {
      seasonAdapter.upsertMany(state, safeArray(payload.seasons));
    });
    builder.addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
      seasonAdapter.upsertMany(state, safeArray(payload.seasons));
    });
    builder.addCase(fetchProvider.fulfilled, (state, { payload }) => {
      seasonAdapter.upsertMany(state, safeArray(payload.seasons));
    });
    builder.addCase(fetchProviders.fulfilled, (state, { payload }) => {
      seasonAdapter.upsertMany(state, safeArray(payload.seasons));
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      seasonAdapter.upsertMany(state, safeArray(payload.seasons));
    });
    builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
      seasonAdapter.upsertMany(state, safeArray(payload.seasons));
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      seasonAdapter.upsertMany(state, safeArray(payload.seasons));
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      seasonAdapter.upsertMany(state, safeArray(payload.seasons));
    });
  }
});

export const seasonSelectors = seasonAdapter.getSelectors<RootState>(
  state => state.seasons
);

export default seasonSlice.reducer;
