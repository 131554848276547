import { checkPropTypes } from 'prop-types';

// const PropTypesHelper = {
//   component: isRequired => {
//
//   },
//   componentArray: (props, propName, componentName, expectedComponent) => {
//     if (props[propName] && props[propName].type !== expectedComponent) {
//       return new Error(
//         `Invalid prop ${propName} supplied to ${componentName}.`
//       );
//     }
//   }
// };
//
// function propExists(props, propName, componentName) {
//   if (props[propName]) {
//     return true;
//   }
//
//   throw new Error(`Prop ${propName} missing in ${componentName}.`);
// }

function component(isRequired) {
  return (props, propName, componentName) => {
    // Prop is required but missing
    if (!props[propName] && isRequired) {
      return new Error(`Prop ${propName} is missing in ${componentName}.`);
    }

    // Prop has wrong type
    if (props[propName] && props[propName].type !== 'type') {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}.`
      );
    }
  };
}

function atLeastOneOf(propsArray, innerProps) {
  return (props, propName, componentName) => {
    if (!propsArray || propsArray.length < 2) {
      return new Error(
        `Not enough alternatives to propsArray argument provided. ${propsArray}`
      );
    }

    let atLeastOneProvided = false;
    for (let pName of propsArray) {
      if (Boolean(props[pName])) {
        atLeastOneProvided = true;
      }
    }

    if (atLeastOneProvided) {
      checkPropTypes(innerProps, props, 'prop', componentName);
      return null;
    } else {
      return new Error(
        `At least one prop of ${propsArray} has to be provided. None were given to '${componentName}'`
      );
    }
  };
}

const PropTypesHelper = {};
PropTypesHelper.component = component(false);
PropTypesHelper.component.isRequired = component(true);
PropTypesHelper.atLeastOneOf = atLeastOneOf;

export default PropTypesHelper;
