import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: theme.effect.boxShadow
  }
}));

const Paper = React.forwardRef((props, ref) => {
  const classes = useStyles(props);

  return (
    // use ...props to make sure '@mui/core/Box' props get applied
    <Box {...props} ref={ref} className={`${classes.paper} ${props.className}`}>
      {props.children}
    </Box>
  );
});

Paper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

Paper.defaultProps = {
  children: null,
  className: ''
};

export default Paper;
