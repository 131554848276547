import { AnyAction } from 'redux';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { fetchBooking } from './booking.slice';
import { fetchRequest } from './request.slice';
import {
  createNotification,
  fetchNotification,
  fetchNotifications
} from './notification.slice';
import { Contact } from '../interfaces';
import { RootState } from '.';
import { safeArray } from '../utils';
import HYDRATE_FIX from './_HYDRATE_FIX';

const contactAdapter = createEntityAdapter<Contact>();
const initialState = contactAdapter.getInitialState({ isLoading: false });
const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        contactAdapter.upsertMany(state, safeArray(payload.contacts.entities));
      });
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      contactAdapter.upsertMany(state, safeArray(payload.contacts));
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      contactAdapter.upsertMany(state, safeArray(payload.contacts));
    });
    builder.addCase(createNotification.fulfilled, (state, { payload }) => {
      contactAdapter.upsertMany(state, safeArray(payload.contacts));
    });
    builder.addCase(fetchNotification.fulfilled, (state, { payload }) => {
      contactAdapter.upsertMany(state, safeArray(payload.contacts));
    });
    builder.addCase(fetchNotifications.fulfilled, (state, { payload }) => {
      contactAdapter.upsertMany(state, safeArray(payload.contacts));
    });
  }
});

export const contactSelectors = contactAdapter.getSelectors<RootState>(
  state => state.contacts
);

export default contactsSlice.reducer;
