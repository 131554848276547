import getConfig from 'next/config';
import { Organization } from 'shared/Redux/interfaces';

const env = getConfig()?.publicRuntimeConfig;

export const customization = (organization: Organization) =>
  organization?.setting_portal_flavor === 'bad_schwartau'
    ? {
        spacing: scale => [4, 8, 16, 30, 60, 80][Math.floor(scale)],
        color: {
          background: {
            default: '#FFFFFF', // standard white background
            primary: '#000000', // blue login background Flensburg
            secondary: '#9B0000', // red, unused so far
            grey: '#F5F5F5'
          },
          border: {
            light: '#DDDDDD', // disabled
            main: '#D1D1D1', // standard
            dark: '#0076dd' // focused
          },
          error: {
            // text box error, invalid information
            light: '#fdecee',
            main: '#d52f2e',
            contrastText: '#9b0000' // white text within buttons
          },
          warning: {
            main: '#F9F4E4',
            light: '#F9F4E4'
          }, // yellow
          success: {
            light: '#e2f1eb',
            main: '#3f6b25',
            contrastText: '#fff'
          },
          text: {
            light: '#4A4A4A', // light text
            main: '#262626', // standard text
            dark: '#171717', // dark Text
            link: '#285471' // link Text color
          },
          common: {
            // holds icon/line/frame/border colors
            white: '#FFFFFF', // unused so far?
            black: '#292929', // unused so far?
            grey: {
              light: '#b3b3b3', // disabled
              main: '#7a7a7a', // standard
              dark: '#545454' // focused
            }
          },
          primary: {
            light: '#3A7BA6', // disabled
            main: '#285471', // standard
            dark: '#000000', // focused
            contrastText: '#FFFFFF' // white text within buttons
          },
          secondary: {
            light: '#EF7A75', // disabled
            main: '#ca4d55', // standard
            dark: '#CD1C15', // focused
            contrastText: '#FFFFFF' // text
          }
        },
        font: {
          primary: 'var(--font-open-sans)',
          secondary: '',
          size: {
            root: '16px', // base size for following sizes
            input: '1rem', // input components
            text: '1.1rem', // standard text
            small: '1rem', // small text
            large: '1.225rem' // large text
          }
        },
        effect: {
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px'
          // if blurRadius needs to be increased,
          // check optical consequences on Tabbars active tab and fix them
        },
        borderRadius: {
          small: '.25rem',
          medium: '.5rem',
          large: '.75rem'
        },
        mediaQueries: {
          tiny: '@media (max-width: 37rem)',
          small: '@media (max-width: 64rem)',
          medium: '@media (max-width: 92rem)',
          large: '@media (min-width: 92rem)'
        },
        // Used for form error scroll behavior
        // based on header top bar height
        contentOffset: '64'
      }
    : {
        color: {
          background: {
            default: '#FFFFFF', // standard white background
            primary: '#000000', // blue login background Flensburg
            secondary: '#9B0000', // red, unused so far
            grey: '#F5F5F5'
          },
          border: {
            light: '#DDDDDD', // disabled
            main: '#D1D1D1', // standard
            dark: '#0076dd' // focused
          },
          error: {
            // text box error, invalid information
            light: '#fdecee',
            main: '#9b0000',
            contrastText: '#9b0000' // white text within buttons
          },
          warning: {
            main: '#776118',
            light: '#F9F4E4',
            contrastText: '#fff'
          }, // yellow
          success: {
            light: '#e2f1eb',
            main: '#3f6b25',
            contrastText: '#fff'
          },
          text: {
            light: '#636363', // light text
            main: '#000000', // standard text
            dark: '#000000', // dark Text
            link: '#0047D3' // link Text color
          },
          common: {
            // holds icon/line/frame/border colors
            white: '#FFFFFF', // unused so far?
            black: '#000000', // unused so far?
            grey: {
              light: '#b3b3b3', // disabled
              main: '#7a7a7a', // standard
              dark: '#545454' // focused
            }
          },
          primary: {
            light: '#7f8d9e', // disabled
            main: '#001C3E', // standard
            dark: '#000000', // focused
            contrastText: '#FFFFFF' // white text within buttons
          },
          secondary: {
            light: '#EF7A75', // disabled
            main: '#FF4F4F', // standard
            dark: '#CD1C15', // focused
            contrastText: '#FFFFFF' // text
          }
        },
        font: {
          primary: 'Arial, sans-serif',
          secondary: '',
          size: {
            root: '16px', // base size for following sizes
            input: '1rem', // input components
            text: '1rem', // standard text
            small: '.875rem', // small text
            large: '1.125rem' // large text
          }
        },
        effect: {
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px'
          // if blurRadius needs to be increased,
          // check optical consequences on Tabbars active tab and fix them
        },
        borderRadius: {
          small: '.25rem',
          medium: '.5rem',
          large: '.75rem'
        },
        mediaQueries: {
          tiny: '@media (max-width: 37rem)',
          small: '@media (max-width: 64rem)',
          medium: '@media (max-width: 92rem)',
          large: '@media (min-width: 92rem)'
        },
        // Used for form error scroll behavior
        // based on header top bar height
        contentOffset: '64'
      };

export const components = (organization: Organization) => {
  const theme = customization(organization);

  return organization?.setting_portal_flavor === 'bad_schwartau'
    ? {
        components: {
          teaser: {
            root: {
              background: theme.color.background.grey,
              padding: theme.spacing(2),
              height: '100%',
              borderRadius: '0.5rem'
            },
            image: {
              background: theme.color.background.grey,
              '& svg': {
                height: '8rem',
                width: '8rem'
              }
            }
          },
          heading: {
            h1: {
              color: theme.color.text.dark,
              fontSize: '2rem'
            },
            h2: {
              color: theme.color.text.dark,
              fontSize: '1.575rem !important',
              margin: props => (props.context ? 0 : '0 0 1rem 0')
            },
            h3: {
              color: theme.color.text.dark,
              fontSize: '1.1rem',
              lineHeight: '1.4375rem'
            },
            h4: {
              color: theme.color.text.dark,
              fontSize: '1rem',
              lineHeight: '1.4375rem',
              marginBottom: '.4rem',
              marginTop: '1rem'
            },
            icon: {
              fill: theme.color.secondary.main,
              marginBottom: props => {
                switch (props.type?.toLowerCase()) {
                  case 'h2':
                    return props.context ? null : '1rem';
                  default:
                    return null;
                }
              }
            }
          },
          textField: {
            root: {
              '& .MuiInputLabel-root': {
                fontWeight: 'bold',
                transform: 'none',
                marginBottom: '.4rem',
                color: theme.color.text.main
              },
              '& .MuiOutlinedInput-root': {
                color: theme.color.text.main,
                background: theme.color.background.default
              },
              '& .MuiOutlinedInput-input': {
                padding: '.5rem .75rem'
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: `2px solid ${theme.color.text.main}`,
                borderRadius: 0
              },
              '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                borderLeft: `6px solid ${theme.color.error.main}`
              },
              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.color.border.dark,
                boxShadow: `0 1px 1px rgb(0 0 0 / 8%) inset, 0 0 8px rgb(102 175 233 / 60%)`
              },
              '& .MuiFormLabel-root.Mui-error': {
                color: theme.color.text.main
              },
              '& .MuiFormHelperText-root.Mui-error': {
                background: theme.color.error.light,
                margin: 0,
                color: theme.color.error.contrastText,
                padding: '1.125rem',
                fontSize: '1rem'
              },
              '& .MuiFormHelperText-root': {
                fontSize: '.875rem',
                marginLeft: 0,
                lineHeight: 1.4
              },
              '& svg': {
                color: props =>
                  props.disabled
                    ? theme.color.common.grey.main
                    : theme.color.text.main
              }
            }
          },
          topbar: {
            root: {
              padding: 0
            }
          },
          timePicker: {
            root: {
              width: '6rem'
            }
          },
          label: {
            root: {
              fontSize: '1rem',
              fontWeight: 'bold',
              lineHeight: '1.4375em',
              color: theme.color.text.main,
              '& svg': {
                width: '1.75rem',
                height: '1.75rem'
              }
            }
          },
          chip: {
            root: {
              '& .MuiChip-root': {
                fontWeight: 'bold',
                backgroundColor: ({ props, state }) => {
                  switch (props.color) {
                    default:
                    case 'default':
                      return state.selected ? '#13245b' : '#ebf1f7';
                    case 'success':
                      return state.selected
                        ? theme.color.success.main
                        : theme.color.success.light;
                    case 'error':
                      return state.selected
                        ? theme.color.error.main
                        : theme.color.error.light;
                    case 'warning':
                      return state.selected
                        ? theme.color.warning.main
                        : theme.color.warning.light;
                  }
                },
                height: 'auto',
                paddingLeft: ({ props }) => (props.icon ? '0.5rem' : null)
              },
              '& .MuiChip-label': {
                color: ({ props, state }) => {
                  switch (props.color) {
                    default:
                    case 'default':
                    case 'primary':
                      return state.selected
                        ? theme.color.primary.contrastText
                        : '#13245b';
                    case 'success':
                      return state.selected
                        ? theme.color.success.contrastText
                        : theme.color.success.main;
                    case 'error':
                      return state.selected
                        ? theme.color.error.contrastText
                        : theme.color.error.main;
                    case 'warning':
                      return state.selected
                        ? theme.color.warning.contrastText
                        : theme.color.warning.main;
                  }
                },
                padding: '0.25rem 0.5rem',
                fontSize: '0.875rem'
              }
            }
          },
          button: {
            root: {
              borderRadius: 0,
              padding: props => {
                if (props.icon) return null;

                if (props.endIcon) return '0 0 0 1rem';

                return '0 1rem';
              },
              lineHeight: '2.45rem',
              marginLeft: '.5rem',
              minHeight: props => (props.icon ? '2rem' : null),
              borderWidth: props => '2px',
              '& svg': {
                fill: props => {
                  if (props.icon) {
                    if (props.color === 'secondary') {
                      return props.disabled
                        ? theme.color.common.grey.main
                        : theme.color.secondary.main;
                    }

                    return theme.color.text.main;
                  }

                  return theme.color.primary.contrastText;
                }
              },
              borderColor: props => {
                if (props.endIcon) {
                  return props.disabled
                    ? theme.color.border.light
                    : theme.color.text.dark;
                }

                if (props.variant === 'text') {
                  return 'transparent';
                }

                if (props.color === 'primary') {
                  return props.disabled
                    ? theme.color.primary.light
                    : theme.color.primary.main;
                }

                if (props.color === 'secondary') {
                  return props.disabled
                    ? theme.color.secondary.light
                    : theme.color.secondary.dark;
                }

                return props.disabled
                  ? theme.color.border.light
                  : theme.color.primary.dark;
              },
              '&:hover': {
                textDecoration: 'none',
                background: props => {
                  if (props.variant === 'contained') {
                    return props.disabled ? null : theme.color.primary.dark;
                  }

                  if (props.variant === 'text') {
                    return 'transparent';
                  }

                  return props.disabled ? null : theme.color.background.grey;
                }
              },
              '&:focus-visible': {
                outline: `3px solid ${theme.color.border.dark}`,
                outlineOffset: 0,
                boxShadow: `0px 0px 0 5px ${theme.color.background.default}`
              },
              [theme.mediaQueries.tiny]: {
                width: props => (props.icon ? 'auto' : '100% !important'),
                marginTop: '.25rem',
                marginBottom: '.25rem',
                marginLeft: 0
              }
            },
            text: {
              marginRight: props => (props.endIcon ? '1rem' : null),
              fontWeight: 'normal',
              color: props => {
                if (props.variant === 'contained') {
                  return props.disabled
                    ? theme.color.primary.contrastText
                    : theme.color.primary.contrastText;
                }

                if (props.variant === 'text') {
                  return props.disabled ? '#3A7BA6' : theme.color.text.link;
                }

                return props.disabled
                  ? theme.color.text.light
                  : theme.color.text.main;
              },
              fontSize: props => {
                switch (props.size) {
                  case 'small':
                    return '0.8em';
                  default:
                  case 'medium':
                    return '1em';
                  case 'large':
                    return '1.2rem';
                }
              }
            },
            endIcon: {
              justifyContent: 'center',
              alignItems: 'center',
              height: '2.45rem',
              width: '2.45rem',
              margin: 0,
              background: props => {
                if (props.color === 'default' || props.color === 'light') {
                  return theme.color.common.grey.main;
                }

                if (props.color === 'primary') {
                  return props.disabled
                    ? theme.color.primary.light
                    : theme.color.primary.main;
                }

                if (props.color === 'secondary') {
                  return props.disabled
                    ? theme.color.secondary.light
                    : theme.color.secondary.main;
                }
              }
            },
            loadingIndicator: {
              color: props => {
                if (props.variant === 'contained') {
                  return theme.color.background.default;
                } else {
                  return theme.color.text.light;
                }
              }
            }
          },
          error: {
            root: {
              fontSize: '1rem',
              color: theme.color.error.contrastText
            }
          },
          typeahead: {
            root: {
              '& .MuiOutlinedInput-input': {
                padding: '0 !important'
              }
            },
            loadMore: {
              '& button:hover': {
                background: theme.color.common.grey.light
              }
            }
          },
          message: {
            root: {
              background: props => {
                switch (props.type) {
                  default:
                  case 'default':
                    return '#ebf1f7';
                  case 'error':
                    return `${theme.color.error.light}`;
                  case 'success':
                    return '#e2f1eb';
                  case 'warning':
                    return theme.color.warning.light;
                }
              },
              '& svg': {
                display: 'none'
              },
              padding: '1.125rem'
            },
            text: {
              color: props => {
                switch (props.type) {
                  default:
                  case 'default':
                    return theme.color.text.main;
                  case 'error':
                    return `${theme.color.error.contrastText}`;
                  case 'warning':
                    return theme.color.text.main;
                }
              },
              '& h3': {
                color: props => {
                  switch (props.type) {
                    default:
                    case 'default':
                      return theme.color.text.main;
                    case 'error':
                      return `${theme.color.error.contrastText}`;
                    case 'warning':
                      return theme.color.text.main;
                  }
                }
              }
            }
          },
          text: {
            root: {
              lineHeight: 1.5,
              marginTop: 0
            }
          },
          multiCreate: {
            root: {
              '& .MuiFormHelperText-root.Mui-error': {
                background: theme.color.error.light,
                margin: 0,
                color: theme.color.error.contrastText,
                padding: '1.125rem',
                fontSize: '1rem'
              }
            },
            item: {
              background: theme.color.background.default,
              border: `2px solid ${theme.color.text.main}`,
              borderRadius: 0
            }
          },
          multiSelect: {
            dropdown: {
              background: theme.color.background.default,
              borderRadius: 0,
              border: `2px solid ${theme.color.text.main}`,
              '&:hover': {
                border: `2px solid ${theme.color.text.main}`
              },
              '&:has(.Mui-focusVisible)': {
                borderColor: theme.color.border.dark,
                boxShadow: `0 1px 1px rgb(0 0 0 / 8%) inset, 0 0 8px rgb(102 175 233 / 60%)`
              }
            },
            tag: {
              borderRadius: 0
            },
            tagName: {
              maxWidth: '20rem',
              [theme.mediaQueries.tiny]: {
                maxWidth: '8em'
              }
            },
            selectedCounter: {
              borderRadius: 0
            },
            removeAllTags: {
              '& svg': {
                height: '.75em',
                transform: 'translate(0) !important',
                width: '.75em',
                fill: theme.color.text.main
              }
            },
            dropdownArrowIcon: {
              '& svg': {
                fill: theme.color.text.main
              }
            },
            searchInput: {
              '& ::placeholder': {
                fontSize: '1rem',
                color: theme.color.text.light,
                opacity: 1
              }
            },
            selectedItems: {
              '& svg': {
                background: 'transparent'
              }
            },
            placeholder: {
              color: theme.color.text.main,
              fontSize: '1rem'
            },
            infoText: {
              fontSize: '1rem'
            },
            textHelper: {
              fontSize: '0.875rem'
            },
            error: {
              background: theme.color.error.light,
              margin: 0,
              color: `${theme.color.error.contrastText} !important`,
              padding: '1.125rem',
              fontSize: '1rem'
            }
          },
          multiToggle: {
            group: {
              '& .MuiButtonGroup-grouped:not(:first-of-type)': {
                marginLeft: '-2px !important'
              }
            },
            root: {
              borderRadius: 0,
              borderWidth: '2px',
              borderColor: theme.color.border.light,
              '&:hover': {
                borderWidth: '2px',
                borderColor: theme.color.border.dark
              }
            },
            error: {
              background: theme.color.error.light,
              margin: 0,
              color: `${theme.color.error.contrastText} !important`,
              padding: '1.125rem',
              fontSize: '1rem'
            },
            activeToggle: {
              borderWidth: '2px !important',
              zIndex: 1,
              '&:hover': {
                borderRightColor: `${theme.color.primary.main} !important`
              }
            }
          },
          checkbox: {
            root: {
              '& input[type="checkbox"]': {
                display: 'block',
                width: '1rem',
                height: '1rem',
                flexShrink: 0
              }
            },
            box: {
              display: 'none'
            },
            label: {
              fontWeight: 'normal',
              fontSize: '1rem'
            },
            error: {
              background: theme.color.error.light,
              margin: 0,
              color: `${theme.color.error.contrastText} !important`,
              padding: '1.125rem',
              fontSize: '1rem'
            }
          },
          weekPicker: {
            firstDay: {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            },
            lastDay: {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            },
            dayBetween: {
              '&:hover, &:focus': {
                backgroundColor: `${theme.color.primary.main} !important`
              }
            }
          },
          pagination: {
            '& li': {
              border: `1px solid ${theme.color.common.grey.light}`
            },
            '& .MuiPaginationItem-root': {
              fontSize: '1rem',
              color: theme.color.text.main,
              margin: 0,
              padding: '.4rem 1.1rem .4rem 1.1rem',
              height: '2.2rem',
              borderRadius: 0,
              '&:hover': {
                textDecoration: 'underline'
              },
              '&:focus-visible': {
                outline: `1px auto ${theme.color.primary.dark}`,
                background: 'transparent'
              }
            },
            '& .MuiPaginationItem-root.Mui-selected': {
              background: 'none',
              borderTop: `4px solid ${theme.color.text.main}`,
              fontWeight: 'bold',
              borderRadius: 0,
              padding: 'calc(.4rem - 4px) 1.1rem .4rem 1.1rem ',
              '&:hover': {
                textDecoration: 'none'
              }
            },
            '& .MuiTouchRipple-root': {
              display: 'none'
            }
          },
          moneyInput: {
            root: {
              width: '7rem'
            }
          },
          currencyTextField: {
            root: {
              '& input': {
                paddingLeft: '0 !important'
              }
            }
          },
          hoverbox: {
            closeButton: {
              position: 'relative',
              transform: 'none',
              top: 0,
              right: 0,
              borderRadius: 0,
              background: 'none',
              display: 'flex',
              justifyContent: 'flex-end',
              '& svg': {
                fill: theme.color.text.main
              }
            }
          },
          icon: {
            svg: {
              height: props => {
                switch (props.size) {
                  case 'small':
                    return '0.875em';
                  case 'medium':
                  default:
                    return '1em';
                  case 'large':
                    return '1.5em';
                  case 'fullsize':
                    return '100%';
                }
              },
              width: props => {
                switch (props.size) {
                  case 'small':
                    return '0.875em';
                  case 'medium':
                  default:
                    return '1em';
                  case 'large':
                    return '1.5em';
                  case 'fullsize':
                    return '100%';
                }
              }
            }
          },
          breadcrumb: {
            root: {
              borderBottom: 0,
              paddingBottom: 0
            }
          },
          informationGrid: {
            item: {
              '& td': {
                padding: `0 0 .75rem 0`
              }
            }
          }
        }
      }
    : {
        components: {
          heading: {
            h1: {
              fontSize: '1.6875rem'
            },
            h2: {
              fontSize: '1.375rem',
              borderLeft: props =>
                props.icon
                  ? null
                  : `.4375rem solid ${theme.color.secondary.main}`, // secondary main
              paddingLeft: '.7rem',
              margin: props => (props.context ? 0 : '0 0 1rem 0')
            },
            h3: {
              fontSize: '1.0625rem',
              lineHeight: '1.4375rem'
            },
            h4: {
              fontSize: '1rem',
              lineHeight: '1.4375rem',
              marginBottom: '.4rem',
              marginTop: '1rem'
            },
            icon: {
              fill: theme.color.secondary.main,
              marginBottom: props => {
                switch (props.type?.toLowerCase()) {
                  case 'h2':
                    return props.context ? null : '1rem';
                  default:
                    return null;
                }
              }
            }
          },
          textField: {
            root: {
              '& .MuiInputLabel-root': {
                fontWeight: 'bold',
                transform: 'none',
                marginBottom: '.4rem',
                color: theme.color.text.main
              },
              '& .MuiOutlinedInput-root': {
                color: theme.color.text.main,
                background: theme.color.background.default
              },
              '& .MuiOutlinedInput-input': {
                padding: '.5rem .75rem'
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: `2px solid ${theme.color.text.main}`,
                borderRadius: 0
              },
              '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                borderLeft: `6px solid ${theme.color.error.main}`
              },
              '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.color.border.dark,
                boxShadow: `0 1px 1px rgb(0 0 0 / 8%) inset, 0 0 8px rgb(102 175 233 / 60%)`
              },
              '& .MuiFormLabel-root.Mui-error': {
                color: theme.color.text.main
              },
              '& .MuiFormHelperText-root.Mui-error': {
                background: theme.color.error.light,
                margin: 0,
                color: theme.color.error.contrastText,
                padding: '1.125rem',
                fontSize: '1rem'
              },
              '& .MuiFormHelperText-root': {
                fontSize: '.875rem',
                marginLeft: 0,
                lineHeight: 1.4
              },
              '& svg': {
                color: props =>
                  props.disabled
                    ? theme.color.common.grey.main
                    : theme.color.text.main
              }
            }
          },
          topbar: {
            root: {
              padding: 0
            }
          },
          timePicker: {
            root: {
              width: '6rem'
            }
          },
          label: {
            root: {
              fontSize: '1rem',
              fontWeight: 'bold',
              lineHeight: '1.4375em',
              color: theme.color.text.main,
              '& svg': {
                width: '1.75rem',
                height: '1.75rem'
              }
            }
          },
          chip: {
            root: {
              '& .MuiChip-root': {
                fontWeight: 'bold',
                backgroundColor: ({ props, state }) => {
                  switch (props.color) {
                    default:
                    case 'default':
                      return state.selected ? '#13245b' : '#ebf1f7';
                    case 'success':
                      return state.selected
                        ? theme.color.success.main
                        : theme.color.success.light;
                    case 'error':
                      return state.selected
                        ? theme.color.error.main
                        : theme.color.error.light;
                    case 'warning':
                      return state.selected
                        ? theme.color.warning.main
                        : theme.color.warning.light;
                  }
                },
                height: 'auto',
                paddingLeft: ({ props }) => (props.icon ? '0.5rem' : null)
              },
              '& .MuiChip-label': {
                color: ({ props, state }) => {
                  switch (props.color) {
                    default:
                    case 'default':
                    case 'primary':
                      return state.selected
                        ? theme.color.primary.contrastText
                        : '#13245b';
                    case 'success':
                      return state.selected
                        ? theme.color.success.contrastText
                        : theme.color.success.main;
                    case 'error':
                      return state.selected
                        ? theme.color.error.contrastText
                        : theme.color.error.main;
                    case 'warning':
                      return state.selected
                        ? theme.color.warning.contrastText
                        : theme.color.warning.main;
                  }
                },
                padding: '0.15rem 0.45rem',
                fontSize: '0.875rem'
              }
            }
          },
          button: {
            root: {
              borderRadius: 0,
              padding: props => {
                if (props.icon) return null;

                if (props.endIcon) return '0 0 0 1rem';

                return '0 1rem';
              },
              lineHeight: '2.45rem',
              marginLeft: '.5rem',
              minHeight: props => (props.icon ? '2rem' : null),
              borderWidth: '2px',
              '& svg': {
                fill: props => {
                  if (props.icon) {
                    if (props.color === 'secondary') {
                      return props.disabled
                        ? theme.color.common.grey.main
                        : theme.color.secondary.main;
                    }

                    return theme.color.text.main;
                  }

                  return theme.color.primary.contrastText;
                }
              },
              borderColor: props => {
                if (props.endIcon) {
                  return props.disabled
                    ? theme.color.border.light
                    : theme.color.text.dark;
                }

                if (props.variant === 'text') {
                  return 'transparent';
                }

                if (props.color === 'primary') {
                  return props.disabled
                    ? theme.color.common.grey.light
                    : theme.color.primary.main;
                }

                if (props.color === 'secondary') {
                  return props.disabled
                    ? theme.color.secondary.light
                    : theme.color.secondary.dark;
                }

                return props.disabled
                  ? theme.color.border.light
                  : theme.color.primary.dark;
              },
              '&:hover': {
                textDecoration: 'none',
                background: props => {
                  if (props.variant === 'contained') {
                    return props.disabled ? null : theme.color.primary.dark;
                  }

                  if (props.variant === 'text') {
                    return 'transparent';
                  }

                  return props.disabled ? null : theme.color.background.grey;
                }
              },
              '&:focus-visible': {
                outline: `3px solid ${theme.color.border.dark}`,
                outlineOffset: 0,
                boxShadow: `0px 0px 0 5px ${theme.color.background.default}`
              },
              [theme.mediaQueries.tiny]: {
                width: props => (props.icon ? 'auto' : '100% !important'),
                marginTop: '.25rem',
                marginBottom: '.25rem',
                marginLeft: 0
              }
            },
            text: {
              marginRight: props => (props.endIcon ? '1rem' : null),
              fontWeight: 'normal',
              letterSpacing: props => (props.variant === 'text' ? 0 : '1px'),
              color: props => {
                if (props.variant === 'contained') {
                  return props.disabled
                    ? theme.color.primary.contrastText
                    : theme.color.primary.contrastText;
                }

                if (props.variant === 'text') {
                  return props.disabled ? '#1F69FF' : theme.color.text.link;
                }

                return props.disabled
                  ? theme.color.text.light
                  : theme.color.text.main;
              },
              fontSize: props => {
                switch (props.size) {
                  case 'small':
                    return '0.8em';
                  default:
                  case 'medium':
                    return '1em';
                  case 'large':
                    return '1.2rem';
                }
              }
            },
            endIcon: {
              justifyContent: 'center',
              alignItems: 'center',
              height: '2.45rem',
              width: '2.45rem',
              margin: 0,
              background: props => {
                if (props.color === 'default' || props.color === 'light') {
                  return theme.color.common.grey.main;
                }

                if (props.color === 'primary') {
                  return props.disabled
                    ? theme.color.primary.light
                    : theme.color.primary.main;
                }

                if (props.color === 'secondary') {
                  return props.disabled
                    ? theme.color.secondary.light
                    : theme.color.secondary.main;
                }
              }
            },
            loadingIndicator: {
              color: props => {
                if (props.variant === 'contained') {
                  return theme.color.background.default;
                } else {
                  return theme.color.text.light;
                }
              }
            }
          },
          error: {
            root: {
              fontSize: '1rem',
              color: theme.color.error.contrastText
            }
          },
          typeahead: {
            root: {
              '& .MuiOutlinedInput-input': {
                padding: '0 !important'
              }
            },
            loadMore: {
              '& button:hover': {
                background: theme.color.common.grey.light
              }
            }
          },
          message: {
            root: {
              background: props => {
                switch (props.type) {
                  default:
                  case 'default':
                    return '#ebf1f7';
                  case 'error':
                    return `${theme.color.error.light}`;
                  case 'success':
                    return '#e2f1eb';
                  case 'warning':
                    return theme.color.warning.light;
                }
              },
              '& svg': {
                display: 'none'
              },
              padding: '1.125rem'
            },
            text: {
              color: props => {
                switch (props.type) {
                  default:
                  case 'default':
                    return theme.color.text.main;
                  case 'error':
                    return `${theme.color.error.contrastText}`;
                  case 'warning':
                    return theme.color.text.main;
                }
              },
              '& h3': {
                color: props => {
                  switch (props.type) {
                    default:
                    case 'default':
                      return theme.color.text.main;
                    case 'error':
                      return `${theme.color.error.contrastText}`;
                    case 'warning':
                      return theme.color.text.main;
                  }
                }
              }
            }
          },
          text: {
            root: {
              lineHeight: 1.5,
              marginTop: 0
            }
          },
          modal: {
            window: {
              padding: 0
            },
            closeButton: {
              right: '0.5rem',
              top: '0.75rem',
              '& button': {
                fontSize: '2rem'
              }
            },
            heading: {
              background: theme.color.background.grey,
              borderBottom: `1px solid ${theme.color.border.light}`,
              paddingTop: '1rem',
              paddingBottom: '1rem',
              paddingLeft: '1rem'
            },
            content: {
              padding: '1rem'
            }
          },
          multiCreate: {
            root: {
              '& .MuiFormHelperText-root.Mui-error': {
                background: theme.color.error.light,
                margin: 0,
                color: theme.color.error.contrastText,
                padding: '1.125rem',
                fontSize: '1rem'
              }
            },
            item: {
              background: theme.color.background.default,
              border: `2px solid ${theme.color.text.main}`,
              borderRadius: 0
            }
          },
          multiSelect: {
            dropdown: {
              background: theme.color.background.default,
              borderRadius: 0,
              border: `2px solid ${theme.color.text.main}`,
              '&:hover': {
                border: `2px solid ${theme.color.text.main}`
              },
              '&:has(.Mui-focusVisible)': {
                borderColor: theme.color.border.dark,
                boxShadow: `0 1px 1px rgb(0 0 0 / 8%) inset, 0 0 8px rgb(102 175 233 / 60%)`
              }
            },
            tag: {
              borderRadius: 0
            },
            tagName: {
              maxWidth: '20rem',
              [theme.mediaQueries.tiny]: {
                maxWidth: '8em'
              }
            },
            selectedCounter: {
              borderRadius: 0
            },
            removeAllTags: {
              '& svg': {
                height: '.75em',
                transform: 'translate(0) !important',
                width: '.75em',
                fill: theme.color.text.main
              }
            },
            dropdownArrowIcon: {
              '& svg': {
                fill: theme.color.text.main
              }
            },
            searchInput: {
              '& ::placeholder': {
                fontSize: '1rem',
                color: theme.color.text.light,
                opacity: 1
              }
            },
            selectedItems: {
              '& svg': {
                background: 'transparent'
              }
            },
            placeholder: {
              color: theme.color.text.main,
              fontSize: '1rem'
            },
            infoText: {
              fontSize: '1rem'
            },
            textHelper: {
              fontSize: '0.875rem'
            },
            error: {
              background: theme.color.error.light,
              margin: 0,
              color: `${theme.color.error.contrastText} !important`,
              padding: '1.125rem',
              fontSize: '1rem !important'
            }
          },
          multiToggle: {
            group: {
              '& .MuiButtonGroup-grouped:not(:first-of-type)': {
                marginLeft: '-2px !important'
              }
            },
            root: {
              borderRadius: 0,
              borderWidth: '2px',
              borderColor: theme.color.border.light,
              '&:hover': {
                borderWidth: '2px',
                borderColor: theme.color.border.dark
              }
            },
            error: {
              background: theme.color.error.light,
              margin: 0,
              color: `${theme.color.error.contrastText} !important`,
              padding: '1.125rem',
              fontSize: '1rem'
            },
            activeToggle: {
              borderWidth: '2px !important',
              zIndex: 1,
              '&:hover': {
                borderRightColor: `${theme.color.primary.main} !important`
              }
            }
          },
          checkbox: {
            root: {
              '& input[type="checkbox"]': {
                display: 'block',
                width: '1rem',
                height: '1rem',
                flexShrink: 0
              }
            },
            box: {
              display: 'none'
            },
            label: {
              fontWeight: 'normal',
              fontSize: '1rem'
            },
            error: {
              background: theme.color.error.light,
              margin: 0,
              color: `${theme.color.error.contrastText} !important`,
              padding: '1.125rem',
              fontSize: '1rem'
            }
          },
          weekPicker: {
            firstDay: {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            },
            lastDay: {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            },
            dayBetween: {
              '&:hover, &:focus': {
                backgroundColor: `${theme.color.primary.main} !important`
              }
            }
          },
          pagination: {
            '& li': {
              border: `1px solid ${theme.color.common.grey.light}`
            },
            '& .MuiPaginationItem-root': {
              fontSize: '1rem',
              color: theme.color.text.main,
              margin: 0,
              padding: '.4rem 1.1rem .4rem 1.1rem',
              height: '2.2rem',
              borderRadius: 0,
              '&:hover': {
                textDecoration: 'underline'
              },
              '&:focus-visible': {
                outline: `1px auto ${theme.color.primary.dark}`,
                background: 'transparent'
              }
            },
            '& .MuiPaginationItem-root.Mui-selected': {
              background: 'none',
              borderTop: `4px solid ${theme.color.text.main}`,
              fontWeight: 'bold',
              borderRadius: 0,
              padding: 'calc(.4rem - 4px) 1.1rem .4rem 1.1rem ',
              '&:hover': {
                textDecoration: 'none'
              }
            },
            '& .MuiTouchRipple-root': {
              display: 'none'
            }
          },
          moneyInput: {
            root: {
              width: '7rem'
            }
          },
          currencyTextField: {
            root: {
              '& input': {
                paddingLeft: '0 !important'
              }
            }
          },
          hoverbox: {
            closeButton: {
              position: 'relative',
              transform: 'none',
              top: 0,
              right: 0,
              borderRadius: 0,
              background: 'none',
              display: 'flex',
              justifyContent: 'flex-end',
              '& svg': {
                fill: theme.color.text.main
              }
            }
          },
          icon: {
            svg: {
              height: props => {
                switch (props.size) {
                  case 'small':
                    return '0.875em';
                  case 'medium':
                  default:
                    return '1em';
                  case 'large':
                    return '1.5em';
                  case 'fullsize':
                    return '100%';
                }
              },
              width: props => {
                switch (props.size) {
                  case 'small':
                    return '0.875em';
                  case 'medium':
                  default:
                    return '1em';
                  case 'large':
                    return '1.5em';
                  case 'fullsize':
                    return '100%';
                }
              }
            }
          },
          breadcrumb: {
            root: {
              borderBottom: 0,
              paddingBottom: 0
            }
          },
          informationGrid: {
            item: {
              '& td': {
                padding: `0 0 .75rem 0`
              }
            }
          }
        }
      };
};
