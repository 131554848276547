import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';

import Text from './Text';
import Heading from './Heading';
import Button from './Button';

export default function Note(props) {
  return (
    <Box p={1} display="flex" flexDirection="column" width="20em">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box>
          <Heading type="h5">{props.title}</Heading>
        </Box>
        <Box pl={1} display="flex" flexDirection="row">
          <Button
            icon
            color="light"
            onClick={props.onEdit}
            aria-label={props.editAriaLabel}
          >
            pen
          </Button>
          <Button
            icon
            color="light"
            onClick={props.onDelete}
            aria-label={props.deleteAriaLabel}
          >
            delete
          </Button>
        </Box>
      </Box>
      <Box width="16em">
        <Box>
          <Text color="dark">{props.children}</Text>
        </Box>
      </Box>
    </Box>
  );
}

Note.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  editAriaLabel: PropTypes.string,
  deleteAriaLabel: PropTypes.string
};

Note.defaultProps = {
  onEdit: () => {},
  children: '',
  onDelete: () => {},
  editAriaLabel: 'edit',
  deleteAriaLabel: 'delete'
};
