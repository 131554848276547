import isDummyEmail from 'shared/Redux/utils/isDummyEmail';
import { Contact } from 'shared/Redux/interfaces';

export default function getDefaultContact(contacts: Contact[]): Contact {
  if (!contacts?.length) return undefined;

  // Since there is no currently no type at contact field
  // we use the first entry as default
  const defaultContact = contacts[0];

  return {
    ...defaultContact,
    email: isDummyEmail(defaultContact.email)
      ? defaultContact.email.split('-')[
          defaultContact.email.split('-').length - 1
        ]
      : defaultContact.email,
    display_name: `${defaultContact?.first_name} ${defaultContact?.last_name}`
  };
}
