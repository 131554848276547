/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';
import { occasionPrimaryCategoriesEntity } from './occasionPrimaryCategory.schema';
import { occasionSecondaryCategoriesEntity } from './occasionSecondaryCategory.schema';

export const occasionsEntity = new schema.Entity(
  'occasions',
  {
    primary_category: occasionPrimaryCategoriesEntity,
    secondary_category: occasionSecondaryCategoriesEntity
  },
  {
    processStrategy: occasion => ({
      ...occasion
    })
  }
);
