/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';
import getDefaultContact from '../selectors/contact/getDefaultContact';

export const providerMembersEntity = new schema.Entity(
  'providerMembers',
  {},
  {
    processStrategy: providerMember => ({
      ...providerMember,
      defaultContact: getDefaultContact(providerMember.contacts)
    })
  }
);
