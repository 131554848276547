import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { client } from '../api/client';
import { consumerMembersEntity } from '../schemas';
import { fetchConsumer, fetchConsumers } from './consumer.slice';
import { fetchOrganizations } from './organization.slice';
import { ConsumerMember, Pagination, UUID } from '../interfaces';
import { RootState } from '.';
import { paginate, safeArray } from '../utils';
import { fetchConsumerTeams } from './consumerTeam.slice';
import { fetchBooking, fetchBookings } from './booking.slice';
import { fetchRequest, fetchRequests } from './request.slice';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const fetchConsumerMember = createAsyncThunk(
  'consumerMembers/byId',
  async (consumerMemberId: UUID) => {
    const response = await client.get(`/consumer_members/${consumerMemberId}`);
    const normalized = normalize(response.data.data, consumerMembersEntity);

    return normalized.entities;
  }
);

export const fetchConsumerMembers = createAsyncThunk(
  'consumerMembers/all',
  async (filter: Pagination) => {
    const response = await client.get('/consumer_members', filter);
    const normalized = normalize(response.data.data, [consumerMembersEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const createConsumerMember = createAsyncThunk(
  'consumerMembers/create',
  async member => {
    const response = await client.post('/consumer_members', {
      consumer_member: member
    });
    const normalized = normalize(response.data.data, consumerMembersEntity);

    return normalized.entities;
  }
);

export const updateConsumerMember = createAsyncThunk(
  'consumerMembers/update',
  async ({
    id,
    consumerMember
  }: {
    id: string;
    consumerMember: ConsumerMember;
  }) => {
    const response = await client.put(`/consumer_members/${id}`, {
      consumer_member: consumerMember
    });
    const normalized = normalize(response.data.data, consumerMembersEntity);

    return normalized.entities;
  }
);

export const archiveConsumerMember = createAsyncThunk(
  'consumerMembers/archive',
  async (id: number) => {
    await client.put(`/archive/consumer_members/${id}`);
    return id;
  }
);

export const unarchiveConsumerMember = createAsyncThunk(
  'consumerMembers/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/consumer_members/${id}`);
    return id;
  }
);

export const deleteConsumerMember = createAsyncThunk(
  'consumer_members/delete',
  async (id: number) => {
    await client.delete(`/consumer_members/${id}`);
    return id;
  }
);

const consumerMemberAdapter = createEntityAdapter<ConsumerMember>();
const initialState = consumerMemberAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const consumerMemberSlice = createSlice({
  name: 'consumerMembers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        consumerMemberAdapter.upsertMany(
          state,
          safeArray(payload.consumerMembers.entities)
        );
        state.pagination = payload.consumerMembers.pagination;
      });
    });
    builder.addCase(fetchConsumerMembers.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchConsumerMembers.fulfilled, (state, { payload }) => {
      consumerMemberAdapter.upsertMany(
        state,
        safeArray(payload.consumerMembers)
      );
      state.pagination = payload.pagination;
      state.isLoading = false;
    });
    builder.addCase(fetchConsumerMembers.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchConsumerMember.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchConsumerMember.fulfilled, (state, { payload }) => {
      consumerMemberAdapter.upsertMany(
        state,
        safeArray(payload.consumerMembers)
      );
      state.isLoading = false;
    });
    builder.addCase(fetchConsumerMember.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createConsumerMember.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createConsumerMember.fulfilled, (state, { payload }) => {
      consumerMemberAdapter.upsertMany(
        state,
        safeArray(payload.consumerMembers)
      );
      state.isLoading = false;
    });
    builder.addCase(createConsumerMember.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateConsumerMember.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateConsumerMember.fulfilled, (state, { payload }) => {
      consumerMemberAdapter.upsertMany(
        state,
        safeArray(payload.consumerMembers)
      );
      state.isLoading = false;
    });
    builder.addCase(updateConsumerMember.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteConsumerMember.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteConsumerMember.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteConsumerMember.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchConsumers.fulfilled, (state, { payload }) => {
      consumerMemberAdapter.upsertMany(
        state,
        safeArray(payload.consumerMembers)
      );
    });
    builder.addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
      consumerMemberAdapter.upsertMany(
        state,
        safeArray(payload.consumerMembers)
      );
    });
    builder.addCase(fetchConsumer.fulfilled, (state, { payload }) => {
      consumerMemberAdapter.upsertMany(
        state,
        safeArray(payload.consumerMembers)
      );
    });
    builder.addCase(fetchConsumerTeams.fulfilled, (state, { payload }) => {
      consumerMemberAdapter.upsertMany(
        state,
        safeArray(payload.consumerMembers)
      );
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      consumerMemberAdapter.upsertMany(
        state,
        safeArray(payload.consumerMembers)
      );
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      consumerMemberAdapter.upsertMany(
        state,
        safeArray(payload.consumerMembers)
      );
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      consumerMemberAdapter.upsertMany(
        state,
        safeArray(payload.consumerMembers)
      );
    });
    builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
      consumerMemberAdapter.upsertMany(
        state,
        safeArray(payload.consumerMembers)
      );
    });
  }
});

export const consumerMemberSelectors =
  consumerMemberAdapter.getSelectors<RootState>(state => state.consumerMembers);

export default consumerMemberSlice.reducer;
