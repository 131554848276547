import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import Tabbar from './Tabbar';

const useStyles = makeStyles(theme => ({
  tabs: {
    '& a': {
      textDecoration: 'none'
    }
  }
}));

export default function TabbarTabs(props) {
  const classes = useStyles(props);
  const tabbarContext = useContext(Tabbar.Context);

  useEffect(() => {
    if (!tabbarContext.index && props.children.length) {
      tabbarContext.setTab(props.children[0].props.index);
    }
  }, []);

  return (
    <Box
      className={classes.tabs}
      display="flex"
      data-testid="tabbar_tabs"
      role="tablist"
      aria-label={props['aria-label']}
    >
      {props.children}
    </Box>
  );
}

TabbarTabs.propTypes = {
  // children props are used in Tabbar
  // eslint-disable-next-line react/no-unused-prop-types
  children: PropTypes.node,
  'aria-label': PropTypes.string
};

TabbarTabs.defaultProps = {
  children: undefined,
  'aria-label': undefined
};
