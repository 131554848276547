/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';
import { datesEntity } from './date.schema';
import { dateSeriesEntity } from './dateSeries.schema';

export const eventsEntity = new schema.Entity('events', {
  date_series: [dateSeriesEntity],
  end_date: datesEntity,
  start_date: datesEntity
});
