import { addDays, getStartOfWeek } from './index';

/**
 * Gets last day of the week of a given date
 */
export default function getEndOfWeek(dt) {
  const date = addDays(getStartOfWeek(dt), 6);
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59,
    999
  );
}
