import { normalize } from 'normalizr';
import { AnyAction } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { client } from '../api/client';
import { consumersEntity } from '../schemas';
import { Consumer, Pagination, UUID } from '../interfaces';
import { fetchOrganizations } from './organization.slice';
import { RootState } from '.';
import { paginate, safeArray } from '../utils';
import { fetchConsumerTeams } from './consumerTeam.slice';
import { fetchBookings, fetchBooking } from './booking.slice';
import { fetchRequest, fetchRequests } from './request.slice';
import HYDRATE_FIX from './_HYDRATE_FIX';

export const fetchConsumer = createAsyncThunk(
  'consumers/byId',
  async (consumerId: UUID) => {
    const response = await client.get(`/consumers/${consumerId}`);
    const normalized = normalize(response.data.data, consumersEntity);

    return normalized.entities;
  }
);

export const fetchConsumers = createAsyncThunk(
  'consumers/all',
  async (filter: Pagination) => {
    const response = await client.get('/consumers', filter);
    const normalized = normalize(response.data.data, [consumersEntity]);
    const paginated = paginate(normalized, response);

    return paginated.entities;
  }
);

export const createConsumer = createAsyncThunk(
  'consumers/create',
  async consumer => {
    const response = await client.post('/consumers', { consumer });
    const normalized = normalize(response.data.data, consumersEntity);

    return normalized.entities;
  }
);

export const updateConsumer = createAsyncThunk(
  'consumers/update',
  async ({ id, consumer }: { id: UUID; consumer: Consumer }) => {
    const response = await client.put(`/consumers/${id}`, {
      consumer
    });
    const normalized = normalize(response.data.data, consumersEntity);

    return normalized.entities;
  }
);

export const archiveConsumer = createAsyncThunk(
  'consumers/archive',
  async (id: number) => {
    await client.put(`/archive/consumers/${id}`);
    return id;
  }
);

export const unarchiveConsumer = createAsyncThunk(
  'consumers/unarchive',
  async (id: number) => {
    await client.put(`/unarchive/consumers/${id}`);
    return id;
  }
);

export const deleteConsumer = createAsyncThunk(
  'consumers/delete',
  async (id: number) => {
    await client.delete(`/consumers/${id}`);
    return id;
  }
);

const consumerAdapter = createEntityAdapter<Consumer>();
const initialState = consumerAdapter.getInitialState({
  isLoading: false,
  pagination: null
});
const consumerSlice = createSlice({
  name: 'consumers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, { payload }: AnyAction) => {
      HYDRATE_FIX(payload, () => {
        consumerAdapter.upsertMany(
          state,
          safeArray(payload.consumers.entities)
        );
        state.isLoading = false;
        state.pagination = payload.consumers.pagination;
      });
    });
    builder.addCase(fetchConsumers.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchConsumers.fulfilled, (state, { payload }) => {
      consumerAdapter.upsertMany(state, safeArray(payload.consumers));
      state.isLoading = false;
      state.pagination = payload.pagination;
    });
    builder.addCase(fetchConsumers.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchConsumer.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchConsumer.fulfilled, (state, { payload }) => {
      consumerAdapter.upsertMany(state, safeArray(payload.consumers));
      state.isLoading = false;
    });
    builder.addCase(fetchConsumer.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(createConsumer.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createConsumer.fulfilled, (state, { payload }) => {
      consumerAdapter.upsertMany(state, safeArray(payload.entities));
      state.isLoading = false;
    });
    builder.addCase(createConsumer.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(updateConsumer.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateConsumer.fulfilled, (state, { payload }) => {
      consumerAdapter.upsertMany(state, safeArray(payload.entities));
      state.isLoading = false;
    });
    builder.addCase(updateConsumer.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteConsumer.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteConsumer.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteConsumer.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
      consumerAdapter.upsertMany(state, safeArray(payload.consumers));
    });
    builder.addCase(fetchConsumerTeams.fulfilled, (state, { payload }) => {
      consumerAdapter.upsertMany(state, safeArray(payload.consumers));
    });
    builder.addCase(fetchBooking.fulfilled, (state, { payload }) => {
      consumerAdapter.upsertMany(state, safeArray(payload.consumers));
    });
    builder.addCase(fetchBookings.fulfilled, (state, { payload }) => {
      consumerAdapter.upsertMany(state, safeArray(payload.consumers));
    });
    builder.addCase(fetchRequest.fulfilled, (state, { payload }) => {
      consumerAdapter.upsertMany(state, safeArray(payload.consumers));
    });
    builder.addCase(fetchRequests.fulfilled, (state, { payload }) => {
      consumerAdapter.upsertMany(state, safeArray(payload.consumers));
    });
  }
});

export const consumerSelectors = consumerAdapter.getSelectors<RootState>(
  state => state.consumers
);

export default consumerSlice.reducer;
