import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import Icon from './Icon';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `2px solid ${theme.color.text.main}`,
  '&:before': {
    display: 'none'
  }
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<Icon color="secondary" lib="fa" name="arrow-down" />}
    {...props}
  />
))(() => ({
  minHeight: '2em',
  lineHeight: '1.353rem',
  padding: 0,
  '& .MuiAccordionSummary-content': {
    margin: '.625rem 0'
  },
  '.MuiAccordionSummary-expandIconWrapper': {
    fontSize: '1.3em'
  }
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '.4375rem 0 .9375rem 0',
  '& p': {
    marginTop: 0
  }
}));
